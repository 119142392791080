import React from 'react'
import { Layout } from "antd";
import Sidbar from '../../components/Sidbar/Sidbar';
import ProfilAcceuil from './ProfilAcceuil';


const { Content } = Layout;
const MangementProfil = () => {
    return (
        <Layout
        style={{
          minHeight: "100vh",
          background:"#E1E1E1"
        }}
      >
        <Sidbar />
        <Layout className="site-layout">
          <Content
            style={{
              margin: "0 0px",
            }}
          >
            <div
              style={{
                padding: 24,
                minHeight: "100vh",
                background: "#E1E1E1",
                marginLeft:"26%"
              }}
            >
             <ProfilAcceuil  role='SuperAdmin'/>
            </div>
          </Content>
        </Layout>
      </Layout>
      )
    }

export default MangementProfil