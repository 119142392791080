import React, { useEffect, useState } from 'react'
import { Layout } from "antd";
import Ellipse11 from "../../assets/Ellipse11.png";
import Ellipse12 from "../../assets/Ellipse 12.png";
import Ellipse13 from "../../assets/Ellipse 13.png";
import HorLineToDo from "../../assets/HorLineToDo.png";
import HorLineInProgress from "../../assets/HorLineInProgress.png";
import HorLineDone from "../../assets/HorLineDone.png";
import Sidbar from '../../components/Sidbar/Sidbar';
import Tableau from '../../components/Tableau/Tableau';
import { useDispatch, useSelector } from 'react-redux';
import { getTasksAsync } from '../../features/Tasks/TaskSlice';
const { Content } = Layout;
const initialLists = [
  {
    title: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row",alignItems:"center" }}>
          <div>
            {" "}
            <img src={Ellipse11} alt="Ellipse11" />
          </div>
          <div className="titleboard">To Do</div>
        </div>
        <div>
          <img src={HorLineToDo} alt="HorLineToDo" />
        </div>
      </div>
    ),
    status: "todo",
  },
  {
    title: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row",alignItems:"center"  }}>
          <div>
            {" "}
            <img src={Ellipse13} alt="Ellipse13" />
          </div>
          <div className="titleboard">In Progress</div>
        </div>
        <div>
          <img src={HorLineInProgress} alt="HorLineToDo" />
        </div>
      </div>
    ),
    status: "doing",
  },
  {
    title: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row" ,alignItems:"center" }}>
          <div>
            {" "}
            <img src={Ellipse12} alt="Ellipse12" />
          </div>
          <div className="titleboard">Completed tasks under 30 days</div>
        </div>
        <div>
          <img src={HorLineDone} alt="HorLineToDo" />
        </div>
      </div>
    ),
    status: "done",
  },
];

const Community = () => {
  const [lists, setLists] = useState(initialLists);

  const data=useSelector((state)=>state.tasks.data)

  const dispatch=useDispatch()
  useEffect(()=>{
 
 
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
  



},[dispatch])

  return (
    <Layout
    style={{
      minHeight: "100vh",
      background:"#E1E1E1"
    }}
  >
    <Sidbar />
    <Layout className="site-layout">
      <Content
        style={{
          margin: "0 0px",
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: "100vh",
            background: "#E1E1E1",
          
            marginLeft:"26%"
          }}
        >
         {/* <Trello  role='Chef du departement'/> */}
         {lists.map((l,index) => index===0 &&  (
         <Tableau  role={JSON.parse(localStorage.getItem('role'))}                          
         data2={data[l.status]}
          />            
          ))}
        </div>
      </Content>
    </Layout>
  </Layout>
  )
}

export default Community