import * as React from 'react';
import { Card, Badge  } from 'antd';
import {  Dropdown } from 'antd';
import 'antd/dist/reset.css';
import JoindreficherIcon from "../assets/JoindreficherIcon.png"
import commentsIcon from "../assets/commentsIcon.png"

import './Task.css'
import Modalcenter from '../components/Modal/Modalcenter';
const tempColors = { "todo": 1, "doing": 2, "done": 3 };


function Task({title,status,projectName,datacomments,id_Task,data2,label,datafiles}) {
  const [type, setType] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [idTask, setIdTask] =  React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (    <Badge count={projectName} style={{background: "green !important" ,marginLeft:"75px"}}>

    <Card style={{width:"200px",height:"140px",
    marginTop:"30px",background:" #F9F9F9"}}
    
  >
    <div style={{display:"flex",justifyContent:"space-between"}}>
         <div className="cardTitle">{title}</div>
         <Dropdown   >
         <button>
      {/* <FontAwesomeIcon icon={faEllipsisV} /> */}
    </button>
    </Dropdown>
        
    </div>
    <div className="cardFooter">
    <div style={{display:"flex",marginTop:"30px"}}>
</div>
    <div className="collab" style={{justifyContent:"space-between",display:"flex"}}>
    <a className="collabPerson1">
    <div className={`label color${tempColors[status]}`}>{status==="todo" ?"pending"  :status==="doing"?"In progress":"Completed"}</div>

            </a>
            <a className="collabPerson1">
            <img src={commentsIcon}  alt='JoindreficherIcon' onClick={()=>{
       
       setType('comment')
       setIdTask(id_Task)
       handleShow()}}/>

              <img src={JoindreficherIcon}  alt='JoindreficherIcon'
                    onClick={()=>{
                      setFiles(datafiles)
                      setType('file')
                      handleShow()}}
            />
            </a>
          </div>
   </div>

  </Card>   
  <Modalcenter
        type={type}
        show={show}
        files={files}
        datacomments={datacomments}
        idTask={id_Task}
        data={data2}
        handleClose={handleClose}
        label={label}
        />
   </Badge>

  )
}

export default Task