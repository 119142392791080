import React,{useEffect, useState} from 'react';
import { Modal } from 'antd';
import 'antd/dist/reset.css';
import './Modal.css';
import Form1 from '../../shared/Form';
import Form2 from '../../shared/Form2';
import Form3 from '../../shared/Form3';
import Form5 from '../../shared/Form5';
const modalStyle = {
    top: "0px",
    bottom:"0px",
    right: '-100%',
    transition: 'right 0.5s',
  };
const Modal1 = ({title,type,detailsselect,detailsselect1,disable,modalIsOpen,handleCancel,detaisdata,data,detaisdataprojects}) => {

   if(detailsselect==="Project Details" ||( type&&type.Projects)){
    return (
      <div className="app">
      
       <Modal
   visible={modalIsOpen}
   onCancel={handleCancel}
   style={modalStyle}
   footer={null}  >
   <div style={{display:"flex",flexDirection:"column"}}>
     <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
       <div className="titleAddTicket">{title}</div>
       <div className='timeaddticket'>Today 09/02/2023</div>
     </div>
     
     <div>
         <Form2 disable={disable}  handleCancel={handleCancel} detaisdata={detaisdataprojects}/>
         </div>
 
   </div>
 </Modal>
     </div>
   )
   }else if(detailsselect1==="Form5" && (type&&type.Tasks)){
    
    return (

    <div className="app">
      
    <Modal
visible={modalIsOpen}
onCancel={handleCancel}
style={modalStyle}
footer={null}  >
<div style={{display:"flex",flexDirection:"column"}}>
  <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
    <div className="titleAddTicket">{title}</div>
    <div className='timeaddticket'>{`Today ${new Date().toLocaleDateString("fr")}` }</div>
  </div>
  
  <div>
      <Form1 disable={disable}  handleCancel={handleCancel} detaisdata={detaisdata} responsable={data}/>
      </div>

</div>
</Modal>
  </div>)
   }
   else if(detailsselect1!=="Form5" && (type&&type.Tasks)){
    
    return (

    <div className="app">
      
    <Modal
visible={modalIsOpen}
onCancel={handleCancel}
style={modalStyle}
footer={null}  >
<div style={{display:"flex",flexDirection:"column"}}>
  <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
    <div className="titleAddTicket">{title}</div>
    <div className='timeaddticket'>{`Today ${new Date().toLocaleDateString("fr")}` }</div>
  </div>
  
  <div>
      <Form5 disable={disable}  handleCancel={handleCancel} detaisdata={detaisdata} responsable={data}/>
      </div>

</div>
</Modal>
  </div>)
   }
   else if(detailsselect==="Ticket Details" || (type&&type.ProjectsTasks)){
    
    return (

    <div className="app">
      
    <Modal
visible={modalIsOpen}
onCancel={handleCancel}
style={modalStyle}
footer={null}  >
<div style={{display:"flex",flexDirection:"column"}}>
  <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
    <div className="titleAddTicket">{title}</div>
    <div className='timeaddticket'>{`Today ${new Date().toLocaleDateString("fr")}` }</div>
  </div>
  
  <div>
      <Form5 disable={disable}  handleCancel={handleCancel} detaisdata={detaisdata} responsable={data}/>
      </div>

</div>
</Modal>
  </div>)
   }
   else{
    return(
      <div className="app">
      
      <Modal
  visible={modalIsOpen}
  onCancel={handleCancel}
  style={modalStyle}
  footer={null}  >
  <div style={{display:"flex",flexDirection:"column"}}>
    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <div className="titleAddTicket">{title}</div>
      <div className='timeaddticket'>{`Today ${new Date().toLocaleDateString("fr")}` }</div>
    </div>
    
    <div>
        <Form3 disable={disable} handleCancel={handleCancel} />
        </div>
  
  </div>
  </Modal>
    </div>
    )
   }
 
}

export default Modal1