import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import io from "socket.io-client";
import Base_URL from "../../app/api/apiSlice";

const API_URL = `${Base_URL}/notifications`;

const socket = io(`${Base_URL}`);

export const notificationsSlide = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    type: 0,
    error: false,
    loading: false,
  },

  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getNotifcation: (state, action) => {
      // socket.on("getNotification", (data) => {
      //   state.notifications = [...state.notifications,data];
      // });
      state.loading = false;
      state.error = false;
      state.notifications = [...state.notifications, ...action.payload];
    },
    getNotifcationRealTime: (state, action) => {
      state.loading = false;
      state.error = false;
      state.notifications = [...state.notifications, action.payload];
    },
    addNotifications: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },
    deleteNotifications: (state, action) => {
      state.notifications = state.notifications.filter(
        (e) => e._id !== action.payload._id
      );
    },
    handelChangeTypeNotification: (state, action) => {
      state.type = action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
  },
});

//get Tous les comments
export const getNotifcationsAsync = (data) => async (dispatch) => {
  api.get("/getAllnotification", { params: { id: data } }).then((response) => {
    dispatch(getNotifcation(response.data));
  });
  socket.on("notification", (data) => {
    dispatch(getNotifcationRealTime(data));
  });
  return () => {
    socket.disconnect();
  };
};

export const addNotifcationsAsync = (data) => async (dispatch) => {
  api.post("/addNotification", data).then((response) => {
    dispatch(addNotifications(response.data));
  });
};
export const deleteNotifcationsAsync = (data) => async (dispatch) => {
  api.delete("/deletenotification", { data: { id: data } }).then((response) => {
    dispatch(deleteNotifications(response.data));
  });
};
export const updateNotifactionsAsync = () => async (dispatch) => {
  api
    .put(`/updatenotification`)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const handelChangeTypeNotificationAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeTypeNotification(e));
  } catch (err) {
    dispatch(setError());
  }
};

export const {
  getNotifcation,
  handelChangeNotification,
  handelChangeTypeNotification,
  deleteNotifications,
  addNotifications,
  getNotifcationRealTime,
  setError,
  setLoading,
} = notificationsSlide.actions;
export default notificationsSlide.reducer;
