import React, { useEffect, useState } from 'react'
import { extend } from '@syncfusion/ej2-base';
import {

  Typography ,
  Input

} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import Avatar from '../../assets/uploadyourimage.png'
import './Team.css';
import { registerLicense } from '@syncfusion/ej2-base';
import { useDispatch, useSelector } from 'react-redux';
import {  getProjectsCalanderAsync } from '../../features/Projects/projectSlice';
import { getTaskscalanderAsync } from '../../features/Tasks/TaskSlice';
import { deleteUserAsync, getUsersAsync, getUsersTeamMemberAsync, sendaddPasswordlinkAsync, sendEmailAllUserAsync, updateActiveUserAsync, updateImageUserAsync } from '../../features/Users/userSlice';
import { Table } from 'antd';
import deleteTask from "../../assets/delete.png";
import { UploadOutlined } from '@ant-design/icons/lib/icons';
import { useCallback } from 'react';
import { Switch } from 'antd';

import Text1 from '../../shared/Text'
registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpAaV1AQmFJfFBmRmlYeFRxc0U3HVdTRHRcQl5hSn5Qd0JiUXtdcXw=;Mgo+DSMBPh8sVXJ0S0J+XE9AflRGQmFLYVF2R2BJfFRxc19HZ0wgOX1dQl9gSX1RdUVgWHhfeHBRTmg=;ORg4AjUWIQA/Gnt2VVhkQlFacldJWXxLfEx0RWFab116dVJMYVtBNQtUQF1hSn5QdEZjWn5ZcXxRQGVU;MTQyNzgyOUAzMjMwMmUzNDJlMzBvRU5kN1VxZEtVVzJraDY3Y0ZUaXFwQjhweEFzV1lIdnN2OTRoQ09iRnNnPQ==;MTQyNzgzMEAzMjMwMmUzNDJlMzBXVnRrNmxSa0gxM3h1RzRHMnFZd291MXZzY0Ixay9TdVNXWktQbDJVaEtzPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmdWfFFpR2NbfE5zflRBal1WVAciSV9jS31TdUdgWXxfd3VdQ2NYWQ==;MTQyNzgzMkAzMjMwMmUzNDJlMzBWYUk3Y1h2eldNMW8zNktZMXMzb0pPbDZFMVAwdmtWMk8wNUNDQnZ4NTVFPQ==;MTQyNzgzM0AzMjMwMmUzNDJlMzBITEs1bnFZOU1BLzhNVnJFYk1IK3NUZ2hzWlYvMHExcFlyR3ByNHdpUFBZPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJWXxLfEx0RWFab116dVJMYVtBNQtUQF1hSn5QdEZjWn5ZcXxTR2JU;MTQyNzgzNUAzMjMwMmUzNDJlMzBQMkhnOWVPMkhkdHBRcGpVSnBOdFgzcjVuKy8zOXdhcXZlbnE1Ry92SktvPQ==;MTQyNzgzNkAzMjMwMmUzNDJlMzBQQ3BYOFlpOGdqTDAxZFR6Y3E1N2RXRkF2V3FmVjc5eHFVVDdoTVpGUXIwPQ==;MTQyNzgzN0AzMjMwMmUzNDJlMzBWYUk3Y1h2eldNMW8zNktZMXMzb0pPbDZFMVAwdmtWMk8wNUNDQnZ4NTVFPQ==');
const { Search } = Input;
const { Text } = Typography;

const Team = () => {
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [infoList,setInfoList]=useState(null)
    const datataskscalander=useSelector((state)=>state.tasks.datataskscalander)
    const dataprojectscalander=useSelector((state)=>state.projects.dataprojectscalander)
    const dataTeamMember = useSelector((state) => state.users.dataTeamMember);
    const datausers = useSelector((state) => state.users.datausers);
    const [image, setImage] = useState(null);
    const [id, setId] = useState("");
    const [searchText, setSearchText] = useState('');

    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const data = extend([], datataskscalander&& datataskscalander.blockData, null, true);
    const dispatch=useDispatch()
  
    
    function getEmployeeName(value) {
        return value.resourceData[value.resource.textField];
    }
    const handleDrop = useCallback((acceptedFiles,id) => {
      const datato={images:acceptedFiles,id:id}
      dispatch(updateImageUserAsync(datato,id,'team'));
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setImage(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    }, []);
    const handleCropChange = useCallback((newCrop) => {
      setCrop(newCrop);
    }, []);
    const handleImageCrop = useCallback(() => {
      const canvas = document.createElement('canvas');
      const imageElement = document.createElement('img');
      imageElement.src = image;
      imageElement.addEventListener('load', () => {
        const scaleX = imageElement.naturalWidth / imageElement.width;
        const scaleY = imageElement.naturalHeight / imageElement.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          imageElement,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
        setImage(canvas.toDataURL());
      });
    }, [crop, image]);
    function getEmployeeImage(value) {
        return getEmployeeName(value).toLowerCase();
    }
    function getEmployeeDesignation(value) {
        return value.resourceData.Designation;
    }
    function resourceHeaderTemplate(props) {
        return (<div className="template-wrap"><div className="employee-category"><div className={"employee-image " + getEmployeeImage(props)}></div><div className="employee-name">
            {getEmployeeName(props)}</div><div className="employee-designation">{getEmployeeDesignation(props)}</div></div></div>);
    }
    const handleTableChange = (pagination, filters, sorter) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
  
      // // `dataSource` is useless since `pageSize` changed
      // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      //   setData([]);
      // }
    };
  
    useEffect(()=>{
       

       dispatch(getProjectsCalanderAsync())
       dispatch(getTaskscalanderAsync())
       dispatch(getUsersTeamMemberAsync());
      dispatch(getUsersAsync())
    },[dispatch])
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
              // dispatch(sendaddPasswordlinkAsync({email}))
       
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          // setError(error.response.data.message);
          // setMsg("");
        }
      }
    };

    const columns = [
      {
        title: 'Image',
        dataIndex: 'images',
        key: 'title',
        render: (images, record) => (
          <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
         {images.length>0 ? images.map((e,key)=>
            
              <div key ={key} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img  src={e.path} style={{width:"40px",marginLeft:"5px",height:"40px",borderRadius:"50%"}} alt=''/>
              </div>
          
          
  
    ): <div  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
     <Dropzone onDrop={(e)=>handleDrop(e,record._id)}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={Avatar} alt='' style={{width:"46px",borderRadius:"50%",height:"46px"}}/>
        </div>
      )}
    </Dropzone>
    {image && (
      <ReactCrop
        src={image}
        crop={crop}
        onChange={handleCropChange}
      />
    )}
    {crop.width && crop.height && (
      <button onClick={handleImageCrop}>Crop Image</button>
    )}
</div>}
               
           </div>  )
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'title',
        render: (text) => <div style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textTransform: "uppercase",
        color: "#787486"}}>{text}</div>,
      },
      {
        title: 'ROLE',
        dataIndex: 'description',
        key: 'title',
        render: (text) => <div style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textTransform: "uppercase",
        color: "#787486"}}><Text  ellipsis>{text}</Text></div>,
      },
      {
        title: 'DEPARTEMENT',
        dataIndex: 'id_departement',
        key: 'title',
        render: (text) => <div style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textTransform: "uppercase",
        color: "#787486"}}>{text && text.Name}</div>,
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'title',
        render: (text) => <div  style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        color: "#787486"}}><Text  ellipsis>{text} </Text></div>,
      },
      {
        title: 'Status',
        dataIndex: '_id and active',
        key: 'action',
        render: (id,record) => JSON.parse(localStorage.getItem('role'))==='SuperAdmin'? (
           <div className="cardProjectFooter">
          <div className="containaircardproject"style={{alignItems:"center"}}>
          <Switch checked={record.active} style={{width:"15px"}} onChange={(checked)=>{
            dispatch(updateActiveUserAsync(record._id,checked))}} />
          {/* <div style={{cursor:'pointer'}} onClick={()=>{
            dispatch(updateActiveUserAsync(record._id,!record.active))}}>{record.active ? (
          <CheckCircleOutlined style={{ color: 'green' }} /> 
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}</div> */}
           
    
            <div style={{display:"flex",height:"100%",alignItems:"center",cursor:"pointer",marginLeft:"5px"}}onClick={()=>dispatch(deleteUserAsync(record._id))}>
            <div><img  src={deleteTask}   alt='deleteTask' style={{width:"25px",height:"25px",color:'red'}}/></div>
            
            </div>
          </div>
        </div>
        ):JSON.parse(localStorage.getItem('role'))==='Admin' &&(
          <div className="cardProjectFooter">
         <div className="containaircardproject">
  
   
           <div style={{display:"flex",height:"100%",alignItems:"center",justifyContent:"space-between"}}>
         
           <div>{record.active ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}</div>
           
           </div>
         </div>
       </div>
       )
        ,
      },
     
    
     
      
     
    ];
    const handleSearch = (text) => {
            setSearchText(text.target.value);
    };
    const filteredData = datausers.filter((item) =>
    item &&item.Name && item.Name.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <div className='containercalndrier'>
      <div style={{ display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <div style={{ display:"flex",flexDirection:"row",marginTop: "30px",
marginLeft: "51px"}}>
      <input placeholder="Search by name" value={searchText} onChange={handleSearch} style={{ width: 200 ,borderRadius:'4px',border:" 0.910839px solid #787486"}} />
    
        {dataTeamMember.length>0 ? (<>
            <div style={{marginLeft:"30px",display:"flex",justifyContent:"center"}}>
        <img  src={dataTeamMember[0] && dataTeamMember[0].images[0] && dataTeamMember[0].images[0].path} alt="Avatar" style={{width:"46px",borderRadius:"50%",height:"46px"}}/>
 </div>
        {dataTeamMember && dataTeamMember.map((e,index)=> index>0 && (
           <div style={{marginLeft:"-20px"}} key ={index}>
          {e.images.length>0 ? <img  src={e.images && e.images[0]&&e.images[0].path} alt="Avatar" style={{width:"46px",borderRadius:"50%",height:"46px"}}/> : <img  src={Avatar} alt="Avatar" style={{width:"46px",borderRadius:"50%",height:"46px"}}/>}
           </div> 
        ))} </>
        ):(
          <img  src={Avatar} alt='Avatar' style={{width:"46px",borderRadius:"50%",height:"46px"}}/>
        )}   
        
        
        
         </div> 
         <div style={{display:"flex"}}>
       {JSON.parse(localStorage.getItem('role'))==="SuperAdmin"&&  <><div className="file-input-container">
      <button  onClick={()=>dispatch(sendEmailAllUserAsync())}>                  
      <Text1 content="Send Email to  All Team Member" classname="AddTask" />
</button>

    </div>
         <div className="file-input-container">
      <label htmlFor="file-input">                  <Text1 content="Import All Team Member" classname="AddTask" />
</label>
      <input type="file" id="file-input" name="file" onChange={(e)=>              dispatch(sendaddPasswordlinkAsync(e.target.files[0]))}/>
    </div></>}
    </div>
         </div> 
         
       
    <div className='schedule-control-section'>
    <div className='col-lg-12 control-section'>
        <div className='control-wrapper drag-sample-wrapper'>
            <div className="schedule-container">
            <section className="board-body" style={{marginTop:"50px"}}>
                <Table  
                 columns={columns} dataSource={filteredData}        
                 pagination={tableParams.pagination}
               loading={loading}
               onChange={handleTableChange}
                    scroll={{
                      y: 840,
                    }}
                />
                </section>
            </div>
        </div>
    </div>
  </div>
  </div>
  )
}

export default Team