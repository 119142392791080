import React from 'react';
import { Card, Row, Col, Typography } from 'antd';

const { Text } = Typography;
const Minicard = ({ name, value, growth, startContent, endContent }) => {
    const textColorSecondary = "#555"; // Define the color for secondary text (adjust as needed)
    const textColor = "#333"; // Define the color for the text (adjust as needed)
  
    return (
             <div className='minicardstast'>
        <Row
         className='sousminicardstast'
        >
          {startContent}
  
          <Col flex="auto" style={{ marginLeft: startContent ? "18px" : "0px" }}>
            <Text
              style={{ lineHeight: '100%', color: textColorSecondary, fontSize: 'sm' }}
            >
              {name}
            </Text>
            <Text
              style={{ color: "#333", fontSize: '2xl',marginLeft:"10px",fontWeight:"700" }}
            >
              {value}
            </Text>
            {growth ? (
              <Row align="center">
                <Text style={{ color: 'green', fontSize: 'xs', fontWeight: '700', marginRight: '5px' }}>
                  {growth}
                </Text>
                <Text style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 'xs', fontWeight: '400' }}>
                  since last month
                </Text>
              </Row>
            ) : null}
          </Col>
          <Col>{endContent}</Col>
        </Row>
      </div>
    );
  };

export default Minicard