import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Base_URL from "../../app/api/apiSlice";
const API_URL = `${Base_URL}/roles`;

export const rolesSlide = createSlice({
  name: "roles",
  initialState: {
    dataroles: [],

    error: false,
    loading: false,
  
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    addRole:  (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataroles = [ ...state.dataroles,action.payload];
    },
    getRoles: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataroles = [...action.payload];
    },
    
    setError: (state) => {
      state.error = true;
    },
  },
});
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
//get Tous les users
export const getRolesAsync = () => async (dispatch) => {
  api
    .get("/getRole")
    .then((response) => {
    

      dispatch(getRoles(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};

export const addRoleAsync = (data) => async (dispatch) => {
  api
    .post("/addRole",data)
    .then((response) => {
    

      dispatch(addRole(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const {  getRoles, setError, setLoading ,addRole} =
rolesSlide.actions;
export default rolesSlide.reducer;
