import React, {useEffect, useState } from "react";
import "./Login.css";
import { Col } from "antd";
import { Checkbox } from 'antd';
import connection from '../../assets/connection.png'
import Button from "../../shared/Button";
import Google from "../../assets/google.png";
import Text from "../../shared/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateNewPassewordAsync } from "../../features/Users/userSlice";
import { Container, Row } from "react-bootstrap";
import Cercular from "./Cercular";

const PasswordUpdate = () => {
	const [password, setPassword] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
    const verifylink=useSelector((state)=>state.auth.verifylink)
    const data=useSelector((state)=>state.auth.data)
    const validator=useSelector((state)=>state.auth.validator)
    const navigate=useNavigate()
    const param = useParams();
    const dispatch=useDispatch()
    const handleSubmit =  (e) => {
	
            dispatch(updateNewPassewordAsync(param.token,password))  
            e.preventDefault();
            navigate('/')
          
	};


  return (
    <Container  fluid>
    <Row>
       <Col xs={12} className="loginBackground1">
       <div className="formBackground" >
              <Cercular />
              
  
      
        </div>
</Col>
<Col xs={12} className="loginBackground">
<div className="formBackground1" >
       
          <Col xs={24} lg={24} xl={24}  style={{
            width:"80%",
            display:"flex",
            justifyContent:"center"

          }}>

            
            <div style={{flexDirection:"column",width:"100%",display:"flex",padding:"10%"}}>
            <div className="titrelogin" >
                Welcome To your ticketing system Manager
              </div>
              <div className="titre2login" >Create your Password Account</div>
         
  
              <div className="labelInputlogin">Password</div>
              <input className="inputlogin" placeholder="Password"	onChange={(e) => setPassword(e.target.value)}
							value={password}  type='password'/>
              <div style={{display:"flex",justifyContent:"flex-end" ,width:"100%"}}>
  
              </div>
              <div style={{display:"flex",justifyContent:"center" ,width:"100%"}} >
              <Button classname="buttonsiginin" text="Create Password" style={{marginBottom:"40px"}} event={handleSubmit}/>
              {/* <Button classname="buttonsigininwithgoogle" text="Continue with Google" img={Google} /> */}</div>
            </div>
              
          </Col>
      
        </div>
</Col>
    </Row>

  </Container>
  
  );
};

export default PasswordUpdate;
