import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home.js/Home";
import Layout from "./components/Layout/Layout";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import TeamMebmber from "./pages/TeamMember/TeamMebmber";
import TrelloUser from "./pages/User/TrelloUser";
import Missing from "./components/Missing/Missing";
import PasswordReset from "./pages/Login/PasswordReset";
import ForgetPassword from "./pages/Login/ForgetPassword";
import SiginUp from "./pages/Login/SiginUp";
import PasswordUpdate from "./pages/Login/PasswordUpdate";
import Community from "./pages/Community/Community";
import Acceuil from "./pages/Acceuil/Acceuil";
import Statistics from "./pages/Statistics/Statistics";
import MangementProfil from "./pages/MangementProfil/MangementProfil";
import User from "./pages/User/User";

function App() {
  return (
    <>
    <Routes>

    <Route path="/" element={<Layout />}>
      {/* public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/siginup" element={<SiginUp />} />

      <Route path="/password-forget" element={<ForgetPassword />} />

      <Route path="/password-reset/:id" element={<PasswordReset />} />
      <Route path="/change-reset/:token" element={<PasswordUpdate />} />

      {/* we want to protect these routes */}
      <Route element={<RequireAuth allowedRoles={["Admin","Developer","Markting","BI Developper","community manager","SuperAdmin","Chef du departement","Recrutement"]} />}>
      <Route path="/statistics" element={<Statistics />} />
         <Route path="/profil" element={<MangementProfil />} />

      </Route>

      <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
        <Route path="/Home" element={<Home />} />

      </Route>
      <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
        <Route path="/Acceuil" element={<Acceuil />} />


      </Route>
      <Route element={<RequireAuth allowedRoles={["Developer","Markting","community manager","BI Developper"]} />}>
        <Route path="/editor" element={<TeamMebmber />} />

      </Route>


      <Route element={<RequireAuth allowedRoles={["Chef du departement"]} />}>
        <Route path="/admin" element={<User />} />

      </Route>
      <Route element={<RequireAuth allowedRoles={["Recrutement"]} />}>
        <Route path="/Recrutement" element={<User />} />

      </Route>
    
      

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  </Routes>
  </>
  );
}

export default App;
