import React from 'react'
import { Layout } from "antd";
import Sidbar from '../../components/Sidbar/Sidbar';
import Trello from '../../components/Trello/Trello';
import TrelloAcceuil from './TrelloAcceuil';

const { Content } = Layout;

const Acceuil = () => {
 
  return (
    <Layout
    style={{
      minHeight: "100vh",
      background:"#E1E1E1"
    }}
  >
    <Sidbar />
    <Layout className="site-layout">
      <Content
        style={{
          margin: "0 0px",
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: "100vh",
            background: "#E1E1E1",
            marginLeft:"26%"
          }}
        >
         <TrelloAcceuil  role='SuperAdmin'/>
        </div>
      </Content>
    </Layout>
  </Layout>
  )
}

export default Acceuil