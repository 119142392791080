import React from 'react'

const Button = (props) => {
    const { classname,text,img,event }=props
    if(img){
        return (
            <button className={classname}>
             
                  <div>
                    <img  src={img} alt='' style={{marginLeft:"-300%"}}/>
                  </div>
                <div style={{display:"flex",justifyContent:"center"}}>
        
                {text}
                </div>
                
                </button>
          )
    }else{
        return (
            <button className={classname} onClick={event}>
             
              
                <div style={{display:"flex",justifyContent:"center"}}>
        
                {text}
                 
                </div>
                
                </button>
          )
    }
  
}

export default Button