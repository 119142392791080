import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
　　　import Carousel from 'react-bootstrap/Carousel';

import {
  Form,
  Input,
  Button,
  DatePicker,
  Upload,
  Switch
} from "antd";
import Select from 'react-select';
import "./Form.css";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsAsync, getProjectsbydepartementAsync } from "../features/Projects/projectSlice";
import {
  addTaskAsync,
  getTasksArchivedAsync,
  getTasksAsync,
  handelChangeAsync,
  handelChangeDateAsync,
  handelChangeProjectAsync,
  handelChangeResponsableAsync,
  restdataAsync,
  updateDateTaskAsync,
} from "../features/Tasks/TaskSlice";
import {
  getUsersLeadAsync,
  getUsersTeamMemberAsync,
  getUsersTeamMemberbydepartementAsync,
} from "../features/Users/userSlice";
import { addNotifcationsAsync } from "../features/Notification/NotificationSlice";
import { FilePdfOutlined, FileWordOutlined, FolderViewOutlined } from "@ant-design/icons/lib/icons";
const { Option } = Select;

const Form1 = ({ disable, handleCancel, detaisdata }) => {
  const [formLayout] = useState("vertical");
  const [infoList, setInfoList] = useState([]);
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // ... other options
  ];
  const [selectedOptions2, setSelectedOptions2] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([
    // Initially selected options
    { value: "defaultOptionValue", label: "Default Option" }
  ]);

  const handleSelectChange = (newValue) => {
    setSelectedOptions(newValue);
  };
  const handleSelectChange2 = (newValue) => {

    setSelectedOptions2(newValue);

  };
  const addAnotherValue = () => {
    const newValueToAdd = { value: "newValue", label: "New Value" };
  };

  const dispatch = useDispatch();
  const dataprojects = useSelector((state) => state.projects.dataprojects);
  const dataprojectsbydepartement = useSelector((state) => state.projects.dataprojectsbydepartement);

  const datataskstosend = useSelector((state) => state.tasks.datataskstosend);
  const dataTeamMemberbydepartement = useSelector((state) => state.users.dataTeamMemberbydepartement);
  const dataProjectLead = useSelector((state) => state.users.dataProjectLead);

  const alldata = useSelector((state) => state.tasks.alldata);
  const alldataourdepartemnt = useSelector((state) => state.tasks.alldataourdepartemnt);
  const alldatadepartementexterne = useSelector((state) => state.tasks.alldatadepartementexterne);
  const ourdepartementtaskP = useSelector((state) => state.tasks.ourdepartementtaskP);

  const [activiatenotifcation,setActiviatenotifcation]=useState(true)

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 14 },
        }
      : null;
  const buttonItemLayout =
    formLayout === "vertical"
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : null;
  const onChange = (checked) => {
    console.log(`selected ${checked}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  useEffect(() => {
    // dispatch(getTasksAsync());

    dispatch(getUsersLeadAsync(JSON.parse(localStorage.getItem('departement'))));
  
    dispatch(getUsersTeamMemberbydepartementAsync(JSON.parse(localStorage.getItem('departement'))));

    dispatch(getProjectsAsync());
    if(JSON.parse(localStorage.getItem('role'))==='Admin'){
dispatch(getProjectsbydepartementAsync(JSON.parse(localStorage.getItem('departement'))))
    }
    
  }, [dispatch]);

useEffect(()=>{
  setSelectedOptions(dataprojectsbydepartement && dataprojectsbydepartement.filter((e)=>e._id===datataskstosend.id_project).flatMap((project) =>
  project.TeamMember.map((member) => ({
    value: member._id, // You can use a unique identifier for value
    label: member.Name,
  }))
))
},[dataprojectsbydepartement,datataskstosend])
  
  return (
    <div style={{ marginTop: "18px" }}>
      <Form layout={formLayout} onSubmitCapture={(e)=>{
          if (!disable)  {
            Swal.fire({
              title: 'Do you want to add this Ticket?',
              showDenyButton: true,
              confirmButtonText: 'Yes',
            
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire('task add avec sucess!', '', 'task add avec sucess')
                dispatch(
                  addTaskAsync({
                    ...datataskstosend,
                    responsable:datataskstosend.responsable.filter((e)=>e!=="undefiend"),
                    TeamMember: [
                      ...(selectedOptions || []).map((option) => option.value),
                      ...(selectedOptions2 || []).map((option) => option.value),
                    ],                            ajouterPar:`${JSON.parse(localStorage.getItem('id'))}`,
                    activiatenotifcation:activiatenotifcation,
                  
                    infoList: infoList,
                   
                    DateCreation: "",
                    departementExterne:false
                  })
                );
                setInfoList([])
                dispatch(restdataAsync())
                handleCancel();
         
              } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
              }
            })
         

            
          }else {
            Swal.fire({
              title: 'Do you want to Edit this Ticket?',
              showDenyButton: true,
              confirmButtonText: 'Yes',
            
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire('task edit avec sucess!', '', 'task edit avec sucess')
                dispatch(updateDateTaskAsync({DateCreation :(datataskstosend.DateCreation && datataskstosend.DateCreation.length>0 )? datataskstosend.DateCreation:detaisdata.DateCreation,id:detaisdata._id,Priority:(datataskstosend.Priority && datataskstosend.Priority.length>0) ?datataskstosend.Priority:detaisdata.Priority,responsable:(datataskstosend.responsable && datataskstosend.responsable.length>0) ? datataskstosend.responsable:[],
                  departementExterne:false}))

         
              } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
              }
            })
            
            handleCancel()
           
          }
      }}>
        {/* <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}> */}
        {JSON.parse(localStorage.getItem('role'))==='Admin' ?  <Form.Item
          label="Name of Project"
          {...formItemLayout}
          // style={{width:"50%"}}
        >
          <select
            disabled={disable}
            showSearch
            placeholder="Critical"
            className={
              disable
                ? "selectProjectLeadgranddisable"
                : "selectProjectLeadgrand"
            }
            name="id_project"
            optionFilterProp="children"
            onChange={(e) => dispatch(handelChangeProjectAsync(e))}
            onSearch={onSearch}
          >
           
          

            {!disable?
             <> 
             <option disabled selected> select your project</option>
            {dataprojectsbydepartement && dataprojectsbydepartement.map((e) => (
              <option value={`${e._id}:${e.ProjectLeadName && e.ProjectLeadName._id}:${e.TeamMember && e.TeamMember._id}`}>{e.Name}</option>
              ))} </>
              :
               <option >{detaisdata && detaisdata.id_project.Name}</option>
              }
            <option value="other">New Project</option>

          </select>
        </Form.Item>: 
        <Form.Item
          label="Name of Project"
          {...formItemLayout}
          // style={{width:"50%"}}
        >
          <select
            disabled={disable}
            showSearch
            placeholder="Critical"
            className={
              disable
                ? "selectProjectLeadgranddisable"
                : "selectProjectLeadgrand"
            }
            name="id_project"

            optionFilterProp="children"
            onChange={(e) => dispatch(handelChangeProjectAsync(e))}
            onSearch={onSearch}
          >
           
          

            {!disable?
             <> 
             <option disabled selected> select your project</option>
            {dataprojects && dataprojects.map((e) => (
              <option value={`${e._id}:${e.ProjectLeadName && e.ProjectLeadName._id}:${e.TeamMember && e.TeamMember._id}`}>{e.Name}</option>
              ))} </>
              :
               <option >{detaisdata && detaisdata.id_project.Name}</option>
              }
            <option value="other">New Project</option>

          </select>
        </Form.Item>}
        {datataskstosend.id_project &&(
          <>   <div style={{display:"flex",justifyContent:"space-between",width:"528px"}}>
                <Form.Item
                label="Lead Team members"
                {...formItemLayout}
              >
     
      <Select
      
        value={selectedOptions}
        onChange={handleSelectChange}
        className={disable ? "selectProjectLeaddisable" : "selectProjectLead"}
  
        isMulti
      />
         </Form.Item>
         <Form.Item
                label=" add Other Team Members"
                {...formItemLayout}
              >
     < Select
        value={selectedOptions2}
        options={dataTeamMemberbydepartement&& dataTeamMemberbydepartement.filter((e)=>e._id!==datataskstosend.id_project).map(option => ({ value: option._id, label: option.Name }))}
        className={disable ? "selectProjectLeaddisable" : "selectProjectLead"}
        onChange={handleSelectChange2}
        isMulti
      />

            
              </Form.Item>    </div></>
        )}
        {datataskstosend.id_project==="other" && (<>
      <Form.Item label="New Project" {...formItemLayout}>
      <Input
        className="inputfiledaddticket"
        disabled={disable}
        placeholder={disable ? detaisdata && detaisdata.title : ""}
        name="new project"
        onChange={(e) => dispatch(handelChangeAsync(e))}
      />
    </Form.Item>
    <Form.Item label="Description Project" {...formItemLayout}>
    <Input
      className="inputfiledaddticket"
      disabled={disable}
      placeholder={disable ? detaisdata && detaisdata.title : ""}
      name="DescriptionProject"
      value={datataskstosend.DescriptionProject ? datataskstosend.DescriptionProject:""}

      onChange={(e) => dispatch(handelChangeAsync(e))}
    />
  </Form.Item></>
     )}
      
    
        <Form.Item label="DescriptionTask" {...formItemLayout}>
          <textarea
            className="textareafiledaddticket"
            disabled={disable}
            placeholder={disable ? detaisdata && detaisdata.title : ""}
            name="title"

            value={datataskstosend.title ? datataskstosend.title:""}
            onChange={(e) => dispatch(handelChangeAsync(e))}
          />
        </Form.Item>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "529px",
          }}
        >
          
            <>
        {localStorage.getItem('role')!=="Admin"?
         <Form.Item
         label="Team members"
         {...formItemLayout}
       >
        <select
  style={{ width: 500 ,height:'fit-content !important'}}
  
  
  className={detaisdata &&  (detaisdata.status==="done")?"selectProjectLeadgranddisable":"selectProjectLeadgrand"}
  disabled={detaisdata && detaisdata.status==="done"}
  onChange={(e) => dispatch(handelChangeResponsableAsync(e.target.value))}
  placeholder={detaisdata &&
    detaisdata.responsable.map(
      (e1) => e1.id_role.Name !== "Admin" && `${e1.Name}/`
    )}
  defaultValue={detaisdata &&
    detaisdata.responsable.filter(
      (e1) => e1.id_role.Name !== "Admin"
    ).map((e1)=>e1._id)}
>
  <option selected disabled> ----Lead name----</option>
   {dataTeamMemberbydepartement &&
             dataTeamMemberbydepartement.map((e) => (
               <>  <option  value={`${e._id}`}  >
    <span > {
                        e.Name
                       
                   }</span>
  </option>
               </>
    ))}

</select>
     
       </Form.Item>:(
         <Form.Item
         label="Team members"
         {...formItemLayout}
       >
        <select
  style={{ width: 500 ,height:'fit-content !important'}}
  
  
  className={detaisdata &&  (detaisdata.status==="done")?"selectProjectLeadgranddisable":"selectProjectLeadgrand"}
  disabled={detaisdata && detaisdata.status==="done"}
  onChange={(e) => dispatch(handelChangeResponsableAsync(e.target.value))}
  placeholder={detaisdata &&
    detaisdata.responsable.map(
      (e1) => e1.id_role.Name !== "Admin" && `${e1.Name}/`
    )}
  defaultValue={detaisdata &&
    detaisdata.responsable.filter(
      (e1) => e1.id_role.Name !== "Admin"
    ).map((e1)=>e1._id)}
>
  <option selected disabled> ----Lead name----</option>
   {dataTeamMemberbydepartement &&
             dataTeamMemberbydepartement.map((e) => (
               <>  <option  value={`${e._id}`}  >
    <span > {
                        e.Name
                       
                   }</span>
  </option>
               </>
    ))}

</select>
     
       </Form.Item>
       )
        }
          </>
          
        </div> */}
        {(JSON.parse(localStorage.getItem('id'))!=="Chef du departement" && disable) && (
               <div
               style={{
                 display: "flex",
                 justifyContent: "space-between",
                 width: "529px",
               }}
             >
                  <Form.Item label="Due Date/Time" {...formItemLayout}>
       
       <DatePicker
     disabled={(detaisdata && JSON.parse(localStorage.getItem('role')) !== "Admin"  )  ||  (detaisdata.status==="done")}
       placeholder={detaisdata.DateCreation !== "" ? detaisdata.DateCreation : "choisi Date" }
         onChange={(date, dateString) =>
           dispatch(handelChangeDateAsync(date, dateString, 1))
         }
       />
    
   
   </Form.Item>
               <Form.Item
                 label="Task Priority"
                 {...formItemLayout}
                 style={{ width: "50%" , marginLeft: "50px" }}
               >
                 <select
                   showSearch
                   className={((detaisdata && JSON.parse(localStorage.getItem('role')) !== "Admin"  ) ||  (detaisdata.status==="done"))?"selectProjectLeaddisable":"selectProjectLead"}
                  disabled={(detaisdata && JSON.parse(localStorage.getItem('role')) !== "Admin"  )  ||  (detaisdata.status==="done")}
                   placeholder="Critical"
                   optionFilterProp="children"
                   name="Priority"
                   value={detaisdata.Priority}

                   onChange={(e) => dispatch(handelChangeAsync(e))}
                   onSearch={onSearch}
                 >
                   <option disabled  selected>
                   {detaisdata.Priority !=="undefined"  ? detaisdata.Priority : "choose Priority of task"}
                   </option>
     
                   <option value="High">High</option>
                   <option value="Low">Low</option>
                   <option value="Normal">Normal</option>
                   <option value="Critical">Critical</option>
                 </select>
               </Form.Item>
              
             </div>
      
        )}
        
   
        <Form.Item
          label="Attached File"
          {...formItemLayout}
          style={{ width: "50%" }}
        >

          
            <Upload
            name="file"
            fileList={infoList}
            onChange={({ fileList }) => {
            
                setInfoList(fileList)
              


            }}
            multiple
          >
            <Button
              className="attaachfile"
              style={{ borderColor:infoList.length >0 && "green"}}

              icon={<UploadOutlined />}

            >
              {" "}
              Attach File
            </Button>
          </Upload>
          
          
        </Form.Item>
       
          <> 
          {!disable && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                label="Activate Notifications"
                {...formItemLayout}
                style={{ width: "50%" }}
              ></Form.Item>
              <div style={{ marginRight: "30px" }}>
                <Switch defaultChecked={activiatenotifcation}  onChange={onChange}/>
              </div>
            </div>      )}
           { (( JSON.parse(localStorage.getItem('role')) === "Admin")|| ( JSON.parse(localStorage.getItem('role')) === "SuperAdmin") || (disable && detaisdata.status!=="done")  || JSON.parse(localStorage.getItem('role')) === "Chef du departement" || JSON.parse(localStorage.getItem('role')) === "Recrutement" ||JSON.parse(localStorage.getItem('role')) === "Chef du departement" || (JSON.parse(localStorage.getItem('role')) === "Developer"&& !disable) ) &&
            <Form.Item
              {...buttonItemLayout}
              style={{ display: "flex", justifyContent: "center" }}
            >
              
              <button
                className="buutonsaveaddticket"
                type="submit"
              >
              {disable ? "Edit Ticket":"Save"}  
              </button>
              <div className="buutoncanceladdticket" onClick={()=>handleCancel()}>Cancel</div>
            </Form.Item>}
          </>
  
      </Form>
    </div>
  );
};

export default Form1;
