import { Layout, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row, Dropdown, Menu, Avatar } from "antd";
import Line from "../../assets/Line.png";
import notification from "../../assets/notification.png";
import LineProject from "../../assets/LineProject.png";
import LineCompleted from "../../assets/LineCompleted.png";
import LineProgress from "../../assets/LineProgress.png";
import LinetoDo from "../../assets/LinetoDo.png";
import LineOption from "../../assets/LineOption.png";
import LineJobPost from "../../assets/LineJobPost.png";

import bell from "../../assets/bell.png";
import Ellipse18 from "../../assets/Ellipse18.png";
import closenotfi from "../../assets/closenotfi.png";
import { Spin } from "antd";
import Iconstats from "../../assets/Iconstats.png";
import io from "socket.io-client";

import Text from "../../shared/Text";
import "./Sidbar.css";
import Task from "../../shared/Task";
import { Steps } from "antd";
import Button from "../../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { getTasksAsync } from "../../features/Tasks/TaskSlice";
import { getProjectsAsync } from "../../features/Projects/projectSlice";
import { getCommentsAsync } from "../../features/Comment/commentSlice";
import { getStatsAsync } from "../../features/Stats/StatsSlice";

import {
  deleteNotifcationsAsync,
  getNotifcationsAsync,
  updateNotifactionsAsync,
} from "../../features/Notification/NotificationSlice";
import { ImportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MenuItem from "antd/es/menu/MenuItem";
import { getOneUserAsync } from "../../features/Users/userSlice";
const { Step } = Steps;
const { Sider } = Layout;

const menu = (notifications, dispatch) => {
  return (
    <Menu
      className="containernotifaction"
      style={{
        background: "#FFFFFF",
        borderRadius: "12px",
        width: "371px",
        height: "480px",
        overflowY: "scroll",
      }}
    >
      {!notifications.length ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          No Notifications
        </div>
      ) : (
        notifications &&
        notifications.map((e, key) => (
          <Menu.Item key={key}>
            {" "}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!e.isreaded && <img src={bell} alt="bell" />}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  marginTop: "13px",
                  height: "86px",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Avatar>{e.Name.slice(0, 2)}</Avatar>
                  <Avatar style={{ marginLeft: "5px" }}>
                    {e.Name.slice(0, 2)}
                  </Avatar>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <span className="notification"> {` ${e.Name}`}</span>
                  </div>
                  <div onClick={() => dispatch(deleteNotifcationsAsync(e._id))}>
                    {" "}
                    <img src={closenotfi} alt="closenotfi" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src={LineOption} alt="LineOption" />
            </div>
          </Menu.Item>
        ))
      )}
    </Menu>
  );
};
const menu2 = (navigattoStatics,handellogout) => {
 
  return (
    <Menu
      className="containernotifaction"
      style={{
        background: "#FFFFFF",
        borderRadius: "12px",
        width: "371px",
        height: "480px",
        overflowY: "scroll",
      }}
    >
      <Menu.Item>
        <Text classname="NameSettingSidBar" content="mangement profil"  />
      </Menu.Item>
      <Menu.Item>
        <Text classname="NameSettingSidBar" content="Statistics" event={()=>navigattoStatics('statistics')}/>

      </Menu.Item>
      <MenuItem>        <Text classname="NameSettingSidBar" content="Acceuil" event={()=>navigattoStatics('Home')}/>
     </MenuItem>
     <MenuItem>         <Text classname="NameSettingSidBar" content="LogOut" event={handellogout}/>
     </MenuItem>
    </Menu>
  );
};

const Sidbar = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSteps, setCurrentSteps] = useState(2);

  const [Name, setName] = useState("");
  const [Role, setRole] = useState("");
  const [imagespath, setImagespath] = useState("");
  const [description, setDescription] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dataprojects = useSelector((state) => state.projects.dataprojects);
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const type = useSelector((state) => state.notification.type);
  const datastasts = useSelector((state) => state.stasts.datastasts);

  const datacomments = useSelector((state) => state.comments.datacomments);

  const data = useSelector((state) => state.tasks.data);
  const alldata = useSelector((state) => state.tasks.alldata);
  const alldataourdepartemnt = useSelector(
    (state) => state.tasks.alldataourdepartemnt
  );
  const dataOneuser=useSelector((state)=>state.users.dataOneuser)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
    dispatch(getProjectsAsync());
    dispatch(getStatsAsync(new Date(), 0, 3));

    dispatch(getNotifcationsAsync(JSON.parse(localStorage.getItem("id"))));
    dispatch(getCommentsAsync());
  }, [dispatch]);
  const handleImageClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    dispatch(getOneUserAsync({id:JSON.parse(localStorage.getItem('id'))}))

    setRole(JSON.parse(localStorage.getItem("role")));
    if (localStorage.getItem("description") === undefined) {
      setDescription(JSON.parse(localStorage.getItem("description")));
    } else {
      setDescription("");
    }

    
  }, []);

  function handleDropdownVisibleChange(visible) {
    if (visible) {
      dispatch(updateNotifactionsAsync());
    }
  }
const navigattoStatics=(text)=>{
  navigate(`/${text}`)
}
const handellogout=()=>{
  localStorage.clear();
                navigate("/");
                window.location.reload(false)
}

useEffect(()=>{
  dispatch(getOneUserAsync({id:JSON.parse(localStorage.getItem('id'))}))

},[])
  return (
    <Sider
      width={"26%"}
      style={{
        background: colorBgContainer,
        position: "fixed",
        height: "100%",
      }}
    >
      <Row style={{ height: "100px" }}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "2rem",
                flexDirection: "row",
              }}
            >
              <Dropdown
                overlay={menu2(navigattoStatics,handellogout)}
                trigger={["click"]}
                placement="bottomRight"
                onVisibleChange={handleDropdownVisibleChange}
              >
                {dataOneuser.images && dataOneuser.images[0] && dataOneuser.images[0].path ? (
                  <img
                    src={dataOneuser.images[0].path}
                    className="dropdown-image"
                    alt="imagespath"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Avatar>{dataOneuser.Name && dataOneuser.Name.slice(0, 2)}</Avatar>
                )}
              </Dropdown>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "37px",
                  justifyContent: "center",
                }}
              >
                <Text classname="NameSidBar" content={dataOneuser.Name} />
                <Text classname="DepartementSidBar" content={dataOneuser.description} />
              </div>
            </div>

            <div style={{ display: "flex"}}></div>

            <Dropdown
              overlay={menu(notifications, dispatch)}
              trigger={["click"]}
              placement="bottomRight"
              onVisibleChange={handleDropdownVisibleChange}
            >
              <button style={{ width: "50px" }}>
                <img src={notification} alt="Admin" className="block-icon" />
                <img
                  src={Ellipse18}
                  alt="Admin"
                  className={
                    notifications.filter((e) => e.isreaded === false).length > 0
                      ? "icon-tag"
                      : "icon-tag1"
                  }
                />
              </button>
            </Dropdown>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                marginRight: "20px",
              }}
              onClick={() => {
                localStorage.clear();
                navigate("/");
                window.location.reload(false);
              }}
            >
              <ImportOutlined style={{ fontSize: "2em" }} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={Line} alt="line" />
          </div>
        </Col>
      </Row>
      <Row
        style={{
          height: "250px",
          marginTop: "5rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent:"center"
        }}
      >
         <Col span={6} style={{ marginTop: "1rem", marginLeft: "4rem" }}>
          <div
            style={{ display: "flex" }}
            className="TiltleNumberprojectSidBar"
          >
            To Do
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <div>
              <img src={LinetoDo} alt="LineProject" />
            </div>
            <div className="numbersidbar">
            {Role === "Admin" && JSON.parse(localStorage.getItem("description"))==="Chief Information Officer "?
                 alldata && alldata.newdata&& alldata.newdata.length
                  : alldata && alldata.newdatap&& alldata.newdatap.length}
            </div>
          </div>
        </Col>
   
        <Col span={6} style={{ marginTop: "1rem", marginLeft: "4rem" }}>
          <div
            style={{ display: "flex" }}
            className="TiltleNumberprojectSidBar"
          >
            In progress
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <div>
              <img src={LineProgress} alt="LineProject" />
            </div>
            <div className="numbersidbar">
            {Role === "Admin" && JSON.parse(localStorage.getItem("description"))==="Chief Information Officer "?
                 alldata && alldata.newdata1&& alldata.newdata1.length
                  : alldata && alldata.newdata1p&& alldata.newdata1p.length}
            </div>
          </div>
        </Col>
        <Col span={6} style={{ height: "70px", marginLeft: "4rem" , marginTop: "2rem"}}>
          <div
            style={{ display: "flex" }}
            className="TiltleNumberprojectSidBar"
          >
            Completed
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <div>
              <img src={LineCompleted} alt="LineProject" />
            </div>
            <div className="numbersidbar">
              {Role === "Admin" && JSON.parse(localStorage.getItem("description"))==="Chief Information Officer "?
                 alldata && alldata.newdata2&& alldata.newdata2.length
                  : alldata && alldata.newdata2p&& alldata.newdata2p.length}
            </div>
          </div>
        </Col>
        <Col span={6} style={{ height: "70px", marginLeft: "4rem" , marginTop: "2rem"}}>
          <div
            style={{ display: "flex" }}
            className="TiltleNumberprojectSidBar"
          >
            {Role === "Chef du departement" ? " Total Projects" : "Total Tasks"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <div>
              <img src={LineProject} alt="LineProject" />
            </div>
            <div className="numbersidbar">
              {Role === "Chef du departement"
                ? dataprojects && dataprojects.length
                : alldata &&alldata.todo&& alldata.todo.length+ alldata.doing.length+alldata.done.length}
            </div>
          </div>
        </Col>
        <Col span={6} style={{ height: "70px", marginLeft: "4rem", marginTop: "2rem" }}>
          <div
            style={{ display: "flex" ,width:"200px"}}
            className="TiltleNumberprojectSidBar"
          >
            Total Recruiters Posts
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <div>
              <img src={LineJobPost} alt="LineProject" />
            </div>
            <div className="numbersidbar">
              {Role === "Chef du departement"
                ? data &&
                  data.done &&
                  data.done.filter(
                    (e) =>
                      e.ajouterPar &&
                      e.ajouterPar._id ===
                        JSON.parse(localStorage.getItem("id"))
                  ).length
                : datastasts && datastasts.numberjobposts}
            </div>
          </div>
        </Col>
        <Col span={6} style={{ height: "70px", marginLeft: "4rem", marginTop: "2rem" }}>
          <div
            style={{ display: "flex" ,width:"200px"}}
            className="TiltleNumberprojectSidBar"
          >
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "7rem", justifyContent: "center" }}>
        <div
          className="buttonLoadrequest"
          onClick={() => navigate("/statistics")}
        >
          statistics
        </div>
      </Row>
    </Sider>
  );
};
export default Sidbar;
