import React from 'react';
import './Login.css'; 
import christina from "../../assets/christina.png";
import justin from "../../assets/justin.png";
import melo from "../../assets/melo.png";
import shaifer from '../../assets/shaifer.png'
import melo2 from '../../assets/melo2.png'
import Avatar from '../../assets/Avatar2.png'

import connection from '../../assets/connection.png'
import logo from '../../assets/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUsersAsync } from '../../features/Users/userSlice';
import { useState } from 'react';

const Cercular = () => {
const  [users,setUsers]=useState([
  
   
   

])
const [max,setMax]=useState(0)
  const dispatch=useDispatch()
  const datausers = useSelector((state) => state.users.datausers);
  const dataProjectLead = useSelector((state) => state.users.dataProjectLead);
const [startIndex,setStartIndex]=useState(0)
  useEffect(()=>{
    dispatch(getUsersAsync())
    setMax(  Math.floor(Math.random() * 5))
  },[])
 
  useEffect(()=>{
setUsers([...datausers.filter((e)=>e.images && e.images.length!==0),...dataProjectLead, {
  image: logo,
}])

  },[datausers])
  useEffect(()=>{
    setStartIndex(Math.floor(Math.random() * (datausers.filter((e)=>e.images && e.images.length!==0).length - 5)))
      },[datausers])
    

  return (

    <div className="user-circle">
          {users.length>0 ?(
 <div style={{ height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}> 
 <img
          src={logo}
          alt={"logo"}
          style={{
         
            
            width: '30%',
            height: 'auto',
            borderRadius: '50%',
            border: 'none',
          }}
        />
</div>
      ): 
      <div style={{ height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}> 
      <img
               src={logo}
               alt={"logo"}
               style={{
              
       
                width: '30%',
                height: 'auto',
                 borderRadius: '50%',
                 border: 'none',
               }}
             />
     </div>
       } 
      {users && users.slice(startIndex,startIndex+6).map((user, index) => user.images &&user.images.length>0 ?(
        <img
        key={index}
          src={ user.images[0].path}
          alt={user.Name}
          style={{
            position: 'absolute',
            
            width: '30%',
            height: '30%',
            borderRadius: '50%',
            border: 'none',
          }}
        />):(
          
          <img
          key={index}
          src={ ""}
          alt={user.Name}
          style={{
            position: 'absolute',
          
            width: '30%',
            height: 'auto',
            borderRadius: '50%',
            border: 'none',
          }}
        />
        )
      )}
  

  </div>
  )
}

export default Cercular