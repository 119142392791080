import React from "react";

import { useState, useEffect } from "react";
import { Table, Select, DatePicker } from "antd";
import moment from "moment";
import { MdBarChart } from "react-icons/md";
import { Button, Typography } from "antd"; // Import Ant Design components
import { useDispatch, useSelector } from "react-redux";
import { getstatsAsync } from "../../features/Auth/authSlice";
import {
  getStatsAsync,
  getStatsreviewAsync,
  handelChangeStatsAsync,
} from "../../features/Stats/StatsSlice";
import { getDrepartmentsAsync } from "../../features/Departement/departementSlice";
import { getUsersTeamMemberbydepartementAsync } from "../../features/Users/userSlice";

const calculateTimeDifference = (createdAt, updatedAt) => {
  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);
  const timeDiffInMilliseconds = updatedDate - createdDate;

  // Calculate the time difference in hours and days
  const hoursDifference = Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));
  const daysDifference = Math.floor(
    timeDiffInMilliseconds / (1000 * 60 * 60 * 24)
  );

  // Set the time difference based on the duration
  let result = "";
  if (daysDifference > 0) {
    result = `${daysDifference} day${daysDifference > 1 ? "s" : ""} `;
  } else if (hoursDifference > 0) {
    result = `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} `;
  } else {
    result = "Less than an hour";
  }

  return result;
};
const columns = [
  {
    title: "Name Task",
    dataIndex: "label",
  },
  {
    title: "Created By",
    dataIndex: "ajouterPar",
    render: (text) => (
      <div
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "10px",
          lineHeight: "19px",
          textTransform: "uppercase",
          color: "#787486",
        }}
      >
        {text && text.Name}
      </div>
    ),
  },
  {
    title: "Responsabe for Task",
    dataIndex: "responsable",
    render: (text) => (
      <div
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "10px",
          lineHeight: "19px",
          textTransform: "uppercase",
          color: "#787486",
        }}
      >
        {text && text.map((e) => `${e.Name}/`)}
      </div>
    ),
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
  },
  {
    title: "Completion Date",
    dataIndex: "updatedAt",
  },

  {
    title: "time Mangement",
    dataIndex: "createdAt",
    render: (row, text) => (
      <>
        {text.status === "done" ? (
          <div
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "19px",
              textTransform: "uppercase",
              color: "#68B266",

              display: "flex",
              justifyContent: "center",
            }}
          >
      

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "2%",
                background: "rgba(131, 194, 157, 0.20)",
              }}
            >
              completed In
              {calculateTimeDifference(text.createdAt, text.updatedAt)}
            </div>
            {/* {text.map((e)=> `${e.Name}/`)} */}
          </div>
        ) : text.status === "todo" ? (
          <div
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "10px",
            lineHeight: "19px",
            textTransform: "uppercase",
            color: "#FF6C65",

            display: "flex",
            justifyContent: "center",
          }}
          > <div
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "4px",
            padding: "2%",
            background: "rgba(255, 108, 101, 0.16)",
          }}
        >{text.status}</div></div>
        ) : (
          <div
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "10px",
            lineHeight: "19px",
            textTransform: "uppercase",
            color: "#FFA500",

            display: "flex",
            justifyContent: "center",
          }}
          > <div
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "4px",
            padding: "2%",
            background: "rgba(255, 165, 0, 0.15)",
          }}
        >{text.status}</div></div>
        )}
      </>
    ),
  },
];
const data = [];
for (let i = 0; i < 4; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}
const { Text } = Typography;

const TimeMangementtask = ({
  StartDate,
  EndDate,
  setEndDate,
  setStartDate,
  lineChartDataTotalSpentp,
  lineChartOptionsTotalSpentp,
  taskarchived,
}) => {
  const [lineChartOptionsTotalSpent, setlineChartOptionsTotalSpent] = useState(
    {}
  );
  const [lineChartDataTotalSpent, setlineChartDataTotalSpent] = useState({});
  const [filterMonth, setFilterMonth] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [monthOptions, setmonthOptions] = useState([]);

  const datastasts2 = useSelector((state) => state.stasts.datastasts2);
  const datadepartements = useSelector(
    (state) => state.departements.datadepartements
  );
  const datataskstosend = useSelector((state) => state.stasts.datataskstosend);
  const dataTeamMemberbydepartement = useSelector(
    (state) => state.users.dataTeamMemberbydepartement
  );

  const [dateRange, setDateRange] = useState([]);

  // Function to handle filtering based on the selected date range
  const handleDateRangeChange = (dates) => {
    // 'dates' is an array containing the start and end date selected in the date range picker
    setEndDate({ day: dates[1].$D, month: dates[1].$M + 1, year: dates[1].$y });

    setSelectedEndDate(dates[1]);

    setStartDate({
      day: dates[0].$D,
      month: dates[0].$M + 1,

      year: dates[0].$y,
    });

    setSelectedStartDate(dates[0]);
    setDateRange(dates);
  };



  const dispatch = useDispatch();

  useEffect(() => {
    setlineChartDataTotalSpent(lineChartDataTotalSpentp);
    setlineChartOptionsTotalSpent(lineChartOptionsTotalSpentp);
  }, [lineChartDataTotalSpentp, lineChartOptionsTotalSpentp]);
  useEffect(() => {
    dispatch(getDrepartmentsAsync());
    dispatch(
      getUsersTeamMemberbydepartementAsync(datataskstosend.id_departements)
    );
  }, [dispatch, datataskstosend.id_departements]);
  useEffect(() => {
    const updatedData = datadepartements.map((item) => {
      return setmonthOptions(item.Name);
    });
    setmonthOptions(updatedData);
  }, [datadepartements]);

  useEffect(() => {
    dispatch(
      getStatsreviewAsync(
        { StartDate, EndDate },
        datataskstosend.id_departements,
        datataskstosend.TeamMember
      )
    );
  }, [StartDate, datataskstosend]);
  return (
    <div className="containerLinechart">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "15px 0",
        }}
      >
        <Text
          style={{ marginRight: "auto", fontSize: "18px", fontWeight: "700" }}
        >
          Check Management Time Tasks
        </Text>
        {/* Month filter */}
        <select
          showSearch
          placeholder="Critical"
          className={"selectProjectLead"}
          name="id_departements"
          optionFilterProp="children"
          onChange={(e) => dispatch(handelChangeStatsAsync(e))}
        >
          <option disabled selected>
            Choice departement
          </option>
          {datadepartements &&
            datadepartements.map((e) => (
              <option value={e._id}>{e.Name}</option>
            ))}{" "}
        </select>

        {datataskstosend && datataskstosend.id_departements && (
          <>
            <select
              showSearch
              placeholder="Critical"
              className="selectProjectLead"
              name="TeamMember"
              optionFilterProp="children"
              onChange={(e) => dispatch(handelChangeStatsAsync(e))}
            >
              <>
                <option disabled selected>
                  {" "}
                  select a teammember
                </option>
                {dataTeamMemberbydepartement &&
                  dataTeamMemberbydepartement.map((e) => (
                    <option value={e._id}>{e.Name}</option>
                  ))}{" "}
              </>
            </select>
          </>
        )}

        {/* Week filter */}
        <DatePicker.RangePicker
          value={dateRange}
          placeholder={[`Start date`, `End date`]} // Set the placeholder text here
          onChange={handleDateRangeChange}
          style={{ marginLeft: "10px" }}
          // disabledDate={disabledEndDate} // Set the disabledDate function to disable dates before the start date
        />
      </div>

      <div style={{ height: "240px", marginTop: "auto", overflowY: "scroll" }}>
        {/* Your table with filtered data */}
        <Table
          columns={columns}
          dataSource={datastasts2.taskarchived}
          // Remove the fixed 'columns' array, it will be defined in the 'responsive' property below
          responsive={{
            // Define how columns should behave at different breakpoints
            xs: {
              // At extra-small (xs) breakpoint, show only the 'Name Task' column
              columns: [
                {
                  title: "Name Task",
                  dataIndex: "title",
                },
              ],
            },
            sm: {
              // At small (sm) breakpoint, show 'Name Task', 'Created At', and 'Done At' columns
              columns: [
                {
                  title: "Name Task",
                  dataIndex: "title",
                },
                {
                  title: "Created At",
                  dataIndex: "createdAt",
                },
                {
                  title: "Done At",
                  dataIndex: "updatedAt",
                },
              ],
            },
            md: {
              // At medium (md) breakpoint, show all columns
              columns: columns,
            },
            // ... Add more breakpoints and column configurations as needed
          }}
        />
      </div>
    </div>
  );
};

export default TimeMangementtask;
