import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import Swal from "sweetalert2";
import Base_URL from "../../app/api/apiSlice";
import { addNotifcationsAsync } from "../Notification/NotificationSlice";
import { getTasksAsync } from "../Tasks/TaskSlice";
import { getStatsAsync } from "../Stats/StatsSlice";

const API_URL = `${Base_URL}/scarppings`;
export const scrappingsSlide = createSlice({
  name: "scarppings",
  initialState: {
    datascarppings: [],
    datatosend: {},
  
  },

  reducers: {
  
    addScrapping: (state, action) => {
      state.datascarppings = [...state.datascarppings, action.payload];
    },

    getScrapping: (state, action) => {
      state.datascarppings = [...action.payload];

      state.loading = false;
      state.error = false;
    },
    updateJobPost: (state, action) => {
      state.datascarppings = [
        ...state.datascarppings.filter((e) => e.id !== state.datascarppings),
        action.payload,
      ];
    },
    handelChange: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeselect: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeResponsable: (state, action) => {
      state.datatosend = { responsable: action.payload };
    },

  
  },
});
// const auth = JSON.parse(localStorage.getItem('token'));

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});
const api2 = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
    // 'Authorization': 'Bearer ' + auth
  },
});
export const updateScrappingAsync = (data) => async (dispatch) => {
  console.log(data);
  api
    .put(`/updateScrapping`, {
      ...data,
      userconncted: JSON.parse(localStorage.getItem("id")),
    })
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));

      // const array = [...response.data.responsable, response.data.ajouterPar];
      // if (response.data.status === "done") {
      //   dispatch(
      //     addNotifcationsAsync({
      //       Name: `${JSON.parse(localStorage.getItem("Name"))} update  Your Task <br/>
      //       Task Name :
      //       ${
      //         response.data.title
      //       }<br/>
      //        Project Name : ${response.data.id_project.Name} <br /> `,
      //       sender: JSON.parse(localStorage.getItem("id")),
      //       recived: array.filter(
      //         (e) => e !== JSON.parse(localStorage.getItem("id"))
      //       ),
      //       type: "update task",
      //     })
      //   );
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const UpdateFileScrappingAsync = (data) => async (dispatch) => {
  const formData = new FormData();
console.log(data);
  for (var j = 0; j < data.infoList.length; j++) {
    formData.append("files", data.infoList[j].originFileObj);
  }
  formData.append("id", data.id);

  api
    .put(`/updateFileScrapping`, formData)
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    })
    .catch((err) => {
      console.log(err);
    });
};
//get Tous les tasks
export const getScrappingAsync = () => async (dispatch) => {
  api
    .get("/getScrapping")
    .then((response) => {
      dispatch(getScrapping(response.data));
    })
    .catch((err) => console.log(err));
};

//add un seul task

export const addScrappingAsync = (data) => async (dispatch) => {
  const formData = new FormData();
  formData.append("responsable", data.responsable);
  for (var j = 0; j < data.files.length; j++) {
    formData.append("files", data.files[j].originFileObj);
  }

  formData.append("ClientRef", data.ClientRef);
  formData.append("Client", data.Client);
  formData.append("ProjectName", data.ProjectName);

  formData.append("LocationOfwork", data.LocationOfwork);
  formData.append("JobTitle", data.JobTitle);
  formData.append("Industry", data.Industry);
  formData.append("ajouterPar", data.ajouterPar);
  formData.append("yearsOfExprience", data.yearsOfExprience);
  formData.append("Other", data.Other);

  api2
    .post(`/addScrapping`, formData)
    .then((response) => {
        console.log(response.data);
   //   dispatch(addScrapping(response.data));
      // dispatch(
      //   addNotifcationsAsync({
      //     Name: `${JSON.parse(localStorage.getItem("Name")) } add new Job Post `,
      //     sender: JSON.parse(localStorage.getItem("id")),
      //     recived: [data.responsable[1]],
      //     type: "add Job Post",
      //   })
      // );
     // dispatch(getScrappingAsync());
     dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));

    })
    .catch((err) => {
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
      console.log(err);
      if (err.response.data.message === "All fields are required") {
        Swal.fire("All fields are required", "", "info");
      }
    });
};




//remlpir formulaire add tasks
export const handelChangeAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChange({ [e.target.name]: e.target.value }));
  } catch (err) {
    console.log(err);
  }
};

export const handelChangeselectAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeselect(e));
  } catch (err) {
    console.log(err);
 
  }
};
export const deleteScrappingAsync = (data) => async (dispatch) => {
  api
    .delete(`/deleteScrapping`, { data: { id: data } })
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
      //dispatch(getJobPost());
    })
    .catch((err) => {
      console.log(err);
    });
};
export const {

  handelChange,
  handelChangeselect,


  addScrapping,
  getScrapping,

} = scrappingsSlide.actions;
export default scrappingsSlide.reducer;
