import React from 'react';
import { useState } from 'react';
import {  Typography } from 'antd'; // Import Ant Design components
import './Stats.css';

import { DatePicker } from 'antd';


import AreaCharts from './AreaChart';

const { Text } = Typography;

const WeeklRevenue = ({  
  setEndDate,
  setStartDate}) => {

    
    
    const [dateRange, setDateRange] = useState([])

// Function to handle filtering based on the selected date range
const handleDateRangeChange = (dates) => {
  
   setEndDate({
    day: dates[1].$D,
    month: dates[1].$M + 1,
    year: dates[1].$y,
  });

  // Set the start date state
  setStartDate({
    day: dates[0].$D,
    month: dates[0].$M + 1,
    year: dates[0].$y,
  });

  // Set the dateRange state with the selected dates
  setDateRange(dates);
};

// if (!datastasts2 || !datastasts2.categories || !Array.isArray(datastasts2.categories)) {
//   // Render a placeholder or loading state when datastasts2 or categories are not valid
//   return  <div className='cardsatts'>
//   <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '5% 5% 5% 5%' }}>
//     <Text
//       style={{ marginRight: 'auto', fontSize: '18px', fontWeight: '700' }}
//     >
//       Weekly Revenue
//     </Text>

//     <DatePicker.RangePicker
//           value={dateRange}
//           placeholder={[`Start date`, `End date`]} // Set the placeholder text here

//           onChange={handleDateRangeChange}
//           style={{ marginLeft: "10px" }}
//           // disabledDate={disabledEndDate} // Set the disabledDate function to disable dates before the start date

//         />

//   </div>
  
//   <div style={{ height: '240px', marginTop: 'auto' }}>
//   <div>Loading...</div>
  
//   </div>
// </div> 
// }

return (
  <div className='cardsatts'>
  <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '1%'}}>
    <Text
      style={{ marginRight: 'auto', fontSize: '18px', fontWeight: '700' }}
    >
      Number of Task
    </Text>

    <DatePicker.RangePicker
          value={dateRange}
          placeholder={[`Start date`, `End date`]} // Set the placeholder text here
          onChange={handleDateRangeChange}
          style={{ marginLeft: "10px" }}
          // disabledDate={disabledEndDate} // Set the disabledDate function to disable dates before the start date

        />

  </div>
  
  <div style={{ height: '300px', marginTop: 'auto' }}>
 <AreaCharts 
 />
  
  </div>
</div>
 
)
 
}

export default WeeklRevenue