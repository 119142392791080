import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Base_URL from "../../app/api/apiSlice";
const API_URL = `${Base_URL}/departements`;

export const departementsSlide = createSlice({
  name: "departements",
  initialState: {
    datadepartements: [],

    error: false,
    loading: false,
  
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    addDepartement:  (state, action) => {
      state.loading = false;
      state.error = false;
      state.datadepartements = [ ...state.datadepartements,action.payload];
    },
    getDrepartments: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datadepartements = [...action.payload];
    },
    
    setError: (state) => {
      state.error = true;
    },
  },
});
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
//get Tous les users
export const getDrepartmentsAsync = () => async (dispatch) => {
  api
    .get("/getDepartement")
    .then((response) => {
    

      dispatch(getDrepartments(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const addDepartementAsync = (data) => async (dispatch) => {
  api
    .post("/addDepartement",data)
    .then((response) => {
    

      dispatch(addDepartement(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};

export const {  getDrepartments, setError, setLoading,addDepartement } =
departementsSlide.actions;
export default departementsSlide.reducer;
