import React, {useEffect, useState } from "react";
import "./Login.css";
import { Col } from "antd";
import { Checkbox } from 'antd';
import connection from '../../assets/connection.png'
import background4 from '../../assets/background4.png'
import background5 from '../../assets/bg1.png'

import Button from "../../shared/Button";
import Text from "../../shared/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, loginPentaverse } from "../../features/Auth/authSlice";
import MediaQuery from 'react-responsive'
import Cercular from "./Cercular";
import { Container, Row } from "react-bootstrap";
import DropdownLanguage from "../../components/DropDownlanguage/DropdownLanguage";
import { useTranslation } from "react-i18next";
const Login = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const role=useSelector((state)=>state.auth.role)
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Rememberme, setRememberme] = useState(true)
    const { t } = useTranslation();
  
    const onChange = (e) => {
      setRememberme(e.target.checked);
      };
    
  
   
      useEffect(() => {
        if(role==="Admin"){
          navigate('/Home')
          window.location.reload(false)
       }else if (role === "Developer"){
         navigate('/editor')
         window.location.reload(false)

       }
       else if (role==='Chef du departement'){
        navigate('/admin')
        window.location.reload(false)

       }
       else if (role==='SuperAdmin'){
        navigate('/Acceuil')
        window.location.reload(false)

       }
       else if (role==='Recrutement'){
        navigate('/Recrutement')
        window.location.reload(false)

       }
      }, [navigate, role])

    const handleSubmit =  (e) => {

          dispatch(loginAsync({Email,Password},Rememberme))
            setEmail('')
            setPassword('')
            const role = JSON.parse(localStorage.getItem('role')); 
            if(role==="Admin"){
               navigate('/Home')
            }else if (role ==="Chef du departement"){
              navigate('/admin')
            }else if (role==='SuperAdmin'){
              navigate('/Acceuil')
             }
             else if (role==='Recrutement'){
              navigate('/Recrutement')
             }
            else  {
              navigate('/editor')
            }
            
           
            e.preventDefault()
    }

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const loginToken = urlParams.get("loginToken");
      
      loginToken && dispatch(loginPentaverse(loginToken));
    }, []);
  return (
    <>

    <Container  fluid>
      {/* <div style={{position:"absolute",top:"0%",right:"0%",zIndex:"1"}}>
        <DropdownLanguage />
       </div> */}
      <Row>
         <Col xs={12} className="loginBackground1">
         <div className="formBackground" >
                <Cercular />
                
    
        
          </div>
  </Col>
  <Col xs={12} className="loginBackground">
  <div className="formBackground1" >
         
            <Col xs={24} lg={24} xl={24}  style={{
              width:"80%",
              display:"flex",
              justifyContent:"center"

            }}>

              
              <div style={{flexDirection:"column",width:"100%",display:"flex",paddingLeft:"10%",paddingTop:"0px",paddingRight:"10%",justifyContent:"center"}}>
              <div className="titrelogin" >
              { t('welcome')}
                </div>
                <div className="titre2login" >{ t('login')}</div>
                <div className="labelInputlogin">Email</div>
                <input className="inputlogin" placeholder="Email" onChange={handleUserInput} value={Email}/>
    
                <div className="labelInputlogin"> { t('password')}</div>
                <input className="inputlogin" placeholder="Password" onChange={handlePwdInput} value={Password} type='password'/>
                <div style={{display:"flex",justifyContent:"flex-end" ,width:"100%"}}>
                <Text classname="textforgetpassword"  content="Forgot password ?" classname2="textforgetpassword"  event={()=>navigate('/password-forget')}/>
    
                </div>
                <div style={{display:"flex",justifyContent:"center" ,width:"100%"}} >
                <Button classname="buttonsiginin" text={ t('siginin')} style={{marginBottom:"40px"}} event={handleSubmit}/>
                {/* <Button classname="buttonsigininwithgoogle" text="Continue with Google" img={Google} /> */}</div>
              </div>
                
            </Col>
        
          </div>
  </Col>
      </Row>
 
    </Container>
    
        </>
    )
    
};

export default Login;
