import React from 'react'

const Text = (props) => {
    const {content,classname,content2,classname2,event}=props
    if(content2){
        return (
            <div className={classname}>
                <div>{content}</div> <div className={classname2} onClick={event}>{content2}</div></div>
          )
    }else{
        return (
    <div className={classname} onClick={event}>{content}</div>
  )
    }
  
}

export default Text