import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsAsync } from "../../features/Comment/commentSlice";
import { getaddJobPostAsync } from "../../features/JobPost/jobpostSlice";

import "./Trello.css";
import Trellocard from "./Trellocard";

export default function TrelloBoard({
  data,
  data2,
  title,
  onChange,
  status,
  type,
  detailsselect,
  setDetailsselect,
  role,
}) {
  // Sort data (Might need useMemo)
  const datacomments = useSelector((state) => state.comments.datacomments);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommentsAsync());

  }, [dispatch]);
  

  const onDragEnterHandler = (e) => {
    e.preventDefault();
  };
  const onDragOverHandler = (e) => {
    e.preventDefault();
    if (e.target.className === "boardContentArea") {
      setTimeout(() => {
        e.target.className = "boardContentArea hovered";
      }, 0);
    }
  };
  const onDragLeaveHandler = (e) => {
    e.preventDefault();
    if (e.target.className === "boardContentArea hovered") {
      setTimeout(() => {
        e.target.className = "boardContentArea";
      }, 0);
    }
  };
  const onDropHandler = (e) => {
 
    let cardInfo = JSON.parse(e.dataTransfer.getData("cardInfo"));
    let targetCardId = e.target.id;

    onChange(cardInfo, status, targetCardId,type);
    if (e.target.className === "boardContentArea hovered") {
      setTimeout(() => {
        e.target.className = "boardContentArea";
      }, 0);
    }
  };
useEffect(()=>{
  console.log(data,role);
},[data])
  // returns JSX - Render cards
  const renderCards = () => {
   if (role === "Developer" ||role === "community manager" || role==="BI Developper"||role === "Chef du departement") {
   
      return (
        data &&
        data
          .map((item) => (
            <Trellocard
              select={type}
              detailsselect={detailsselect}
              setDetailsselect={setDetailsselect}
              key={`status-${item.id}`}
              id={item.id}
              color={item.id_project && item.id_project.Color}
                            id_Task={item && item._id}
              datacomments={datacomments.filter(
                (e) => e.task && e.task._id && e.task._id === item._id
              )}
              datafiles={item.files}
              detaisdata={item}
              data2={[item.ajouterPar,item.responsable[0] && item.responsable[0]._id]}
              projectName={item.id_project ? item.id_project.Name:item.title}
              status={status}
              role={role}
              title={item.title}
              label={item.label ? item.label:item.description}
              typetask={item.type}
            />
          ))
      );
    } else if (role === "Admin" || role==="SuperAdmin") {
      return (
        data &&
        data.map((item) => (
          <Trellocard
            select={type}
            detailsselect={detailsselect}
            setDetailsselect={setDetailsselect}
            key={`status-${item.id}`}
            color={item.id_project && item.id_project.Color}
            id={item.type==="task" ?item && item.id:item && item.id}
            id_Task={item && item._id}
            datacomments={datacomments.filter(
              (e) => e.task && e.task._id &&( e.task._id === item._id)
            )}
            role={role}
            datafiles={item.files}
            detaisdata={item}
            data2={[item.ajouterPar]}
            projectName={item.id_project ? item.id_project.Name:item.title}
            status={status}
            title={item.title}
            label={item.label ? item.label:item.description}
            typetask={item.type}
                      />
        ))
      );
     }
    //else  if (role === "Developer" ||role === "community manager" ) {
   
    //   return (
    //     data &&
    //     data
    //       .map((item) => (
    //         <Trellocard
    //           select={type}
    //           detailsselect={detailsselect}
    //           setDetailsselect={setDetailsselect}
    //           key={`status-${item.id}`}
    //           id={item.id}
    //           color={item.id_project && item.id_project.Color}
    //                         id_Task={item && item._id}
    //           datacomments={datacomments.filter(
    //             (e) => e.task && e.task._id && e.task._id === item._id
    //           )}
    //           datafiles={item.files}
    //           detaisdata={item}
    //           data2={[item.ajouterPar,item.responsable[0] && item.responsable[0]._id]}
    //           projectName={item.id_project ? item.id_project.Name:item.title}
    //           status={status}
    //           role={role}
    //           title={item.title}
    //           label={item.label ? item.label:item.description}
    //           typetask={item.type}
    //         />
    //       ))
    //   );
    // }     
  };
  return (
    <div className="board-body">
      <div className="list"  >
        <div className="list-title" >{title} <div
        style={{
          width: "18.66px",
height:" 18.66px",


background: "#E0E0E0",
borderRadius: "9.33211px"}}> </div></div>
        <div
          className="boardContentArea"
          onDragEnter={onDragEnterHandler}
          onDragOver={onDragOverHandler}
          onDragLeave={onDragLeaveHandler}
          onDrop={onDropHandler}
        >
          {renderCards()}
        </div>
        <a className="btn-list"> Scroll to view another card</a>
      </div>
    </div>
  );
}
