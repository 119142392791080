import React, { useEffect, useReducer, useState } from "react";

import "./Trello.css";
import { Rate } from "antd";

import TrelloBoard from "./TrelloBoard";
import Ellipse11 from "../../assets/Ellipse11.png";
import Ellipse12 from "../../assets/Ellipse 12.png";
import Ellipse13 from "../../assets/Ellipse 13.png";
import HorLineToDo from "../../assets/HorLineToDo.png";
import HorLineInProgress from "../../assets/HorLineInProgress.png";
import HorLineDone from "../../assets/HorLineDone.png";
import taskSquare from "../../assets/task-square.png";
import taskSquareActive from "../../assets/task-square-active.png";
import ProjectsIcon from "../../assets/ProjectsIcon.png";
import ProjectsIconActive from "../../assets/ProjectIconActive.png";
import TeamIcon from "../../assets/TeamIcon.png";
import TeamIconActive from "../../assets/TeamIconActive.png";
import PlusIcon from "../../assets/PlusIcon.png";
import { Progress } from "antd";
import Projects from "../Projects/Projects";
import Text from "../../shared/Text";
import Modal1 from "../Modal/Modal";
import Team from "../Team/Team";
import {
  addTaskAsync,
  changeDataAsync,
  getCombineTasksAsync,
  getTasksArchivedAsync,
  getTasksAsync,
  updateTaskAsync,
} from "../../features/Tasks/TaskSlice";
import { useDispatch, useSelector } from "react-redux";
import { getaddJobPostAsync, updateJobPostAsync } from "../../features/JobPost/jobpostSlice";
import TasksProject from "../TasksProject/TasksProject";
import Modal2 from "../Modal/Modal2";
import Modalcenter from "../Modal/Modalcenter";
import Modalscapping from "../Modal/Modalscapping";
import { updateScrappingAsync } from "../../features/Scrapping/scrappingSlice";


export default function Trello({ role }) {
  const [lists, setLists] = useState([
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse11} alt="Ellipse11" />
            </div>
            <div className="titleboard">To Do</div>
            {/* <div
          style={{
            width: "18.66px",
  height:" 18.66px",
  
  marginLeft:"10px",
  background: "#E0E0E0",
  borderRadius: "9.33211px"}}> 4 </div> */}
          </div>
          <div>
            <img src={HorLineToDo} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "todo",
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse13} alt="Ellipse13" />
            </div>
            <div className="titleboard">In Progress</div>
          </div>
          <div>
            <img src={HorLineInProgress} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "doing",
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse12} alt="Ellipse12" />
            </div>
            <div className="titleboard">Completed tasks under 30 days</div>
          </div>
          <div>
            <img src={HorLineDone} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "done",
    },
  ]);
  const [precentage, setprecentage] = useState(25);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsselect, setDetailsselect] = useState("");
  const [detailsselect1, setDetailsselect1] = useState("dd");
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const [modalIsChoice, setmodalIsChoice] = useState(false);
  const [type, setType] = useState("");

  const [Archived, setArchived] = useState(false);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [select, setSelect] = useState({
    Tasks: true,
    Projects: false,
    Team: false,
    ProjectsTasks:false
  });
  const data = useSelector((state) => state.tasks.data);
  const alldata = useSelector((state) => state.tasks.alldata);
  const alldataourdepartemnt = useSelector((state) => state.tasks.alldataourdepartemnt);

  const datajobposts = useSelector((state) => state.jobposts.datajobposts);
  const datacombintasks = useSelector((state) => state.jobposts.datacombintasks);

  const dispatch = useDispatch();
  function openModal() {
    setModalIsOpen(true);
    if(JSON.parse(localStorage.getItem('role'))==="Admin"){
      setDetailsselect1("Form5")
    }else{
      setDetailsselect1("Form")

    }
  }
  function openModalChoice() {
    setmodalIsChoice(true);
    setType('choice')
  }
  function handleCancel() {
    setmodalIsChoice(false);
  }
  function handleCancelForm() {

    setModalIsOpen(false);
  }
  function openModal1() {
    setModalIsOpen2(true);
  }
  function openModal2() {
    setModalIsOpen3(true);
  }
  function handleCancel1() {
    setModalIsOpen2(false);
  }
  function handleCancel2() {
    setModalIsOpen3(false);
  }
  // Handle Lists
  // Handle Lists ends here

  // Handle Data

  const cardChangeHandler = (cardInfo, newStatus, targetCardId) => {
    const { id, status: oldStatus, _id } = cardInfo;

    let dropCard = alldataourdepartemnt[oldStatus].find((el) => el.id === id);
    let targetCard =
      targetCardId !== ""
        ? alldataourdepartemnt[newStatus].find((el) => el.id === targetCardId)
        : null;

    let newListOrderValueMax = alldataourdepartemnt[newStatus]
      .map((item) => item.order)
      .reduce((maxValue, a) => Math.max(maxValue, a), 0);

    // CASE 1: If same list, work only this if block then return;
    if (oldStatus === newStatus) {
      let temp = alldataourdepartemnt[oldStatus]
        .map((item) => {
          if (item.id === dropCard.id)
            return {
              ...dropCard,
              order: targetCard
                ? targetCard.order - 1
                : newListOrderValueMax + 1,
            };
          return item;
        })
        .sort((a, b) => a.order - b.order)
        .map((item, i) => {
          return { ...item, order: i + 1 };
        });
      dispatch(changeDataAsync({ ...alldataourdepartemnt, [oldStatus]: temp }));

      return;
    }
    // CASE 1 ENDS HERE

    // CASE 2: Drag across multiple lists
    let tempGaveList = alldataourdepartemnt[oldStatus]
      .filter((item) => item.id !== id)
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });
    let tempRecievedList = [
      ...alldataourdepartemnt[newStatus],
      {
        ...dropCard,
        order: targetCard ? targetCard.order - 1 : newListOrderValueMax + 1,
      },
    ]
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });
    // At last, set state

    console.log(tempRecievedList);
    if(id){
      dispatch(
        changeDataAsync({
          ...data,
          [oldStatus]: tempGaveList,
          [newStatus]: tempRecievedList,
        })
      );
      if(role==="Recrutement"||role==="BI Developper"){
        dispatch(
          updateScrappingAsync({
            status: newStatus,
            id: tempRecievedList[tempRecievedList.length - 1]._id,
          })

        ); 
      }else{
        dispatch(
          updateTaskAsync({
            status: newStatus,
            id: tempRecievedList[tempRecievedList.length - 1]._id,
          })
        );  
         dispatch(
        updateJobPostAsync({
          status: newStatus,
          id: tempRecievedList[tempRecievedList.length - 1]._id,
        })
       
      ); 
      }
      
      
    
    }else{
      dispatch(
        changeDataAsync({
          ...data,
          [oldStatus]: tempGaveList,
          [newStatus]: tempRecievedList,
        })
      );

      dispatch(
        updateJobPostAsync({
          status: newStatus,
          id: tempRecievedList[tempRecievedList.length - 1]._id,
        })
       
      ); 
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
      dispatch(getaddJobPostAsync())
    }
    
    //   // CASE 2 ENDS HERE
  };
  // Handle Data ends here
  useEffect(() => {
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    dispatch(getaddJobPostAsync())
  }, [dispatch, select.Tasks]);


  
  return (
    <div className="trello_page">
      {/* Sidebar */}
      <div className="app-sidebar"></div>
      <div className="app-content-area">
        {/* Main Header */}
        <div className="app-header">
          <div
            className={
              role === "Admin"
                ? "logo"
               
                : "minilogo"
            }
          >
              {role === "Chef du departement" ? (
                <div
                  className={select.Tasks ? "miniselect1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: true, Projects: false, Team: false ,ProjectsTasks:false})
                  }
                >
                  <div>
                    <img
                      src={select.Tasks ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Your Requests"
                      classname={select.Tasks ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              ) :role === "community manager" || role==="Developer"? (
                <div
                  className={select.Tasks ? "miniselect1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: true, Projects: false, Team: false ,ProjectsTasks:false})
                  }
                >
                  <div>
                    <img
                      src={select.Tasks ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Tasks"
                      classname={select.Tasks ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={select.Tasks ? "select1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: true, Projects: false, Team: false,ProjectsTasks:false })
                  }
                >
                  <div>
                    <img
                      src={select.Tasks ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Tasks"
                      classname={select.Tasks ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              )}

              {(role === "Admin"  ) && (
                <div
                  className={select.Projects ? "select1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: false, Projects: true, Team: false ,ProjectsTasks:false})
                  }
                >
                  <div>
                    <img
                      src={select.Projects ? ProjectsIconActive : ProjectsIcon}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Projects"
                      classname={
                        select.Projects ? "textTasksActive" : "textTasks"
                      }
                    />
                  </div>
                </div>
              )}
              {role === "Admin" && (
                <div
                  className={select.Team ? "select1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: false, Projects: false, Team: true,ProjectsTasks:false })
                  }
                >
                  <div>
                    <img
                      src={select.Team ? TeamIconActive : TeamIcon}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Team"
                      classname={select.Team ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              )}
                    {/* {role === "Admin" && (
                <div
                  className={select.ProjectsTasks ? "select1" : "select"}
                  onClick={() =>
                    setSelect({ Tasks: false, Projects: false, Team: false,ProjectsTasks:true })
                  }
                >
                  <div>
                    <img
                      src={select.ProjectsTasks ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div style={{ marginLeft: "17px" }}>
                    <Text
                      content="Inter  departements "
                      classname={select.ProjectsTasks ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              )} */}
          </div>
          <div className="center"></div>
          <div className="right">
            {role === "Admin" &&
              (select.Tasks ? (
                <>
                { (JSON.parse(localStorage.getItem('role'))==="Admin"&& (JSON.parse(localStorage.getItem('description'))==="Chief Recruitement Officer"||JSON.parse(localStorage.getItem('description'))==="Chief Information Officer ")  )&& (
                  <><div className="btn search" onClick={openModal2} style={{marginRight:"2%"}}>
                 <div style={{ marginTop: "3px" }}>
                   <img src={PlusIcon} alt="PlusIcon" />
                 </div>
                 <Text content="Scraping Request" classname="AddTask" />
               </div>
                <div className="btn search" onClick={openModal1} style={{marginRight:"2%"}}>
                <div style={{ marginTop: "3px" }}>
                  <img src={PlusIcon} alt="PlusIcon" />
                </div>
                <Text content="Request Job Posts" classname="AddTask" />
              </div></>
                )}
                 
                <div className="btn search" onClick={openModalChoice}>
                  <div style={{ marginTop: "3px" }}>
                    <img src={PlusIcon} alt="PlusIcon" />
                  </div>
                  <Text content="Add Ticket" classname="AddTask" />
                </div>
                
               </>
              ) : select.Projects ? (
                <div className="btn search" onClick={openModal}>
                  <div style={{ marginTop: "3px" }}>
                    <img src={PlusIcon} alt="PlusIcon" />
                  </div>
                  <Text content="Add Project" classname="AddTask" />
                </div>
              ):select.ProjectsTasks ? (
                <div className="btn search" onClick={openModal}>
                  <div style={{ marginTop: "3px" }}>
                    <img src={PlusIcon} alt="PlusIcon" />
                  </div>
                  <Text content="Add task Project" classname="AddTask" />
                </div>
              ) : (
                <div className="btn search" onClick={openModal}>
                  <div style={{ marginTop: "3px" }}>
                    <img src={PlusIcon} alt="PlusIcon" />
                  </div>
                  <Text content="Add Team Member" classname="AddTask" />
                </div>
              ))}
            {(role === "Chef du departement" || role==="Developer" ||role === "community manager" )&& select.Tasks && (
              <div className="btn search" onClick={openModal}>
                <div style={{ marginTop: "3px" }}>
                  <img src={PlusIcon} alt="PlusIcon" />
                </div>
                <Text content="Add Ticket" classname="AddTask" />
              </div>
            )}
          </div>
        </div>
        {role !== "Chef du departement" && (
          <>
            <div className="app-header">
              <div className="left">
                <div className="logo1">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Text content="Task Meter" classname="soustitreboard" />
                    <Text
                      content={`${
                        alldataourdepartemnt &&
                        alldataourdepartemnt.done &&
                        alldataourdepartemnt.done.filter(
                          (e) =>
                          (e.responsable && e.responsable[0] && (e.responsable[0]._id ===
                              JSON.parse(localStorage.getItem("id")) ))||
                            (e.responsable && e.responsable[1] &&
                              e.responsable[1]._id ===
                                JSON.parse(localStorage.getItem("id")))
                        ).length > 0
                          ? Math.trunc(
                              (alldataourdepartemnt &&
                                alldataourdepartemnt.done &&
                                alldataourdepartemnt.done.filter(
                                (e) =>
                                (e.responsable && e.responsable[0] && (e.responsable[0]._id ===
                                    JSON.parse(localStorage.getItem("id")))) ||
                                  (e.responsable && e.responsable[1] &&
                                    e.responsable[1]._id ===
                                      JSON.parse(localStorage.getItem("id")))
                              ).length /
                                (alldataourdepartemnt &&
                        alldataourdepartemnt.done &&
                        alldataourdepartemnt.done.filter(
                                  (e) =>
                                  (e.responsable && e.responsable[0] && (e.responsable[0]._id ===
                                      JSON.parse(localStorage.getItem("id")))) ||
                                    (e.responsable[1] &&
                                      e.responsable[1]._id ===
                                        JSON.parse(localStorage.getItem("id")))
                                ).length +
                                alldataourdepartemnt.doing.filter(
                                    (e) =>
                                      (e.responsable && e.responsable[0] &&
                                        e.responsable[0]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          )) ||
                                      (e.responsable && e.responsable[1] &&
                                        e.responsable[1]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          ))
                                  ).length +
                                  alldataourdepartemnt.todo.filter(
                                    (e) =>
                                      (e.responsable && e.responsable[0] &&
                                        e.responsable[0]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          )) ||
                                      (e.responsable && e.responsable[1] &&
                                        e.responsable[1]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          ))
                                  ).length)) *
                                100
                            )
                          : 0
                      }`}
                      classname="soustitreboardActive"
                    />
                    <Text content={`/100`} classname="soustitreboard" />
                  </div>
                </div>
              </div>
            </div>
            <div className="app-header">
              <div className="left">
                <div className="logo1">
                  <div
                    style={{
                      width: 270,
                    }}
                  >
                    <Progress
                      percent={
                        alldataourdepartemnt.done &&
                        alldataourdepartemnt.done.filter(
                          (e) =>
                            (e.responsable && e.responsable[0] &&
                              e.responsable[0]._id ===
                                JSON.parse(localStorage.getItem("id"))) ||
                            ( e.responsable && e.responsable[1] &&
                              e.responsable[1]._id ===
                                JSON.parse(localStorage.getItem("id")))
                        ).length > 0
                          ? Math.trunc(
                              (alldataourdepartemnt.done.filter(
                                (e) =>
                                  (e.responsable[0] &&
                                    e.responsable[0]._id ===
                                      JSON.parse(localStorage.getItem("id"))) ||
                                  (e.responsable[1] &&
                                    e.responsable[1]._id ===
                                      JSON.parse(localStorage.getItem("id")))
                              ).length /
                                (alldataourdepartemnt.done.filter(
                                  (e) =>
                                    (e.responsable[0] &&
                                      e.responsable[0]._id ===
                                        JSON.parse(
                                          localStorage.getItem("id")
                                        )) ||
                                    (e.responsable[1] &&
                                      e.responsable[1]._id ===
                                        JSON.parse(localStorage.getItem("id")))
                                ).length +
                                alldataourdepartemnt.doing.filter(
                                    (e) =>
                                      (e.responsable[0] &&
                                        e.responsable[0]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          )) ||
                                      (e.responsable[1] &&
                                        e.responsable[1]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          ))
                                  ).length +
                                  alldataourdepartemnt.todo.filter(
                                    (e) =>
                                      (e.responsable[0] &&
                                        e.responsable[0]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          )) ||
                                      (e.responsable[1] &&
                                        e.responsable[1]._id ===
                                          JSON.parse(
                                            localStorage.getItem("id")
                                          ))
                                  ).length)) *
                                100
                            )
                          : 0
                      }
                      size="small"
                      status={precentage === 100 && "active"}
                    />
                  </div>
                  { !Archived ? (
           <div style={{display:"flex",marginLeft:"5%"}}
           onClick={()=>{
            setArchived(true)
            dispatch(getTasksArchivedAsync())}}
           >                    <Text content="All completed Tasks" classname="Archivetextboard" />
</div>
                  ):(
                    <div style={{display:"flex",marginLeft:"5%"}}
                    onClick={()=>{
                      setArchived(false)
                      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
                    }}
     
      >                    <Text content="Current Task"  classname="Archivetextboard" />
</div>
               
                  )}
            
                </div>
              </div>
            </div>
          </>
        )}
        {/* App Board */}
        <main
          className="app-board"
          style={{ marginTop: role === "Chef du departement" && "32px" }}
        >
       
          {/* Board */}
          <section className="board-body">
            {select.Tasks ? (
              <div className="wrap-lists">
                {lists.map((l,key) => (
                  <TrelloBoard
                  
                    type={select}
                    detailsselect={detailsselect}
                    setDetailsselect={setDetailsselect}
                    data={
                      role === "Chef du departement"
                        ? data[l.status]
                        : alldataourdepartemnt && alldataourdepartemnt[l.status]
                    }
                    data2={data}
                    role={role}
                    key={l.status}
                    title={l.title}
                    status={l.status}
                    onChange={
                      role !== "Chef du departement" ? cardChangeHandler : null
                    }
                  />
                ))}
              </div>
            ) : select.Projects ? (
              <div className="ListProject">
                <Projects
                  type={select}
                  detailsselect={detailsselect}
                  setDetailsselect={setDetailsselect}
                />
              </div>
            ) : select.ProjectsTasks ? (
                <TasksProject 
                  type={select}
                  detailsselect={detailsselect}
                  setDetailsselect={setDetailsselect}
                />
            ):(
              <Team />
            )}
          </section>
        </main>
      </div>
      {modalIsOpen2 && (
        <Modal2
          update={false}
          // details={dataOne}
          role={role}
          title={
            select.Tasks
              ? role === "Chef du departement" && role === "Recrutement"
                ? "Add Request"
                : "Add Ticket"
              : select.Projects
              ? "Add Project"
              : "Add Team Member"
          }
          type={select}
          handleCancel={handleCancel1}
        />
      )}
        {modalIsOpen3 && (
        <Modalscapping
          update={false}
          // details={dataOne}
          role={role}
          title={
            select.Tasks
              ? role === "Chef du departement" && role === "Recrutement"
                ? "Add Request"
                : "Add Ticket"
              : select.Projects
              ? "Add Project"
              : "Add Team Member"
          }
          type={select}
          handleCancel={handleCancel2}
        />
      )}
      <Modal1
        title={
          select.Tasks
            ? role === "Chef du departement"
              ? "Add Request"
              : "Add Ticket"
            : select.Projects
            ? "Add Project": select.ProjectsTasks
            ?"Add Task Project "
            : "Add Team Member"
        }
        detailsselect={detailsselect}
        detailsselect1={detailsselect1}

        type={select}
        forceUpdate={forceUpdate}
        modalIsOpen={modalIsOpen}
        handleCancel={handleCancelForm}
      />
         <Modalcenter
        type={type}
        show={modalIsChoice}
        title={
          select.Tasks
            ? role === "Chef du departement"
              ? "Add Request"
              : "Add Ticket"
            : select.Projects
            ? "Add Project": select.ProjectsTasks
            ?"Add Task Project "
            : "Add Team Member"
        }
        detailsselect={detailsselect}
        detailsselect1={detailsselect1}
        type2={select}
        forceUpdate={forceUpdate}
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        handleCancel={handleCancel}
        handleClose={handleCancelForm}
        // files={files}
    
        // idTask={idTask}
        // data={responsable}
        // // detaisdata={detaisdata}
        // handleClose={handleClose}
        // label={label}
      />
    </div>
  );
}