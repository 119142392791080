import React, { useEffect } from "react";

import { Avatar, Select, Row, Col } from "antd";

import { MdBarChart } from "react-icons/md";
import { Form, Input, Button } from "antd";
import Dropzone from "react-dropzone";
import { CheckCircleOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { getStatsAsync } from "../../features/Stats/StatsSlice";
import { getstatsAsync } from "../../features/Auth/authSlice";
import {
  getOneUserAsync,
  updateImageUserAsync,
  updatePasswordAsync,
} from "../../features/Users/userSlice";
import Text from "../../shared/Text";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import Modalcenter from "../../components/Modal/Modalcenter";
const Profil = () => {
  const dataOneuser = useSelector((state) => state.users.dataOneuser);
  const [datatosend, setDatatosend] = useState({});
  const [show, setShow] = useState(false);
  const [oldpassword, setOldpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const dispatch = useDispatch();
  const openModal = () => {
    setShow(true);
  };
  const handleCancel = () => {
    setShow(false);
  };
  useEffect(() => {
    dispatch(getOneUserAsync({ id: JSON.parse(localStorage.getItem("id")) }));
  }, [dispatch]);

  const onFinish = () => {
    dispatch(updateImageUserAsync(datatosend, dataOneuser._id, "profil"));
  };
  const onFinishpassword = () => {
    dispatch(
      updatePasswordAsync(
        { newpassword: newpassword, oldpassword: oldpassword },
        dataOneuser._id
      )
    );
  };
  const handleDrop = (acceptedFiles, id) => {
    const datato = { images: acceptedFiles, id: id };
    setDatatosend(datato);

    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // Handle image preview if needed
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setOldpassword("");
  }, []);

  return (
    <div className="profile-container">
      <Row gutter={20}>
        <Col
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <h1>Update Your Profil</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Dropzone onDrop={(e) => handleDrop(e, dataOneuser._id)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <Avatar
                    size={100}
                    icon={<UserOutlined />}
                    src={dataOneuser?.images?.[0]?.path}
                  />
                  <EditOutlined className="edit-icon" />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "4rem",
              flexDirection: "column",
            }}
          >
            <div>Full Name </div>{" "}
            <div>
              <input
                placeholder={`${dataOneuser.Name}`}
                onChange={(e) => {
                  setDatatosend({ ...datatosend, name: e.target.value });
                }}
                style={{ width: "90%", height: "5vh", borderRadius: "4px" }}
              />
            </div>
            <div style={{ marginTop: "2rem" }}>Email</div>{" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "20%",
                justifyContent: "space-between",
              }}
            >
              {`${dataOneuser.Email}`}
              <CheckCircleOutlined style={{ color: "green" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2rem",
                marginRight: "7.5rem",
              }}
            >
              <Button
                type="primary"
                onClick={onFinish}
                disabled={
                  datatosend.name === undefined &&
                  datatosend.name !== dataOneuser.Name &&
                  datatosend.images === undefined
                }
              >
                update Information
              </Button>
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>Old Password </div>{" "}
          <div>
            <input
              type="password"
              placeholder="old password"
              onChange={(e) => setOldpassword(e.target.value)}
              style={{ width: "90%", height: "5vh", borderRadius: "4px" }}
            />
          </div>
          <div style={{ marginTop: "2rem" }}>New Password </div>{" "}
          <div>
            <input
              type="password"
              placeholder="new password"
              onChange={(e) => setNewpassword(e.target.value)}
              style={{ width: "90%", height: "5vh", borderRadius: "4px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
              marginRight: "7.5rem",
            }}
          >
            <Button
              type="primary"
              disabled={oldpassword.length < 2 && newpassword.length < 2}
              onClick={onFinishpassword}
            >
              update password
            </Button>
          </div>
          {/* <Form layout="horizontal" onFinish={onFinish} initialValues={{ username: 'JohnDoe', email: 'johndoe@example.com' }} style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>

          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"80%",alignItems:"center"}}>
            <Form.Item  name="username" rules={[{ required: true, message: 'Please enter your username' }]} style={{width:"50%"}} >

              <input placeholder={`${dataOneuser.Name}`} onChange={(e) => setDatatosend({ ...datatosend, name: e.target.value })}  value={`${dataOneuser.Name}`}  style={{width:"90%",height:"5vh",borderRadius:"4px"}}/>
              </Form.Item>
              <div style={{display:"flex",flexDirection:"column",width:"50%",marginTop:"5vh"}}>
            <Form.Item  name="password"   >
            <input type='text' disabled placeholder={`${dataOneuser.Email}`} onChange={(e) => setDatatosend({ ...datatosend, password: e.target.value })} style={{width:"90%",height:"5vh",borderRadius:"4px"}}/>
            </Form.Item>

            <Form.Item  style={{display:"flex",justifyContent:"flex-end",marginRight:"10%"}}>
              <Button type="primary" htmlType="submit" disabled={ (datatosend.name===undefined && datatosend.name!==dataOneuser.Name) &&datatosend.images===undefined}>
              update Information
              </Button>
            </Form.Item>
            </div></div>
          </Form> */}
        </Col>
      </Row>
      {/* <div className="profile-container">
   
   <Row gutter={20} >
     < Col  style={{width:"100%"}}>
       <h1 >Update Your Password</h1>
     
       <Form layout="horizontal" onFinish={onFinishpassword} initialValues={{ username: 'JohnDoe', email: 'johndoe@example.com' }} style={{alignItems:"center",display:"flex",flexDirection:"column",width:"100%"}}>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"80%",alignItems:"center"}}>
        <Form.Item  name="username" rules={[{ required: true, message: 'Please enter your old password' }]} style={{width:"50%"}}  >

          <input type='password' placeholder="old password" onChange={(e) => setOldpassword(e.target.value)}  style={{width:"90%",height:"5vh",borderRadius:"4px"}}/>
          </Form.Item>
        <Form.Item  name="password" rules={[{ required: true, message: 'Please enter your new  password if you want to change' }]}  style={{width:"50%"}}  >
        <input type='password' placeholder="new password" onChange={(e) => setNewpassword(e.target.value)}  style={{width:"90%",height:"5vh",borderRadius:"4px"}}/>
        </Form.Item>

        <Form.Item  style={{display:"flex",justifyContent:"center"}}>
          <Button type="primary" htmlType="submit" disabled={ oldpassword.length<2 &&newpassword.length<2}>
            update password
          </Button>
        </Form.Item>
       </div>
      </Form>
     </Col>
     
   </Row>

 
 </div> */}
    </div>
  );
};

export default Profil;
