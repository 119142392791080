import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

import {
  Form,
  Input,
  Button,
  DatePicker,
  Upload,
  Switch,
  Select,
  Radio,
} from "antd";
import "./Form.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectsAsync,
  getProjectsbydepartementAsync,
} from "../features/Projects/projectSlice";
import {
  addTaskAsync,
  getTasksArchivedAsync,
  getTasksAsync,
  handelChange,
  handelChangeAsync,
  handelChangeDateAsync,
  handelChangeProjectAsync,
  handelChangeResponsableAsync,
  handelChangeTeammeberAsync,
  updateDateTaskAsync,
} from "../features/Tasks/TaskSlice";
import {
  getDeparementbyUserloginAsync,
  getUsersLeadAsync,
  getUsersTeamMemberAsync,
  getUsersTeamMemberbydepartementAsync,
} from "../features/Users/userSlice";

import { getDrepartmentsAsync } from "../features/Departement/departementSlice";
const Option = Select.Option;

const Form1 = ({ disable, handleCancel, detaisdata }) => {
  const [formLayout] = useState("vertical");
  const [infoList, setInfoList] = useState([]);
  const [exitProject, setExitProject] = useState(true);
  const dispatch = useDispatch();
  const dataprojects = useSelector((state) => state.projects.dataprojects);

  const datataskstosend = useSelector((state) => state.tasks.datataskstosend);
  const dataTeamMemberbydepartement = useSelector(
    (state) => state.users.dataTeamMemberbydepartement
  );
  const dataProjectLead = useSelector((state) => state.users.dataProjectLead);
  const IdDepartement = useSelector((state) => state.users.IdDepartement);
  const dataprojectsbydepartement = useSelector(
    (state) => state.projects.dataprojectsbydepartement
  );

  const datadepartements = useSelector(
    (state) => state.departements.datadepartements
  );
  const alldata = useSelector((state) => state.tasks.alldata);
  const alldataourdepartemnt = useSelector(
    (state) => state.tasks.alldataourdepartemnt
  );
  const alldatadepartementexterne = useSelector(
    (state) => state.tasks.alldatadepartementexterne
  );
  const ourdepartementtaskP = useSelector(
    (state) => state.tasks.ourdepartementtaskP
  );

  const [activiatenotifcation, setActiviatenotifcation] = useState(true);

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 14 },
        }
      : null;
  const buttonItemLayout =
    formLayout === "vertical"
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : null;
  const onChange = (checked) => {
    setExitProject(checked.target.value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  useEffect(() => {
    dispatch(getDrepartmentsAsync());
    //dispatch(getTasksAsync());

    dispatch(
      getDeparementbyUserloginAsync(JSON.parse(localStorage.getItem("id")))
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(getUsersLeadAsync(datataskstosend.id_departements));
    if (exitProject) {
      if (disable) {
        dispatch(
          getUsersTeamMemberbydepartementAsync(
            JSON.parse(localStorage.getItem("departement"))
          )
        );
      } else {
        dispatch(
          getUsersTeamMemberbydepartementAsync(datataskstosend.id_departements)
        );
      }
    } else {
      dispatch(
        getUsersTeamMemberbydepartementAsync(datataskstosend.id_departements)
      );
    }

    dispatch(getProjectsbydepartementAsync(datataskstosend.id_departements));
  }, [dispatch, datataskstosend.id_departements, detaisdata, disable]);


  return (
    <div style={{ marginTop: "18px" }}>
      <Form layout={formLayout}>
        <Form.Item
          label="Choice The Concerned Departement"
          {...formItemLayout}
          // style={{width:"50%"}}
        >
          <select
            disabled={disable}
            showSearch
            placeholder="Critical"
            className={
              disable
                ? "selectProjectLeadgranddisable"
                : "selectProjectLeadgrand"
            }
            name="id_departements"
            optionFilterProp="children"
            onChange={(e) => dispatch(handelChangeProjectAsync(e))}
            onSearch={onSearch}
          >
            {!disable ? (
              <>
                <option disabled selected>
                  {" "}
                  select your departement
                </option>
                {datadepartements &&
                  datadepartements.map((e) => (
                    <option value={e._id}>{e.Name}</option>
                  ))}{" "}
              </>
            ) : (
              <option>
                {detaisdata &&
                  detaisdata.id_project &&
                  detaisdata.id_project.id_departement &&
                  detaisdata.id_project.id_departement.Name}
              </option>
            )}
          </select>
        </Form.Item>
        {!disable && (
          <Radio.Group onChange={onChange} value={exitProject}>
            <Radio value={true} checked>
              Task in the exist project
            </Radio>
            <Radio value={false}>Task without exist project </Radio>
          </Radio.Group>
        )}
        {exitProject ? (
          <>
            {((datataskstosend && datataskstosend.id_departements) ||
              disable) && (
              <>
                <Form.Item
                  label="Name of project"
                  {...formItemLayout}
                  // style={{width:"50%"}}
                >
                  <select
                    disabled={disable}
                    showSearch
                    placeholder="Critical"
                    className={
                      disable
                        ? "selectProjectLeadgranddisable"
                        : "selectProjectLeadgrand"
                    }
                    name="id_project"
                    optionFilterProp="children"
                    onChange={(e) => dispatch(handelChangeProjectAsync(e))}
                    onSearch={onSearch}
                  >
                    {!disable ? (
                      <>
                        <option disabled selected>
                          {" "}
                          select your project
                        </option>
                        {dataprojectsbydepartement &&
                          dataprojectsbydepartement.map((e) => (
                            <option
                              value={`${e._id}:${
                                e.ProjectLeadName && e.ProjectLeadName._id
                              }:${
                                e.TeamMember &&
                                e.TeamMember.map((member) => member._id).join(
                                  ":"
                                )
                              }`}
                            >
                              {e.Name}
                            </option>
                          ))}{" "}
                      </>
                    ) : (
                      <option>
                        {detaisdata && detaisdata.id_project.Name}
                      </option>
                    )}
                    <option value="other">New project</option>
                  </select>
                </Form.Item>
                {datataskstosend.id_project === "other" && (
                  <>
                    <Form.Item label="New Project" {...formItemLayout}>
                      <Input
                        className="inputfiledaddticket"
                        disabled={disable}
                        placeholder={
                          disable ? detaisdata && detaisdata.title : ""
                        }
                        name="newproject"
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                    </Form.Item>
                    <Form.Item label="Description Project" {...formItemLayout}>
                      <Input
                        className="inputfiledaddticket"
                        disabled={disable}
                        placeholder={
                          disable ? detaisdata && detaisdata.title : ""
                        }
                        name="DescriptionProject"
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                    </Form.Item>
                  </>
                )}
                {((datataskstosend && datataskstosend.id_project) ||
                  disable) && (
                  <>
                    <Form.Item label="Description Task" {...formItemLayout}>
                      <textarea
                        className="inputfiledaddticket"
                        disabled={disable}
                        placeholder={
                          disable ? detaisdata && detaisdata.title : ""
                        }
                        name="title"
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                    </Form.Item>
                    {((datataskstosend && datataskstosend.title) ||
                      (disable &&
                        (JSON.parse(localStorage.getItem("role")) ===
                          "SuperAdmin" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "Admin" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "Developer" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "community manager"))) && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "529px",
                          }}
                        >
                          {((JSON.parse(localStorage.getItem("role")) ===
                            "Admin" &&
                            disable) ||
                            (JSON.parse(localStorage.getItem("role")) ===
                              "SuperAdmin" &&
                              disable)) && (
                            <>
                              <Form.Item
                                label="Choose project lead"
                                {...formItemLayout}
                                style={{ width: "50%" }}
                              >
                                <select
                                  showSearch
                                  placeholder="Select a person"
                                  className={
                                    disable
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={disable}
                                  name="responsabel"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    dispatch(handelChangeAsync(e))
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  <option selected disabled>
                                    {disable
                                      ? detaisdata &&
                                        detaisdata.responsable.map(
                                          (e) =>
                                            e.id_role.Name === "Admin" && e.Name
                                        )
                                      : "----Lead name----"}
                                  </option>
                                  {dataProjectLead &&
                                    dataProjectLead.map((e) => (
                                      <>
                                        <option value={`${e._id}`}>
                                          {e.Name}
                                        </option>
                                      </>
                                    ))}
                                </select>
                              </Form.Item>
                              <Form.Item
                                label="Team members"
                                {...formItemLayout}
                                style={{ width: "50%", marginLeft: "50px" }}
                              >
                                <Select
                                  style={{
                                    width: 500,
                                    height: "fit-content !important",
                                  }}
                                  className={
                                    detaisdata && detaisdata.status === "done"
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={
                                    detaisdata && detaisdata.status === "done"
                                  }
                                  onChange={(value, option) =>
                                  {  
                                    console.log(value,
                                      detaisdata.responsable);
                                    dispatch(
                                      handelChangeResponsableAsync(
                                        value,
                                        detaisdata.responsable
                                      )
                                    )}
                                  }
                                  placeholder={
                                    detaisdata &&
                                    detaisdata.responsable.map(
                                      (e1) =>
                                        e1.id_role.Name !== "Admin" &&
                                        `${e1.Name}/`
                                    )
                                  }
                                  defaultValue={
                                    detaisdata &&
                                    detaisdata.responsable
                                      .filter(
                                        (e1) => e1.id_role.Name !== "Admin"
                                      )
                                      .map((e1) => e1._id)
                                  }
                                  mode="multiple" // Set mode to "multiple" for multiple selection
                                  tokenSeparators={[" ", ","]}
                                >
                                  <Select.Option key="placeholder" disabled>
                                    {disable
                                      ? detaisdata &&
                                        detaisdata.responsable.map(
                                          (e) =>
                                            e.id_role.Name !== "Admin" && e.Name
                                        )
                                      : "----Lead name----"}
                                  </Select.Option>
                                  {dataTeamMemberbydepartement &&
                                    dataTeamMemberbydepartement.map((e) => (
                                      <Select.Option key={e._id} value={e._id}>
                                        <span>{e.Name}</span>
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          )}
                        </div>
                        {JSON.parse(localStorage.getItem("id")) !==
                          "Chef du departement" &&
                          disable && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "529px",
                              }}
                            >
                              <Form.Item
                                label="Due Date/Time"
                                {...formItemLayout}
                              >
                                <DatePicker
                                  disabled={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                  }
                                  placeholder={
                                    detaisdata.DateCreation !== ""
                                      ? detaisdata.DateCreation
                                      : "choisi Date"
                                  }
                                  onChange={(date, dateString) =>
                                    dispatch(
                                      handelChangeDateAsync(date, dateString, 1)
                                    )
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                label="Task Priority"
                                {...formItemLayout}
                                style={{ width: "50%", marginLeft: "50px" }}
                              >
                                <select
                                  showSearch
                                  className={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                  }
                                  placeholder="Critical"
                                  optionFilterProp="children"
                                  name="Priority"
                                  value={detaisdata.Priority}
                                  onChange={(e) =>
                                    dispatch(handelChangeAsync(e))
                                  }
                                  onSearch={onSearch}
                                >
                                  <option disabled selected>
                                    {detaisdata.Priority !== "undefined"
                                      ? detaisdata.Priority
                                      : "choose Priority of task"}
                                  </option>

                                  <option value="High">High</option>
                                  <option value="Low">Low</option>
                                  <option value="Normal">Normal</option>
                                  <option value="Critical">Critical</option>
                                </select>
                              </Form.Item>
                            </div>
                          )}

                        {!disable && (
                          <Form.Item
                            label="Attached File"
                            {...formItemLayout}
                            style={{ width: "50%" }}
                          >
                            <Upload
                              name="file"
                              fileList={infoList}
                              onChange={({ fileList }) => {
                                setInfoList(fileList);
                              }}
                              multiple
                            >
                              <Button
                                className="attaachfile"
                                icon={<UploadOutlined />}
                                style={{
                                  borderColor: infoList.length > 0 && "green",
                                }}
                              >
                                {" "}
                                Attach File
                              </Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <>
              {!disable && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Form.Item
                    label="Activate Notifications"
                    {...formItemLayout}
                    style={{ width: "50%" }}
                  ></Form.Item>
                  <div style={{ marginRight: "30px" }}>
                    <Switch
                      defaultChecked={activiatenotifcation}
                      onChange={onChange}
                    />
                  </div>
                </div>
              )}
              {(JSON.parse(localStorage.getItem("role")) ===
                "community manager" ||
                JSON.parse(localStorage.getItem("role")) === "Admin" ||
                JSON.parse(localStorage.getItem("role")) === "SuperAdmin" ||
                JSON.parse(localStorage.getItem("role")) ===
                  "Chef du departement" ||
                JSON.parse(localStorage.getItem("role")) === "Recrutement" ||
                (JSON.parse(localStorage.getItem("role")) === "Developer" &&
                  !disable)) && (
                <Form.Item
                  {...buttonItemLayout}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="buutonsaveaddticket"
                    onClick={() => {
                      if (!disable) {
                        Swal.fire({
                          title: "Do you want to add this Ticket?",
                          showDenyButton: true,
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.isConfirmed) {
                            Swal.fire(
                              "task add avec sucess!",
                              "",
                              "task add avec sucess"
                            );
                            
                            dispatch(
                              addTaskAsync({
                                ...datataskstosend,
                                responsable: datataskstosend.responsable.filter(
                                  (e) => e !== "undefiend"
                                ),
                                ajouterPar: `${JSON.parse(
                                  localStorage.getItem("id")
                                )}`,
                                activiatenotifcation: activiatenotifcation,

                                infoList: infoList,

                                departementExterne: true,
                              })
                            );

                            handleCancel();
                          } else if (result.isDenied) {
                            Swal.fire("Changes are not saved", "", "info");
                          }
                        });
                      } else {
                        Swal.fire({
                          title: "Do you want to Edit this Ticket?",
                          showDenyButton: true,
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.isConfirmed) {
                            dispatch(
                              updateDateTaskAsync({
                                DateCreation:
                                  datataskstosend.DateCreation &&
                                  datataskstosend.DateCreation.length > 0
                                    ? datataskstosend.DateCreation
                                    : detaisdata.DateCreation,
                                id: detaisdata._id,
                                Priority:
                                  datataskstosend.Priority &&
                                  datataskstosend.Priority.length > 0
                                    ? datataskstosend.Priority
                                    : detaisdata.Priority,
                                responsable:
                                  datataskstosend.responsable &&
                                  datataskstosend.responsable.length > 0
                                    ? datataskstosend.responsable
                                    : detaisdata.responsable,
                                departementExterne: true,
                              })
                            );
                            Swal.fire(
                              "task edit avec sucess!",
                              "",
                              "task edit avec sucess"
                            );
                          } else if (result.isDenied) {
                            Swal.fire("Changes are not saved", "", "info");
                          }
                        });

                        handleCancel();
                      }
                    }}
                  >
                    {disable ? "Edit Ticket" : "Save"}
                  </div>
                  <div
                    className="buutoncanceladdticket"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </div>
                </Form.Item>
              )}
            </>
          </>
        ) : (
          <>
            {((datataskstosend && datataskstosend.id_departements) ||
              disable) && (
              <>
                <Form.Item
                  label="Choice TeamMember"
                  {...formItemLayout}
                   style={{width:"100%"}}
                >
                  <Select
  disabled={disable}
  showSearch
  
  placeholder="TeamMember"
  className={
    disable
      ? "selectProjectLeadgranddisable"
      : "selectProjectLeadgrand"
  }
  name="TeamMember"
  optionFilterProp="children"
  onChange={(value) => {
    dispatch(handelChangeTeammeberAsync(value))
  }}
  onSearch={onSearch}
  mode="multiple" // Set mode to "multiple" for multiple selection
>
  {!disable ? (
    <>
      <Select.Option key="placeholder" disabled>
        select a team member
      </Select.Option>
      {dataTeamMemberbydepartement &&
        dataTeamMemberbydepartement.map((e) => (
          <Select.Option key={e._id} value={e._id}>
            {e.Name}
          </Select.Option>
        ))}
    </>
  ) : (
    <Select.Option key="selectedValue">
      {detaisdata && detaisdata.id_project.Name}
    </Select.Option>
  )}
</Select>
                </Form.Item>

                {((datataskstosend && datataskstosend.TeamMember) ||
                  disable) && (
                  <>
                    <Form.Item label="Description Task" {...formItemLayout}>
                      <textarea
                        className="inputfiledaddticket"
                        disabled={disable}
                        placeholder={
                          disable ? detaisdata && detaisdata.title : ""
                        }
                        name="title"
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                    </Form.Item>
                    {((datataskstosend && datataskstosend.title) ||
                      (disable &&
                        (JSON.parse(localStorage.getItem("role")) ===
                          "SuperAdmin" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "Admin" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "Developer" ||
                          JSON.parse(localStorage.getItem("role")) ===
                            "community manager"))) && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "529px",
                          }}
                        >
                          {((JSON.parse(localStorage.getItem("role")) ===
                            "Admin" &&
                            disable) ||
                            (JSON.parse(localStorage.getItem("role")) ===
                              "SuperAdmin" &&
                              disable)) && (
                            <>
                              <Form.Item
                                label="Choose project lead"
                                {...formItemLayout}
                                style={{ width: "50%" }}
                              >
                                <select
                                  showSearch
                                  placeholder="Select a person"
                                  className={
                                    disable
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={disable}
                                  name="responsabel"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    dispatch(handelChangeAsync(e))
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  <option selected disabled>
                                    {disable
                                      ? detaisdata &&
                                        detaisdata.responsable.map(
                                          (e) =>
                                            e.id_role.Name === "Admin" && e.Name
                                        )
                                      : "----Lead name----"}
                                  </option>
                                  {dataProjectLead &&
                                    dataProjectLead.map((e) => (
                                      <>
                                        <option value={`${e._id}`}>
                                          {e.Name}
                                        </option>
                                      </>
                                    ))}
                                </select>
                              </Form.Item>
                              <Form.Item
                                label="Team members"
                                {...formItemLayout}
                                style={{ width: "50%", marginLeft: "50px" }}
                              >
                                <select
                                  style={{
                                    width: 500,
                                    height: "fit-content !important",
                                  }}
                                  className={
                                    detaisdata && detaisdata.status === "done"
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={
                                    detaisdata && detaisdata.status === "done"
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      handelChangeResponsableAsync(
                                        e,
                                        detaisdata.responsable
                                      )
                                    )
                                  }
                                  placeholder={
                                    detaisdata &&
                                    detaisdata.responsable.map(
                                      (e1) =>
                                        e1.id_role.Name !== "Admin" &&
                                        `${e1.Name}/`
                                    )
                                  }
                                  tokenSeparators={[" ", ","]}
                                  defaultValue={
                                    detaisdata &&
                                    detaisdata.responsable
                                      .filter(
                                        (e1) => e1.id_role.Name !== "Admin"
                                      )
                                      .map((e1) => e1._id)
                                  }
                                >
                                  <option selected disabled>
                                    {" "}
                                    {disable
                                      ? detaisdata &&
                                        detaisdata.responsable.map(
                                          (e) =>
                                            e.id_role.Name !== "Admin" && e.Name
                                        )
                                      : "----Lead name----"}
                                  </option>
                                  {dataTeamMemberbydepartement &&
                                    dataTeamMemberbydepartement.map((e) => (
                                      <>
                                        {" "}
                                        <option value={`${e._id}`}>
                                          <span> {e.Name}</span>
                                        </option>
                                      </>
                                    ))}
                                </select>
                              </Form.Item>
                            </>
                          )}
                        </div>
                        {JSON.parse(localStorage.getItem("id")) !==
                          "Chef du departement" &&
                          disable && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "529px",
                              }}
                            >
                              <Form.Item
                                label="Due Date/Time"
                                {...formItemLayout}
                              >
                                <DatePicker
                                  disabled={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                  }
                                  placeholder={
                                    detaisdata.DateCreation !== ""
                                      ? detaisdata.DateCreation
                                      : "choisi Date"
                                  }
                                  onChange={(date, dateString) =>
                                    dispatch(
                                      handelChangeDateAsync(date, dateString, 1)
                                    )
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                label="Task Priority"
                                {...formItemLayout}
                                style={{ width: "50%", marginLeft: "50px" }}
                              >
                                <select
                                  showSearch
                                  className={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                      ? "selectProjectLeaddisable"
                                      : "selectProjectLead"
                                  }
                                  disabled={
                                    (detaisdata &&
                                      JSON.parse(
                                        localStorage.getItem("role")
                                      ) !== "Admin") ||
                                    detaisdata.status === "done"
                                  }
                                  placeholder="Critical"
                                  optionFilterProp="children"
                                  name="Priority"
                                  value={detaisdata.Priority}
                                  onChange={(e) =>
                                    dispatch(handelChangeAsync(e))
                                  }
                                  onSearch={onSearch}
                                >
                                  <option disabled selected>
                                    {detaisdata.Priority !== "undefined"
                                      ? detaisdata.Priority
                                      : "choose Priority of task"}
                                  </option>

                                  <option value="High">High</option>
                                  <option value="Low">Low</option>
                                  <option value="Normal">Normal</option>
                                  <option value="Critical">Critical</option>
                                </select>
                              </Form.Item>
                            </div>
                          )}

                        {!disable && (
                          <Form.Item
                            label="Attached File"
                            {...formItemLayout}
                            style={{ width: "50%" }}
                          >
                            <Upload
                              name="file"
                              fileList={infoList}
                              onChange={({ fileList }) => {
                                setInfoList(fileList);
                              }}
                              multiple
                            >
                              <Button
                                className="attaachfile"
                                icon={<UploadOutlined />}
                                style={{
                                  borderColor: infoList.length > 0 && "green",
                                }}
                              >
                                {" "}
                                Attach File
                              </Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <>
              {!disable && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Form.Item
                    label="Activate Notifications"
                    {...formItemLayout}
                    style={{ width: "50%" }}
                  ></Form.Item>
                  <div style={{ marginRight: "30px" }}>
                    <Switch
                      defaultChecked={activiatenotifcation}
                      onChange={onChange}
                    />
                  </div>
                </div>
              )}
              {(JSON.parse(localStorage.getItem("role")) ===
                "community manager" ||
                JSON.parse(localStorage.getItem("role")) === "Admin" ||
                JSON.parse(localStorage.getItem("role")) === "SuperAdmin" ||
                JSON.parse(localStorage.getItem("role")) ===
                  "Chef du departement" ||
                JSON.parse(localStorage.getItem("role")) === "Recrutement" ||
                (JSON.parse(localStorage.getItem("role")) === "Developer" &&
                  !disable)) && (
                <Form.Item
                  {...buttonItemLayout}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="buutonsaveaddticket"
                    onClick={() => {
                      if (!disable) {
                        Swal.fire({
                          title: "Do you want to add this Ticket?",
                          showDenyButton: true,
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.isConfirmed) {
                            Swal.fire(
                              "task add avec sucess!",
                              "",
                              "task add avec sucess"
                            );
                            dispatch(
                              addTaskAsync({
                                ...datataskstosend,
                                responsable: datataskstosend.responsable.filter(
                                  (e) => e !== "undefiend"
                                ),
                                TeamMember: datataskstosend.TeamMember,
                                ajouterPar: `${JSON.parse(
                                  localStorage.getItem("id")
                                )}`,
                                activiatenotifcation: activiatenotifcation,

                                infoList: infoList,

                                departementExterne: true,
                              })
                            );

                            handleCancel();
                          } else if (result.isDenied) {
                            Swal.fire("Changes are not saved", "", "info");
                          }
                        });
                      } else {
                        Swal.fire({
                          title: "Do you want to Edit this Ticket?",
                          showDenyButton: true,
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.isConfirmed) {
                            dispatch(
                              updateDateTaskAsync({
                                DateCreation:
                                  datataskstosend.DateCreation &&
                                  datataskstosend.DateCreation.length > 0
                                    ? datataskstosend.DateCreation
                                    : detaisdata.DateCreation,
                                id: detaisdata._id,
                                Priority:
                                  datataskstosend.Priority &&
                                  datataskstosend.Priority.length > 0
                                    ? datataskstosend.Priority
                                    : detaisdata.Priority,
                                responsable:
                                  datataskstosend.responsable &&
                                  datataskstosend.responsable.length > 0
                                    ? datataskstosend.responsable
                                    : detaisdata.responsable,
                                departementExterne: true,
                              })
                            );
                            Swal.fire(
                              "task edit avec sucess!",
                              "",
                              "task edit avec sucess"
                            );
                          } else if (result.isDenied) {
                            Swal.fire("Changes are not saved", "", "info");
                          }
                        });

                        handleCancel();
                      }
                    }}
                  >
                    {disable ? "Edit Ticket" : "Save"}
                  </div>
                  <div
                    className="buutoncanceladdticket"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </div>
                </Form.Item>
              )}
            </>
          </>
        )}
      </Form>
    </div>
  );
};

export default Form1;
