import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Base_URL from "../../app/api/apiSlice";
import Swal from "sweetalert2";

const API_URL = `${Base_URL}/users`;

export const usersSlide = createSlice({
  name: "users",
  initialState: {
    datausers: [],
    dataProjectLead: [],
    dataTeamMember: [],
    dataTeamMemberbydepartement: [],
    error: false,
    loading: false,
    datatosend: {},
    data: {},
    IdDepartement: null,
    dataOnebyrole: {},
    dataOneuser: {},
    dataOnebyroleAdmin: {},
    dataOnebydepartemnt: [],
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getOneUsers: (state, action) => {
      state.dataOneuser = action.payload;
    },
    addUser: (state, action) => {
      state.datausers.push(action.payload);
    },
    getUsers: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datausers = [...action.payload];
    },
    getUsersbyrole: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataOnebyrole = action.payload;
    },
    getUsersbyroleAdmin: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataOnebyroleAdmin = action.payload;
    },
    getUsersbydepartemnt: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataOnebydepartemnt = action.payload;
    },
    getUsersLead: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataProjectLead = [...action.payload];
    },
    getDeparementbyUserlogin: (state, action) => {
      state.loading = false;
      state.error = false;
      state.IdDepartement = action.payload;
    },
    updateImageUser: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datausers = state.datausers.filter((e) => e._id !== action);
    },
    deleteUser: (state, action) => {
      state.datausers = state.datausers.filter(
        (e) => e._id !== action.payload._id
      );
    },
    sendPasswordlink: (state, action) => {
      state.data = action.payload;
    },
    getUsersDevelopper: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataTeamMember = [...action.payload];
    },
    getUsersTeamMemberbydepartement: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataTeamMemberbydepartement = [...action.payload];
    },
    handelChange: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    setError: (state) => {
      state.error = true;
    },
  },
});
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
  },
});
const api2 = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
    // 'Authorization': 'Bearer ' + auth
  },
});
export const getUsersByDepartementAsync = () => async (dispatch) => {
  api
    .get(
      `/findAllUserByDepartement/${JSON.parse(
        localStorage.getItem("departement")
      )}`
    )
    .then((response) => {
      dispatch(getUsersbydepartemnt(response.data));
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};
export const getUsersByroleAdminAsync = (role) => async (dispatch) => {
  api
    .get(`/getUserbyroleAdmin/${role}`)
    .then((response) => {
      console.log(response);

      dispatch(getUsersbyroleAdmin(response.data));
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};
//get Tous les users
export const getUsersByroleAsync = (role) => async (dispatch) => {
  console.log(role);
  api
    .get(`/getUserbyrole/${role}`)
    .then((response) => {
      console.log(response);
      dispatch(getUsersbyrole(response.data));
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};
export const getUsersAsync = () => async (dispatch) => {
  api
    .get("/getUser")
    .then((response) => {
      const resultLead = response.data.filter(
        (word) => word.id_role && word.id_role.Name === "Admin"
      );
      const resultUsersSaufAdmin = response.data.filter(
        (word) => word.id_role && word.id_role.Name !== "SuperAdmin"
      );
      const resultDeveploper = response.data.filter(
        (word) =>
          word.id_role &&
          (word.id_role.Name === "Developer" ||
            word.id_role.Name === "community manager")
      );
      dispatch(getUsersLead(resultLead));
      dispatch(getUsersDevelopper(resultDeveploper));
      dispatch(getUsers(resultUsersSaufAdmin));
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};

export const getOneUserAsync = (id) => async (dispatch) => {
  const data = {
    param1: id.id,
  };

  api
    .get("/getOneUser", { params: data })
    .then((response) => {
      dispatch(getOneUsers(response.data));
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};
export const getUsersLeadAsync = (data) => async (dispatch) => {
  api
    .get("/getUser")
    .then((response) => {
      const resultLead = response.data.filter(
        (word) => word.id_role && word.id_role.Name === "Admin"
      );
      const Projectbydepartement = resultLead.filter(
        (word) => word.id_departement && word.id_departement._id === data
      );

      dispatch(getUsersLead(Projectbydepartement));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getUsersTeamMemberAsync = () => async (dispatch) => {
  api
    .get("/getUser")
    .then((response) => {
      const resultDeveploper = response.data.filter(
        (word) =>
          (word.id_role && word.id_role.Name === "Developer") ||
          (word.id_role && word.id_role.Name === "community manager")
      );
      dispatch(getUsersDevelopper(resultDeveploper));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getUsersTeamMemberbydepartementAsync =
  (data1) => async (dispatch) => {
    const data = {
      param1: data1,
    };
    api
      .get("/getUser", { params: data })
      .then((response) => {
        const TeamMemberbydepartement = response.data.filter(
          (word) =>
            word.id_departement && word.id_departement._id === data.param1
        );
        dispatch(getUsersTeamMemberbydepartement(TeamMemberbydepartement));
      })
      .catch((err) => {
        console.log(err);
      });
  };
export const getDeparementbyUserloginAsync = (id) => async (dispatch) => {
  const data = {
    param1: id,
  };
  api
    .get("/getDepartementbyUserlogin", { params: data })
    .then((response) => {
      dispatch(getDeparementbyUserlogin(response.data._id));
    })
    .catch((err) => {
      console.log(err);
    });
};
//add un seul user

export const addUserAsync = (data) => async (dispatch) => {
  const formData = new FormData();
  for (var j = 0; j < data.images.length; j++) {
    formData.append("images", data.images[j].originFileObj);
  }

  formData.append("Name", data.Name);

  formData.append("id_role", data.id_role);
  formData.append("id_departement", data.id_departement);
  formData.append("Email", data.Email);
  formData.append("Password", data.Password);

  api2
    .post(`/addUser`, formData)
    .then((response) => {
      dispatch(addUser(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const sendEmailAllUserAsync = () => async (dispatch) => {
  api2
    .post(`/sendEmailPentabellUser`)
    .then((response) => {
      Swal.fire({
        title: "Do you want to delete this task?",
        showDenyButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Email send to Users !", "", "Email send to Users !");
        } else if (result.isDenied) {
          Swal.fire(" Email no sended !", "", "info");
        }
      });
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const sendaddPasswordlinkAsync = (data) => async (dispatch) => {
  const formData = new FormData();

  formData.append("csvfile", data);

  api
    .post(`/addPentabellUser`, formData)
    .then((response) => {
      dispatch(sendPasswordlink(response.data));
      Swal.fire("all user in ficher added succesuufully");
    })
    .catch((err) => {
      if (err.response.data === "email duplicate") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "User with given email does deja exist!",
        });
      } else {
        console.log(err);
      }
    });
};
export const updateImageUserAsync = (data, id, type) => async (dispatch) => {
  const formData = new FormData();
  formData.append("id", id);
  if (data.images) {
    formData.append("images", data.images[0]);
  }
  if (data.name) {
    formData.append("name", data.name);
  }
  if (data.password) {
    formData.append("password", data.password);
  }

  api2
    .put(`/updateUser`, formData)
    .then((response) => {
      if (type === "profil") {
        dispatch(getOneUserAsync({ id: response.data._id }));
        alert("Profile updated successfully!");
      } else {
        getUsersAsync();
        alert("Image updated successfully!");
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const updatePasswordAsync = (data, id) => async (dispatch) => {
  api
    .put(`/updatePassworduser`, {
      id: id,
      newpassword: data.newpassword,
      oldpassword: data.oldpassword,
    })
    .then((response) => {
      dispatch(getOneUserAsync({ id: response.data._id }));
      alert("Password updated successfully!");
    })
    .catch((err) => {
      console.log(err?.response?.data);
      if (err?.response?.data) {
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
      }
    });
};
export const updateNewPassewordAsync = (id, data) => async (dispatch) => {
  api
    .post(`/sendNewPassword`, { token: id, password: data })
    .then((response) => {
      dispatch(sendPasswordlink(response.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateActiveUserAsync = (id, data) => async (dispatch) => {
  if (data) {
    /* Read more about isConfirmed, isDenied below */

    Swal.fire({
      title: "Do you want to activate this user?",
      showDenyButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire(
          "user activated avec sucess!",
          "",
          "user activated avec sucess!"
        );
        api
          .put(`/UpdateActiveUser`, { id: id, active: data })
          .then((response) => {
            dispatch(getUsersAsync());
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  } else {
    /* Read more about isConfirmed, isDenied below */

    Swal.fire({
      title: "Do you want to inactivate this user?",
      showDenyButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire(
          "user inactivate avec sucess!",
          "",
          "user inactivate avec sucess!"
        );
        api
          .put(`/UpdateActiveUser`, { id: id, active: data })
          .then((response) => {
            dispatch(getUsersAsync());
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
};
//remlpir formulaire add projet
export const handelChangeAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChange({ [e.target.name]: e.target.value }));
  } catch (err) {
    dispatch(setError());
  }
};

export const deleteUserAsync = (data) => async (dispatch) => {
  Swal.fire({
    title: "Do you want to delete this task?",
    showDenyButton: true,
    confirmButtonText: "Yes",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire("task delete avec sucess!", "", "task delete avec sucess");
      axios
        .delete(`${Base_URL}/Users/deleteUser`, { data: { id: data } })
        .then((response) => {
          dispatch(deleteUser(response.data));
        })
        .catch((err) => {
          if (err.response.data.message) {
            alert(err.response.data.message);
          } else {
            dispatch(setError());
          }
        });
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};
export const {
  getOneUsers,
  addUser,
  getUsersbydepartemnt,
  getUsers,
  handelChange,
  getUsersTeamMemberbydepartement,
  updateImageUser,
  getDeparementbyUserlogin,
  setError,
  getUsersbyrole,
  getUsersbyroleAdmin,
  setLoading,
  getUsersDevelopper,
  getUsersLead,
  deleteUser,
  sendPasswordlink,
} = usersSlide.actions;
export default usersSlide.reducer;
