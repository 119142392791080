import React ,{useEffect, useState} from 'react';
import { UploadOutlined } from '@ant-design/icons';

import { Form, Button   , Upload} from 'antd';
import './Form.css'
import { useDispatch, useSelector } from 'react-redux';
import { addRoleAsync, getRolesAsync } from '../features/Role/roleSlice';
import { addDepartementAsync, getDrepartmentsAsync } from '../features/Departement/departementSlice';
import { addUserAsync, handelChangeAsync } from '../features/Users/userSlice';
import PlusIcon from '../assets/PlusIcon.png'

const Form3 = ({disable, handleCancel}) => {
    const [formLayout,setFormLayout]=useState('vertical')
    const [newRole,setNewrole]=useState('')
    const [newDepartement,setNewDepartement]=useState('')

    const [infoList, setInfoList] = useState([]);
    const [addRole, setAddRole] = useState(false);
    const [addDepartement, setAddDepartement] = useState(false);

    const dispatch=useDispatch()

    const dataroles = useSelector((state) => state.roles.dataroles);
    const datatosend = useSelector((state) => state.users.datatosend);

    const datadepartements = useSelector((state) => state.departements.datadepartements);


      const formItemLayout =
      formLayout === 'vertical'
        ? {
            labelCol: { span: 24 },
            wrapperCol: { span: 14 },
          }
        : null;
    const buttonItemLayout =
      formLayout === 'vertical'
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : null;
  
        const onSearch = (value) => {
          console.log('search:', value);
        };
        useEffect(()=>{
          dispatch(getRolesAsync())
          dispatch(getDrepartmentsAsync())
        },[dispatch])
  return (
    <div style={{marginTop:"18px"}}>
    <Form layout={formLayout}>
     
      <Form.Item label="Full Name"  {...formItemLayout}>
        <input  className='inputfiledaddticket' disabled={disable} name="Name"  onChange={(e)=>dispatch(handelChangeAsync(e))}/>
      </Form.Item>
   
   
      <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}>
      <Form.Item label="Role" {...formItemLayout} style={{width:"50%"}}>
   
      <select   disabled={disable}
    showSearch
    placeholder="Exp : Dev ..."
    name='id_role'
    className='selectProjectLead'
    optionFilterProp="children"
    onChange={(e)=>dispatch(handelChangeAsync(e))}
    onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }>
          <option selected disabled>Select a Role</option>

          {dataroles && dataroles.map((e)=>(
    <option value={`${e._id}`}>{e.Name}</option>
          ))}
</select>
<div
                  style={{background:"gray",borderRadius:"50%",width:"20px" ,height:"20px",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"5px"}} 
                  onClick={()=>{
                    setAddDepartement(false)
                    setAddRole(!addRole)}}>
                    <img src={PlusIcon} style={{display:"flex"}} alt='PlusIcon'/></div>
      </Form.Item>
      <Form.Item label="Departement " {...formItemLayout}  style={{width:"50%",marginLeft:"50px"}}>
      <select   disabled={disable}
    showSearch
    placeholder="Select a departement"
    name='id_departement'
    className='selectProjectLead'
    optionFilterProp="children"
    onChange={(e)=>dispatch(handelChangeAsync(e))}
    onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }>
                <option selected disabled>Select a departement</option>

        {datadepartements && datadepartements.map((e)=>(
    <option value={`${e._id}`}>{e.Name}</option>
          ))}
</select>
<div
                  style={{background:"gray",borderRadius:"50%",width:"20px" ,height:"20px",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"5px"}} onClick={()=>{
                    setAddRole(false)
                    setAddDepartement(!newDepartement)}}>
                    <img src={PlusIcon} style={{display:"flex"}} alt='PlusIcon'/></div>
      </Form.Item>
      </div>
      <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}>
     {addRole && (
      <Form.Item  {...formItemLayout}  style={{width:"50%"}}>
     <input  className='inputminifiledaddticket' disabled={disable} placeholder="New Role" value={newRole} onChange={(e)=>{
      setNewrole(e.target.value)
      // dispatch(addRoleAsync(newRole))
      }}/>

     </Form.Item>
     )}
     {addDepartement && (
      <Form.Item  {...formItemLayout}  style={{width:"50%",marginLeft:addRole ? "50px":"55%"}}>
     <input  className='inputminifiledaddticket' disabled={disable} placeholder="New Departement" value={newDepartement} type="password" onChange={(e)=>
     setNewDepartement(e.target.value)}
      />

     </Form.Item>
     )}
     
     </div>
      <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}>
     
      <Form.Item label="Email Adress" {...formItemLayout}  style={{width:"50%"}}>
      <input  className='inputminifiledaddticket' disabled={disable} placeholder="Name Surname" name='Email' onChange={(e)=>dispatch(handelChangeAsync(e))}/>

      </Form.Item>
      <Form.Item label="Password" {...formItemLayout}  style={{width:"50%",marginLeft:"50px"}}>
      <input  className='inputminifiledaddticket' disabled={disable} placeholder="Name Surname" name='Password' type="password" onChange={(e)=>dispatch(handelChangeAsync(e))}/>

      </Form.Item>
      </div>
      <Form.Item
          {...formItemLayout}
          style={{ width: "50%" }}
        >
         <Upload 
          fileList={infoList}
            onChange={({ fileList }) => {
              setInfoList(fileList);
            }}>
    <Button className='attaachfile' icon={<UploadOutlined />} disabled={disable}> Add Image</Button>
  </Upload>
      </Form.Item>
     
      
 {!disable &&(
  <>

      <Form.Item {...buttonItemLayout} style={{display:"flex",justifyContent:"center",marginTop:"100px"}}>
        <div className='buutonsaveaddticket' onClick={()=>{
         
          if(addRole && !addDepartement){
         dispatch(addRoleAsync({Name :newRole}))
          }else if (!addRole  && addDepartement){
            dispatch(addDepartementAsync({Name :newDepartement}))

          }
            else {
          dispatch(addUserAsync({...datatosend,images:infoList}))
          handleCancel()
          }
         
        }}>
       {   (addRole  && !addDepartement )? "Add New Role":(!addRole  && addDepartement )? "Add New Departement": "Save"}

          </div>
          <div className='buutoncanceladdticket' onClick={()=>handleCancel()}>
          Cancel
          </div>
      </Form.Item></>
 )}
      
    </Form>
  </div>
  )
}

export default Form3