import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsAsync } from '../../features/Projects/projectSlice'
import HorLineToDo from "../../assets/HorLineToDo.png";
import HorLineInProgress from "../../assets/HorLineInProgress.png";
import HorLineDone from "../../assets/HorLineDone.png";
import taskSquare from "../../assets/task-square.png";
import taskSquareActive from "../../assets/task-square-active.png";
import ProjectsIcon from "../../assets/ProjectsIcon.png";
import ProjectsIconActive from "../../assets/ProjectIconActive.png";
import TeamIcon from "../../assets/TeamIcon.png";
import TeamIconActive from "../../assets/TeamIconActive.png";
import PlusIcon from "../../assets/PlusIcon.png";
import Text from '../../shared/Text'
import Projectcard from './Projectcard'

const Projects = ({type ,detailsselect, setDetailsselect}) => {
 
  return (
    <>
    
   {/* <div
            className=
               "logo"
               
           
            
          >
            <div className="logo">
        

            
                <div
                  className= "select"
                 
                >
                  <div>
                    <img
                      src={ ProjectsIcon}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div style={{ marginLeft: "17px" }}>
                    <Text
                      content="Projects"
                      classname= "textTasksActive"
                    />
                  </div>
                </div>
              
           
                <div
                  className="select"
                
                >
                  <div>
                    <img
                      src={ TeamIcon}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div style={{ marginLeft: "17px" }}>
                    <Text
                      content="Team"
                      classname="textTasks"
                    />
                  </div>
                </div>
              
              
                <div
                  className= "select"
                
                >
                  <div>
                    <img
                      src={ taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div style={{ marginLeft: "17px" }}>
                    <Text
                      content="Inter  departements "
                      classname="textTasks"
                    />
                  </div>
                </div>
              
            </div>
          </div> */}
         <Projectcard setDetailsselect={setDetailsselect} detailsselect={detailsselect} select={type}/>
  
     </>
  )
}

export default Projects