import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import io from "socket.io-client";
import Base_URL from "../../app/api/apiSlice";
import { addNotifcationsAsync } from "../Notification/NotificationSlice";

const API_URL = `${Base_URL}/comments`;
const socket = io(`${Base_URL}`);
export const commentsSlide = createSlice({
  name: "comments",
  initialState: {
    datacomments: [],
    notifications: [],
    type: 0,
    error: false,
    loading: false,
  },

  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getComments: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datacomments = action.payload;
    },
    addComments: (state, action) => {
      state.datacomments = [...state.datacomments, action.payload];
      state.notifications = [...state.notifications, "Admin add coment"];
    },
    handelChangeTypeComment: (state, action) => {
      state.type = action.payload;
    },
    handelChangeComment: (state, action) => {
      state.loading = false;
      state.error = false;

      state.datacomments.push(action.payload);
    },
    handelUpdateComment: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datacomments = [...state.datacomments, ...action.payload];
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
  },
});

//get Tous les comments
export const getCommentsAsync = () => async (dispatch) => {
  api.get("/getAllcomment").then((response) => {
    dispatch(getComments(response.data));
  });
};
export const addCommentsAsync =
  (data, responsable, ajouterPar) => async (dispatch) => {
    const Idresponsabel = responsable.map((e) => e._id);
    const array = [...Idresponsabel, ajouterPar].filter(
      (e) => e !== data.sender
    );
    api
      .post("/ajoutercomment", { ...data, recived: array })
      .then((response) => {
        dispatch(addComments(response.data));
        //   dispatch(
        //     addNotifcationsAsync({
        //       Name: `${JSON.parse(localStorage.getItem("Name")) } add new comment `,
        //       sender: JSON.parse(localStorage.getItem("id")),
        //       recived: array,
        //       type: "comment",
        //     })
        //   );
        //
      });
  };

export const updateCommentLikeAsync = (data) => async (dispatch) => {
  api
    .put(`/updateCommentLike`, data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const updateCommentdisLikeAsync = (data) => async (dispatch) => {
  api
    .put(`/updateCommentdisLike`, data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const updateCommenReplayAsync = (data) => async (dispatch) => {
  api
    .put(`/updateCommentReplay`, data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const handelChangeCommentAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeComment(e));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelUpdateCommentAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelUpdateComment(e));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeTypeCommentAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeTypeComment(e));
  } catch (err) {
    dispatch(setError());
  }
};

export const {
  getComments,
  handelChangeComment,
  handelChangeTypeComment,
  addComments,
  handelUpdateComment,
  setError,
  setLoading,
} = commentsSlide.actions;
export default commentsSlide.reducer;
