import { configureStore } from "@reduxjs/toolkit";
import  authSlide from "../features/Auth/authSlice";
import commentSlice from "../features/Comment/commentSlice";
import  departementsSlide from "../features/Departement/departementSlice";
import jobpostSlice from "../features/JobPost/jobpostSlice";
import NotificationSlice from "../features/Notification/NotificationSlice";
import  projectsSlide  from "../features/Projects/projectSlice";
import rolesSlide from "../features/Role/roleSlice";
import tasksSlide  from "../features/Tasks/TaskSlice";
import  usersSlide  from "../features/Users/userSlice";

import  statsSlice  from "../features/Stats/StatsSlice";
import scrappingSlice from "../features/Scrapping/scrappingSlice";



export const store = configureStore({
    reducer: {
        
        projects:projectsSlide,
        users:usersSlide,
        roles:rolesSlide,
        auth:authSlide,
        tasks:tasksSlide,
        comments:commentSlice,
        notification:NotificationSlice,
        departements:departementsSlide,
        jobposts:jobpostSlice,
        stasts:statsSlice,
        scarppings:scrappingSlice
    }
})