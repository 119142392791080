
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
    
    const location = useLocation();
    const auth = JSON.parse(localStorage.getItem('token'));
    const role = JSON.parse(localStorage.getItem('role'));
    // const departement = JSON.parse(localStorage.getItem('departement'));



    return (
        auth && allowedRoles.includes(role)
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    );
}

export default RequireAuth;