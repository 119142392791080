import React, { useEffect, useState } from "react";
import { Badge } from "antd";
import { Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import JoindreficherIcon from "../../assets/JoindreficherIcon.png";
import LineOption from "../../assets/LineOption.png";
import commentsIcon from "../../assets/commentsIcon.png";
import deletetask from "../../assets/delete.png";
import Swal from "sweetalert2";

import logoProject from "../../assets/logoProject.png";

import eye from "../../assets/eye.png";

import Text from "../../shared/Text";
import Modal1 from "../../components/Modal/Modal";
import Modalcenter from "../../components/Modal/Modalcenter";
import { useDispatch } from "react-redux";
import { deleteTaskAsync, getTasksAsync } from "../../features/Tasks/TaskSlice";
import Modal2 from "../../components/Modal/Modal2";
import {
  deleteJobPostAsync,
  getaddJobPostAsync,
} from "../../features/JobPost/jobpostSlice";
import { deleteScrappingAsync } from "../../features/Scrapping/scrappingSlice";
import Modalscapping from "../../components/Modal/Modalscapping";
const tempColors = { todo: 1, doing: 2, done: 3 };
const menu = (setmodalIsOpen2, setDetailsselect, handelDeleteTask) => (
  <Menu>
    <Menu.Item
      key="2"
      onClick={() => {
        setDetailsselect("Ticket Details");
        setmodalIsOpen2(true);
      }}
    >
      {" "}
      <div className="text-more-task">Ticket Details</div>
      <div>
        <img src={LineOption} alt="LineOption" />
      </div>
    </Menu.Item>
    {JSON.parse(localStorage.getItem("role")) === "Admin" && (
      <Menu.Item key="3" onClick={() => handelDeleteTask()}>
        <div className="text-more-task">Delete Ticket</div>
        <div>
          <img src={LineOption} alt="LineOption" />
        </div>
      </Menu.Item>
    )}
  </Menu>
);
export default function Trellcarduser({
  id,
  id_Task,
  status,
  responsable,
  title,
  data2,
  role,
  projectName,
  label,
  select,
  detailsselect,
  setDetailsselect,
  datacomments,
  datafiles,
  detaisdata,
  color,
  typetask,
}) {
  const [onHold, setOnHold] = useState(false);
  const [modalIsOpen2, setmodalIsOpen2] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [idTask, setIdTask] = useState("");
  const [files, setFiles] = useState([]);
  const [dataOne, setDataOne] = useState({});
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [update, setUpdate] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const handleCancel2 = () => {
    setmodalIsOpen2(false);
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
  };
  const handleCancel3 = () => {
    setModalIsOpen3(false);
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
  };
  const handelDeleteTask = () => {
    Swal.fire({
      title: "Do you want to delete this task?",
      showDenyButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("task delete avec sucess!", "", "task delete avec sucess");
        dispatch(deleteTaskAsync(id_Task));
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handelDeleteScrapping = () => {
    console.log(detaisdata._id);
    Swal.fire({
      title: "Do you want to delete this scrapping request ?",
      showDenyButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire(
          "scrapping request delete avec sucess!",
          "",
          "scrapping request  delete avec sucess"
        );
        dispatch(deleteScrappingAsync(detaisdata._id));
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handelDeleteJobPost = () => {
    Swal.fire({
      title: "Do you want to delete this JobPost?",
      showDenyButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire(
          "job post delete avec sucess!",
          "",
          "task delete avec sucess"
        );
        dispatch(deleteJobPostAsync(detaisdata._id));
        dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
        dispatch(getaddJobPostAsync());
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const dragStartHandler = (e) => {
    e.dataTransfer.setData("cardInfo", JSON.stringify({ id, status }));
    e.target.className += " ohhold";
    setTimeout(() => {
      setOnHold(true);
    }, 0);
  };
  const dragEndHandler = () => {
    setOnHold(false);
  };
  const onDragOverHandler = (e) => {
    e.preventDefault();
    if (e.target.className === "card") {
      setTimeout(() => {
        e.target.className = "card anotherCardOnTop";
      }, 0);
    }
  };
  const onDragLeaveHandler = (e) => {
    resetClassName(e);
  };
  const onDropHandler = (e) => {
    resetClassName(e);
    /**  
     TODO: Remove all anotherCardOnTop classnames 
     from DOM after drop complete.
    */
  };
  function handleCancel1() {
    setUpdate(false);
    setModalIsOpen1(false);
  }
  const resetClassName = (e) => {
    e.preventDefault();
    let isCard =
      e.target.className === "card" ||
      e.target.className === "card anotherCardOnTop";
    if (isCard) {
      setTimeout(() => {
        e.target.className = "card";
      }, 0);
    }
  };

  return (
    <Badge
      count={
        typetask === "scrapping"
          ? "Scrapping Request"
          : typetask === "jobpost"
          ? "Job Post Request"
          : projectName
      }
      style={{ backgroundColor: typetask === "jobpost" ? "#FF1493" : color }}
      className="texttaskname"
    >
      <div
        id={id}
        className={`card${tempColors[status]} ${onHold ? "hidden" : ""}`}
        draggable="true"
        onDragStart={dragStartHandler}
        onDragEnd={dragEndHandler}
        onDragOver={onDragOverHandler}
        onDragLeave={onDragLeaveHandler}
        onDrop={onDropHandler}
      >
        {typetask === "task" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
              }}
              onClick={() => {
                handelDeleteTask();
              }}
            >
              <img
                src={deletetask}
                alt="eye"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setDetailsselect("Ticket Details");
                setmodalIsOpen2(true);
              }}
            >
              <img src={eye} alt="eye" />
            </div>{" "}
          </div>
        ) : typetask === "scrapping" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
              }}
              onClick={() => {
                handelDeleteScrapping();
              }}
            >
              <img
                src={deletetask}
                alt="eye"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalIsOpen3(true);
                setUpdate(true);
                setDataOne(detaisdata);
              }}
            >
              <img src={eye} alt="eye" />
            </div>{" "}
          </div>
        ) : (
          //    <div>
          //    <Dropdown
          //      overlay={menu(
          //        setmodalIsOpen2,
          //        setDetailsselect,
          //        handelDeleteTask,
          //        label
          //      )}
          //    >
          //      <button>
          //        <FontAwesomeIcon icon={faEllipsisV} />
          //      </button>
          //    </Dropdown>
          //  </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
              }}
              onClick={() => {
                handelDeleteJobPost();
              }}
            >
              <img
                src={deletetask}
                alt="eye"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setModalIsOpen1(true);
                setUpdate(true);
                setDataOne(detaisdata);
              }}
            >
              <img src={eye} alt="eye" />
            </div>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="cardTitle"
              style={{
                marginLeft: " 5px",

                width: "50%",

                maxHeight: "50px",

                height: "fit-content",
                marginTop: "5px",
                overflowY: "scroll",
              }}
            >
              {typetask === "scrapping"
                ? detaisdata.Client !== "undefined"
                  ? `Client:${detaisdata.Client}`
                  : ""
                : title}
            </div>
            <div
              className="cardTitle"
              style={{
                marginLeft: " 5px",
                maxHeight: "50px",

                width: "50%",
                height: "fit-content",
                marginTop: "5px",
                overflowY: "scroll",
              }}
            >
              {typetask === "scrapping" &&
              detaisdata.ProjectName !== "undefined"
                ? `Project:${detaisdata.ProjectName}`
                : ""}
            </div>
          </div>
          <div
            className="cardTitle"
            style={{
              width: "90%",
              maxHeight: "50px",

              height: "fit-content",
              overflowY: "scroll",
            }}
          >
            {typetask === "scrapping" && detaisdata.JobTitle !== "undefined"
              ? `JobTitle:${detaisdata.JobTitle}`
              : ""}
          </div>
        </div>
        <div className="cardFooter">
          <div
            style={{
              display: "flex",
              marginTop: "0px",
              justifyContent: "space-between",
              marginRight: "25px",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                background: "rgba(255, 108, 101, 0.16)",
                color: "#FF6C65",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                lineHeight: "normal",
              }}
            >
              {detaisdata.Priority !== "undefined" && detaisdata.Priority}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ color: "gray" }}>Request by </div>
              <div className="textby">{` ${
                detaisdata.ajouterPar && detaisdata.ajouterPar.Name
              } `}</div>
            </div>
          </div>

          <div className="collab">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {detaisdata &&
                detaisdata.responsable &&
                detaisdata.responsable.map((e, key) => (
                  <a
                    key={key}
                    className="collabPerson"
                    onClick={() => {
                      if (
                        JSON.parse(localStorage.getItem("role")) === "Admin"
                      ) {
                        setType("Team");
                        handleShow();
                      }
                    }}
                    target="_blank"
                  >
                    {e.images && e.images.length > 0 ? (
                      <img
                        src={e.images[0].path}
                        style={{
                          width: "30px",
                          height: "30px",

                          borderRadius: "50% ",
                        }}
                        alt="f"
                      />
                    ) : (
                      <i className="fas fa-user"></i>
                    )}
                  </a>
                ))}

              <a
                className="collabPerson"
                onClick={() => {
                  if (JSON.parse(localStorage.getItem("role")) === "Admin") {
                    setType("Team");
                    handleShow();
                  }
                }}
                target="_blank"
              >
                {detaisdata &&
                detaisdata.ajouterPar &&
                detaisdata.ajouterPar.images &&
                detaisdata.ajouterPar.images.length > 0 ? (
                  <img
                    src={detaisdata.ajouterPar.images[0].path}
                    style={{
                      width: "30px",
                      height: "30px",

                      borderRadius: "50% ",
                    }}
                    alt="f"
                  />
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </a>
            </div>

            {/* <a  className="collabPerson"  onClick={()=>{
              setType('Team')
              handleShow()}}>
              <i className="fas fa-user"></i>
              

            </a>  <a className="collabPerson"  onClick={()=>{
              setType('Team')
              handleShow()}}>
              <i className="fas fa-user"></i>
              

            </a>   */}
            <div className="collabPerson1">
              <div
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  setType("comment");
                  setIdTask(id_Task);
                  handleShow();
                }}
              >
                <img src={commentsIcon} alt="JoindreficherIcon" />
                <Text
                  content={`${datacomments.length} comments`}
                  classname="textcarfooter"
                />
              </div>
              <div
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  setFiles(datafiles);
                  setType("file");
                  handleShow();
                }}
              >
                <img src={JoindreficherIcon} alt="JoindreficherIcon" />
                <Text
                  content={`${datafiles && datafiles.length} files`}
                  classname="textcarfooter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal1
        title={detailsselect}
        type={select}
        detailsselect={detailsselect}
        detaisdata={detaisdata}
        data={data2}
        modalIsOpen={modalIsOpen2}
        disable={true}
        handleCancel={handleCancel2}
      />
      <Modalcenter
        type={type}
        show={show}
        files={files}
        datacomments={datacomments}
        idTask={id_Task}
        data={detaisdata.responsable}
        detaisdata={detaisdata}
        handleClose={handleClose}
        label={label}
        ajouterPar={detaisdata.ajouterPar}
      />
      {modalIsOpen1 && (
        <Modal2
          update={update}
          details={dataOne}
          role={role}
          type={select}
          handleCancel={handleCancel1}
        />
      )}
      {modalIsOpen3 && (
        <Modalscapping
          update={update}
          details={dataOne}
          role={role}
          type={select}
          handleCancel={handleCancel3}
        />
      )}
    </Badge>
  );
}
