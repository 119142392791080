import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import Swal from "sweetalert2";
import Base_URL from "../../app/api/apiSlice";
import { addNotifcationsAsync } from "../Notification/NotificationSlice";
import { getTasksAsync } from "../Tasks/TaskSlice";
import { getStatsAsync } from "../Stats/StatsSlice";

const API_URL = `${Base_URL}/jobposts`;
export const jobpostsSlide = createSlice({
  name: "jobposts",
  initialState: {
    datajobposts: [],
    datatosend: {},
    error: false,
    loading: false,
  },

  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    addJobPost: (state, action) => {
      state.datajobposts = [...state.datajobposts, action.payload];
    },

    getJobPost: (state, action) => {
      state.datajobposts = [...action.payload];

      state.loading = false;
      state.error = false;
    },
    updateJobPost: (state, action) => {
      state.datajobposts = [
        ...state.datajobposts.filter((e) => e.id !== state.datajobposts),
        action.payload,
      ];
    },
    handelChange: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeselect: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeResponsable: (state, action) => {
      state.datatosend = { responsable: action.payload };
    },

    setError: (state) => {
      state.error = true;
    },
  },
});
// const auth = JSON.parse(localStorage.getItem('token'));

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});
const api2 = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
    // 'Authorization': 'Bearer ' + auth
  },
});

//get Tous les tasks
export const getaddJobPostAsync = () => async (dispatch) => {
  api
    .get("/getJobPost")
    .then((response) => {
      dispatch(getJobPost(response.data));
    })
    .catch((err) => console.log(err));
};

//add un seul task

export const addJobPostAsync = (data) => async (dispatch) => {
  const formData = new FormData();
  formData.append("responsable", data.responsable);
  for (var j = 0; j < data.files.length; j++) {
    formData.append("files", data.files[j].originFileObj);
  }

  formData.append("Language", data.Language);
  formData.append("id", data.id);
  formData.append("label", data.label);

  formData.append("ajouterPar", data.ajouterPar);
  formData.append("language", data.Language);
  formData.append("description", data.description);
  formData.append("filed", data.filed);
  formData.append("location", data.location);
  formData.append("responsable", data.responsable);
  formData.append("title", data.title);
  formData.append("RecruiterEmail", data.RecruiterEmail);
  formData.append("RecruiterLien", data.RecruiterLien);

  api2
    .post(`/addJobPost`, formData)
    .then((response) => {
      dispatch(addJobPost(response.data));
      // dispatch(
      //   addNotifcationsAsync({
      //     Name: `${JSON.parse(localStorage.getItem("Name")) } add new Job Post `,
      //     sender: JSON.parse(localStorage.getItem("id")),
      //     recived: [data.responsable[1]],
      //     type: "add Job Post",
      //   })
      // );
      dispatch(getaddJobPostAsync());
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    })
    .catch((err) => {
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
      console.log(err);
      if (err.response.data.message === "All fields are required") {
        Swal.fire("All fields are required", "", "info");
      }
    });
};
export const deleteJobPostAsync = (data) => async (dispatch) => {
  api
    .delete(`/deleteJobPost`, { data: { id: data } })
    .then((response) => {
      dispatch(getJobPost());
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateJobPostLinkAsync = (data) => async (dispatch) => {
  const formData = new FormData();

  for (var i = 0; i < data.filescomminty.length; i++) {
    formData.append("filescomminty", data.filescomminty[i].originFileObj);
  }
  formData.append("link", data.Link);
  formData.append("id", data.id);

  api
    .put(`/updateJobPostLink`, formData)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const updateJobPostAsync = (data) => async (dispatch) => {
  console.log(data);
  api
    .put(`/updateJobPost`, {
      ...data,
      userconncted: JSON.parse(localStorage.getItem("id")),
    })
    .then((response) => {
      dispatch(getaddJobPostAsync());
      //dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
      //dispatch(getStatsAsync(new Date(), 0, 3));

      //   if(response.data.status==='done'){
      //     if(response.data.responsable){
      //       const array=[response.data.ajouterPar]
      //       dispatch(
      //         addNotifcationsAsync({
      //           Name: `${JSON.parse(localStorage.getItem("Name")) } update  Task `,
      //           sender: JSON.parse(localStorage.getItem("id")),
      //           recived: array.filter((e)=>e!==JSON.parse(localStorage.getItem("id"))),
      //           type: "update job post",
      //         })
      //       );
      //     }else{
      //       const array=[...response.data.responsable,response.data.ajouterPar]
      //  dispatch(
      //         addNotifcationsAsync({
      //           Name: `${JSON.parse(localStorage.getItem("Name")) } update  Task `,
      //           sender: JSON.parse(localStorage.getItem("id")),
      //           recived: array.filter((e)=>e!==JSON.parse(localStorage.getItem("id"))),
      //           type: "update job post",
      //         })
      //       );
      //     }

      //   }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateallJobPostAsync = (data) => async (dispatch) => {
  console.log(data);
  api
    .put(`/UpdateJobpostall`, data)
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));

      // const array=[...response.data.responsable,response.data.ajouterPar]
      // if(response.data.status==='done'){
      //    dispatch(
      //   addNotifcationsAsync({
      //     Name: `${JSON.parse(localStorage.getItem("Name")) } update  Task `,
      //     sender: JSON.parse(localStorage.getItem("id")),
      //     recived: array.filter((e)=>e!==JSON.parse(localStorage.getItem("id"))),
      //     type: "update job post",
      //   })
      // );
      // }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
//remlpir formulaire add tasks
export const handelChangeAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChange({ [e.target.name]: e.target.value }));
  } catch (err) {
    dispatch(setError());
  }
};

export const handelChangeselectAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeselect(e));
  } catch (err) {
    console.log(err);
    dispatch(setError());
  }
};

export const {
  addTask,
  getTasks,
  resetData,
  getTaskscalander,
  handelChange,
  setError,
  setLoading,
  deleteTask,
  handelChangeDate,
  handelChangeTime,
  handelChangeselect,
  handelChangeResponsable,
  addJobPost,
  getJobPost,
  updateJobPost,
} = jobpostsSlide.actions;
export default jobpostsSlide.reducer;
