import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";
import "./Modal.css";
import { Form, Upload, Button, Radio } from "antd";
import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { CountryDropdown } from "react-country-region-selector";
import "./Modal.css";
import close from "../../assets/x.png";
import { addScrappingAsync, handelChangeAsync } from "../../features/Scrapping/scrappingSlice";
import { getUsersByroleAdminAsync, getUsersByroleAsync } from "../../features/Users/userSlice";

const Modalscapping = ({
  title,
  disable,
  modalIsOpen,
  handleCancel,
  role,
  details,
  update,
}) => {
  const [formLayout] = useState("vertical");
  const [infoList, setInfoList] = useState([]);
  const [infoList2, setInfoList2] = useState([]);

  const [value, setValue] = React.useState(null);
  const [filedvalue, setfiledvalue] = React.useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);
  const [isCopied3, setIsCopied3] = useState(false);
  const [isCopied4, setIsCopied4] = useState(false);
  const [isCopied5, setIsCopied5] = useState(false);
  const [valueEmail, setValueEmail] = useState("Email");

  const dispatch = useDispatch();
  const datatosend = useSelector((state) => state.scarppings.datatosend);
  const dataOnebyrole = useSelector((state) => state.users.dataOnebyrole);
  const dataOnebyroleAdmin = useSelector(
    (state) => state.users.dataOnebyroleAdmin
  );
  const alldataourdepartemnt = useSelector(
    (state) => state.tasks.alldataourdepartemnt
  );
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        }
      : null;
  const buttonItemLayout =
    formLayout === "vertical"
      ? {
          wrapperCol: { span: 24, offset: 4 },
        }
      : null;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onChange = (e) => {
    setValueEmail(e.target.value);
  };
  
  useEffect(()=>{
    dispatch(getUsersByroleAsync("BI Developper"));
    dispatch(getUsersByroleAdminAsync("Admin"));
  },[])
  const handelSubmit = () => {
   console.log(dataOnebyroleAdmin,dataOnebyrole);
          dispatch(
                  addScrappingAsync({
                    ...datatosend,
                    responsable: [dataOnebyroleAdmin._id, dataOnebyrole._id],
                    ajouterPar: `${JSON.parse(localStorage.getItem("id"))}`,
                    files: infoList,
                  })
                );
                handleCancel()
               
  };
  const handleDownload = async (filename, urlPDF) => {
    var a = document.createElement("a");
    const response = await fetch(urlPDF);
    const blob = await response.blob();
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClientRefClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.description)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyProjectClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.link)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied2(true);
          setTimeout(() => {
            setIsCopied2(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyClientClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.title)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied3(true);
          setTimeout(() => {
            setIsCopied3(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyRecutierEmailClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      if (details.RecruiterEmail === "undefined") {
        copyTextToClipboard(details.RecruiterLien)
          .then(() => {
            // If successful, update the isCopied state value
            setIsCopied4(true);
            setTimeout(() => {
              setIsCopied4(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        copyTextToClipboard(details.RecruiterEmail)
          .then(() => {
            // If successful, update the isCopied state value
            setIsCopied4(true);
            setTimeout(() => {
              setIsCopied4(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handleCopyRecutierLienClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.RecruiterLien)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied5(true);
          setTimeout(() => {
            setIsCopied5(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyRecutierNameClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.ajouterPar.Name)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied4(true);
          setTimeout(() => {
            setIsCopied4(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyWorkColactionClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.location)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied5(true);
          setTimeout(() => {
            setIsCopied5(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  return (
    <div className="app">
      <div className="darkBG" onClick={handleCancel} />
      <div className="centered">
        <div className="contenttittle">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="titlePostForm">Scrapping Form</div>
            <div>
              <img src={close} onClick={handleCancel} />
            </div>
          </div>
          <Form layout={formLayout} style={{ width: "100%", marginTop: "2%" }}>
          <div style={{ display: "flex", width: "100%" ,justifyContent:"space-between"}}>
          <Form.Item label="Client Ref " {...formItemLayout} style={{ width: "45%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="ClientRef"
                  placeholder={update ? details.ClientRef : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClientRefClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
            <Form.Item label="Client " {...formItemLayout}  style={{ width: "45%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="Client"
                  placeholder={update ? details.Client : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClientClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
         
              
            </div>
            <div style={{ display: "flex", width: "100%" ,justifyContent:"space-between"}}>
            <Form.Item label="Project Name ( If any)" {...formItemLayout} style={{ width: "45%" }}>
              <div style={{ display: "flex", flexDirection: "row" }} >
                <textarea
                  className="inputfiledjob"
                  name="ProjectName"
                  placeholder={update ? details.ProjectName : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyProjectClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
            <Form.Item label="Job Title " {...formItemLayout}  style={{ width: "45%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="JobTitle"
                  placeholder={update ? details.JobTitle : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClientClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
         
              
            </div>
            <div style={{ display: "flex", width: "100%" ,justifyContent:"space-between"}}>
            <Form.Item label="Industry" {...formItemLayout} style={{ width: "30%" }}>
              <div style={{ display: "flex", flexDirection: "row" }} >
                <textarea
                  className="inputfiledjob"
                  name="Industry"
                  placeholder={update ? details.Industry : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyProjectClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
            <Form.Item label="Location of work" {...formItemLayout} style={{ width: "30%" }}>
              <div style={{ display: "flex", flexDirection: "row" }} >
                <textarea
                  className="inputfiledjob"
                  name="LocationOfwork"
                  placeholder={update ? details.LocationOfwork : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyProjectClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
            <Form.Item label="Years of expriences" {...formItemLayout}  style={{ width: "30%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="yearsOfExprience"
                  placeholder={update ? details.yearsOfExprience : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClientClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
         
              
            </div>
            <Form.Item label="Other" {...formItemLayout} style={{ width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="Other"
                  placeholder={update ? details.Other : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {/* {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClientRefClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )} */}
              </div>
            </Form.Item>
   
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "529px",
              }}
            ></div>
            {(role === "Recrutement" ||
              JSON.parse(localStorage.getItem("description")) ===
                "Chief Recruitement Officer" ||
              (role === "community manager" && update)) && (
              <Form.Item
                {...buttonItemLayout}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <button className="buutonsaveaddticket" onClick={handelSubmit}>
                  {  update
                    ? "Update"
                    : "Save"}
                </button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Modalscapping;
