import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';import './index.css';
import App from './App';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './common/en.json';
import translationFR from './common/fr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
  lng: 'en', // Set the default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // Allows passing variables to translations
  },
});
ReactDOM.createRoot(document.getElementById('root')).render( <BrowserRouter>
  <Provider store={store}>
    <App />
  </Provider>
</BrowserRouter>)
