import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useDispatch } from "react-redux";
import { handelChange, handelChangeColorAsync } from "../features/Projects/projectSlice";

const PaletteColor = ({ detaisdata, disable }) => {
  const customPaletteColors = ["#C0C0C0	", "#808080", "#000000", "#FF0000","#800000","#FFFF00","#808000","#00FF00","#008000","#00FFFF","#008080","#0000FF","#000080","#FF00FF","#800080"
, "#723893",
"#FF5733",
"#00A36C",
"#FFD700",
"#4CAF50",
"#2196F3",
"#FF9800",
"#9C27B0",
"#F44336",
"#607D8B"];
  const [color, setColor] = useState(disable ? (detaisdata && detaisdata.Color) : "");
  const dispatch = useDispatch();

  function handleChange(newColor) {
    console.log(newColor);
    setColor(newColor);
    dispatch(handelChangeColorAsync(newColor));
  }

  useEffect(() => {
    if (disable) {
      setColor(detaisdata && detaisdata.Color);
    }
  }, [disable]);

  return (
      customPaletteColors.map((e)=>
        <div 
        onClick={()=>{
          handleChange(e)
        }}
        style={{
          background: e,
    height: "25px",
    width: "25px",
    marginLeft:"5px",
    marginTop:"5px",
    cursor: "pointer",
    outline: "none",
    borderRadius: "3px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset",
    border:color===e?"1px solid #33FFDD":"none"
        }}>
          </div>

      )
  );
};

export default PaletteColor;
