import React, {  useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Comme from "../../shared/Comments1";
import x from "../../assets/x.png";
import File from "../../assets/File.png";
import {Upload} from 'antd'
import { useDispatch } from "react-redux";
import { UpdateFileTaskAsync, updateResponsableTaskAsync } from "../../features/Tasks/TaskSlice";
import { UploadOutlined } from "@ant-design/icons";
import { Radio } from 'antd';
import Modal1 from "./Modal";
import { useEffect } from "react";
import { UpdateFileScrappingAsync } from "../../features/Scrapping/scrappingSlice";
const Modalcenter = ({
  handleClose,
  show,
  type,
  type2,
  data,
  title,
  datacomments,
  handleCancel,
  idTask,
  files,
  ajouterPar,
  label,
  detaisdata,
  modalIsOpen,
  openModal
}) => {
  const [infoList, setInfoList] = useState([]);
  const [value, setValue] = useState(1);
const [detailsselect1,setdetailsselect1]=useState("")


 const dispatch =useDispatch()
 const handleDownload = async(filename,urlPDF) => {
  try {
    var a = document.createElement("a");
    const response = await fetch(urlPDF);

    if (!response.ok) {
      throw new Error('Failed to fetch resource');
    }

    const blob = await response.blob();
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }

};
const onChange = (e) => {
  setdetailsselect1( e.target.value);
  openModal()
  handleCancel()
};
useEffect(()=>{

},[])
  if (type === "comment") {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Comme
              data={datacomments}
              idTask={idTask}
              ajouterPar={ajouterPar}
              label={label}
              responsable={data}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  } else if (type === "file") {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <DrogFile files={files} /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                minHeight: "100px",
                height: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {files &&
                files.map((e) => (
                  <div
                  key={`${e.path}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <div>
                      <img src={File}  alt='File'/>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={()=>{
                        handleDownload(e.path,e.fileName)
                      }}
                    >
                      <a href="#">
                        {e.fileName}{" "}
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
           
          <Upload
            name="file"
            fileList={infoList}
            onChange={({ fileList }) => {
                setInfoList(fileList)
              


            }}
            multiple
          >
            <Button
              className="attaachfile"
              style={{ borderColor:infoList.length >0 && "green"}}
            
              icon={<UploadOutlined />}

            >
              {" "}
              Uploaded From pc
            </Button>
          </Upload>  
        <button
        style={{ borderColor:infoList.length >0 && "green"}} 
          onClick={()=>{
                if(detaisdata.type
                  ===
                  "scrapping"){
                    dispatch(UpdateFileScrappingAsync({infoList:infoList,id:detaisdata._id}))
                  }else{
                    dispatch(UpdateFileTaskAsync({infoList:infoList,id:detaisdata._id}))
                    handleClose();
                  }
           
            }}
        >
         {detaisdata.type==="scrapping"?"update Scrapping":" update task"}
        </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else if (type === "Team") {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Team</div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {detaisdata &&
              detaisdata.responsable.map((e,index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {e.images.length > 0 ? (
                        <img
                          src={e.images[0].path}
                          style={{ width: "40px",borderRadius:'50%' }}
                          alt="f"
                        />
                      ) : (
                        <i className="fas fa-user"></i>
                      )}
                    </div>
                    <div style={{ marginLeft: "22px" }}>
                      <div className="Teammodaltext">{e.Name}</div>
                      <div className="Teammodaltextrole">{e.id_role.Name}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {
                      e.id_role.Name!=="Admin" && (
                      <img src={x} alt="close" onClick={()=>{
                      const index1 = detaisdata.responsable.indexOf(e);
                      dispatch(updateResponsableTaskAsync({id:detaisdata._id,Responsable: detaisdata.responsable.filter((e)=>e._id!==detaisdata.responsable[index1]._id)}))
                    }}/>)
                    }
                    
                  </div>
                </div>
              ))}
           
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {detaisdata.ajouterPar.images.length > 0 ? (
                        <img
                          src={detaisdata.ajouterPar.images[0].path}
                          style={{ width: "40px",borderRadius:'50%' }}
                          alt="f"
                        />
                      ) : (
                        <i className="fas fa-user"></i>
                      )}
                    </div>
                    <div style={{ marginLeft: "22px" }}>
                      <div className="Teammodaltext">{ detaisdata.ajouterPar && detaisdata.ajouterPar.Name}</div>
                      <div className="Teammodaltextrole">{detaisdata.ajouterPar && detaisdata.ajouterPar.description }</div>
                    </div>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   
                    
                  </div>
                </div>
              
          </Modal.Body>
        </Modal>
      </>
    );
  }
  else if (type === "choice") {
    return (
      <>
        <Modal show={show} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Choice Type of ticket</div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
          <Radio.Group onChange={onChange} value={value}>
      <Radio value={"Form5"}  >Task in my departement</Radio>
      <Radio value={"Inter departement"}>Task with other departement</Radio>

    </Radio.Group>
          </Modal.Body>
        </Modal>
        <Modal1
        title={title}
        // detailsselect={detailsselect}
        detailsselect1={detailsselect1}

        type={type2}
        modalIsOpen={modalIsOpen}
        handleCancel={handleClose}
      />
      </>
    );
  }
 
};

export default Modalcenter;
