import React, { useEffect, useState } from "react";
import { Avatar, Tooltip,message } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./ToolbarText.css";

import Comment from "@ant-design/compatible/lib/comment";
import {
  LikeOutlined,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
} from "@ant-design/icons";
import "./ToolbarText.css";
import io from "socket.io-client";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentsAsync,
  handelChangeCommentAsync,
  handelUpdateCommentAsync,
  updateCommenReplayAsync,
  updateCommentdisLikeAsync,
  updateCommentLikeAsync,
} from "../features/Comment/commentSlice";
import {
  addNotifcationsAsync,
} from "../features/Notification/NotificationSlice";
import Base_URL from "../app/api/apiSlice";
const socket = io(`${Base_URL}`);

const ExampleComment = ({
  children,
  comments,
  HandelUpadteLike,
  id,
  HandelUpadtedisLike,
  setReplyTo,
  replyTo,
  setIdComment
}) => {
  const [likes, setlikes] = useState(0);

  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);
  
  const like = () => {
    if (likes === 0) {
      setlikes(1);

      HandelUpadteLike(1, id);
      setAction("liked");
      setDislikes(0);

      HandelUpadtedisLike(0, id);
      setAction("disliked0");
    } else if (likes === 1) {
      setlikes(0);

      HandelUpadteLike(0, id);
      setAction("liked0");
    }
  };
  const dislike = () => {
    if (dislikes === 0) {
      setDislikes(1);

      HandelUpadtedisLike(1, id);
      setAction("disliked");
      setlikes(0);

      HandelUpadteLike(0, id);
      setAction("liked0");
    } else if (dislikes === 1) {
      setDislikes(0);

      HandelUpadtedisLike(0, id);
      setAction("disliked0");
    }
  };
  useEffect(() => {
    if (comments.like === 0) {
      setAction("liked");
    } else if (comments.like === 1) {
      setAction("liked0");
    }
    if (comments.dislike === 0) {
      setAction("disliked0");
    } else if (comments.dislike === 1) {
      setAction("disliked");
    }
  }, [comments]);
  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {React.createElement(action === "liked" ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{comments.like}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(
          action === "disliked" ? DislikeFilled : DislikeOutlined
        )}
        <span className="comment-action">{comments.dislike}</span>
      </span>
    </Tooltip>,

    // <span key="comment-basic-reply-to" onClick={()=>{
    //   replyTo[comments._id]=true
    //   setIdComment(comments._id)
    //   setReplyTo({...replyTo})
    // }}>Reply to</span>,


    
 
  ];
  return (
    <Comment
      actions={actions}
      author={
        <div className="nametextcomment">
          {comments.sender && comments.sender.Name}
        </div>
      }
      avatar={
        <Avatar>{comments.sender && comments.sender.Name.slice(0, 2)}</Avatar>
      }
      content={<p className="textcomment">{comments.content}</p>}
      datetime={
        <Tooltip title={`${comments.createdAt}`}>
          <span>{moment(comments.createdAt).calendar()}</span>
        </Tooltip>
      }
    >
      {children}
    </Comment>
  );
};
const ExampleComment1 = ({
  children,
  comments,
  HandelUpadteLike,
  id,
  HandelUpadtedisLike,
  setReplyTo,
  replyTo,
  setIdComment
}) => {
  const [likes, setlikes] = useState(0);

  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);
  
  const like = () => {
    if (likes === 0) {
      setlikes(1);

      HandelUpadteLike(1, id);
      setAction("liked");
      setDislikes(0);

      HandelUpadtedisLike(0, id);
      setAction("disliked0");
    } else if (likes === 1) {
      setlikes(0);

      HandelUpadteLike(0, id);
      setAction("liked0");
    }
  };
  const dislike = () => {
    if (dislikes === 0) {
      setDislikes(1);

      HandelUpadtedisLike(1, id);
      setAction("disliked");
      setlikes(0);

      HandelUpadteLike(0, id);
      setAction("liked0");
    } else if (dislikes === 1) {
      setDislikes(0);

      HandelUpadtedisLike(0, id);
      setAction("disliked0");
    }
  };
  useEffect(() => {
    if (comments.like === 0) {
      setAction("liked");
    } else if (comments.like === 1) {
      setAction("liked0");
    }
    if (comments.dislike === 0) {
      setAction("disliked0");
    } else if (comments.dislike === 1) {
      setAction("disliked");
    }
  }, [comments]);
  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {React.createElement(action === "liked" ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{comments.like}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(
          action === "disliked" ? DislikeFilled : DislikeOutlined
        )}
        <span className="comment-action">{comments.dislike}</span>
      </span>
    </Tooltip>,




    
 
  ];
  return (
    <Comment
      actions={actions}
      author={
        <div className="nametextcomment">
          {comments.sender && comments.sender.Name}
        </div>
      }
      avatar={
        <Avatar>{comments.sender && comments.sender.Name.slice(0, 2)}</Avatar>
      }
      content={<p className="textcomment">{comments.content}</p>}
      datetime={
        <Tooltip title={`${comments.createdAt}`}>
          <span>{moment(comments.createdAt).calendar()}</span>
        </Tooltip>
      }
    >
      {children}
    </Comment>
  );
};
const Comments1 = ({  idTask, label, responsable,ajouterPar }) => {
  const datacomments = useSelector((state) => state.comments.datacomments);
  const [replyTo, setReplyTo] = useState({});
  const [commentReplay,setCommentReplay]=useState("")
  const [idComment,setIdComment]=useState("")

  const dispatch = useDispatch();
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const comment = e.target.comment.value;
    dispatch(
      handelChangeCommentAsync({
        content: comment,
        replayComment: [],
        task: {
          _id: idTask,
        },
        sender: {
          _id: JSON.parse(localStorage.getItem("id")),
          Name: JSON.parse(localStorage.getItem("Name")),
        },
      })
    );
  
      if(JSON.parse(localStorage.getItem("role"))==="Admin" || JSON.parse(localStorage.getItem("role"))==="Developer"){  
        dispatch(
        addCommentsAsync({
          content: comment,
          createdAt: new Date(),
          replayComment: [],
          task: idTask,
          sender: JSON.parse(localStorage.getItem("id")),
      
  
        },
        responsable,
        JSON.parse(localStorage.getItem('id'))
        )
      );

      }else{ 
        dispatch(
          addCommentsAsync({
            content: comment,
            createdAt: new Date(),
            replayComment: [],
            task: idTask,
            sender: JSON.parse(localStorage.getItem("id")),
        
    
          },
          responsable,
          JSON.parse(localStorage.getItem("id")),
          )
        );
      }
  

 
    
        message.success("Commmaintaire added successfully");
    
      
    e.target.comment.value = "";
  };

  const HandelUpadteLike = (like, id) => {
    dispatch(updateCommentLikeAsync({ like: like, id: id }));
  };
  const HandelUpadtedisLike = (dislike, id) => {
    dispatch(updateCommentdisLikeAsync({ dislike: dislike, id: id }));
  };
  const handleReplay = (replay, id) => {

    dispatch(updateCommenReplayAsync({ replayComment: [replay], id: id }));
    replyTo[id]=false
    setReplyTo({...replyTo})

  };


  useEffect(() => {
    socket.on("update-comments", (comments) => {
      handelUpdateCommentAsync(comments);
    });
  }, [datacomments]);
  return (
    <>
      <div>
        {datacomments &&
          datacomments
            .filter((e) => e.task && e.task._id === idTask)
            .map((comment, index) => (
              <>
                <ExampleComment
                  comments={comment}
                  HandelUpadteLike={HandelUpadteLike}
                  HandelUpadtedisLike={HandelUpadtedisLike}
                  id={comment._id}
                  setReplyTo={setReplyTo}
                  replyTo={replyTo}
                  handleReplay={handleReplay}
                  setIdComment={setIdComment}
                >
                  {comment.replayComment && comment.replayComment.length>0 && (
                    <ExampleComment1                   comments={comment.replayComment[0]}
                    ></ExampleComment1>
                  )}
                </ExampleComment>
                <hr />
              </>
            ))}
               { replyTo &&  replyTo[idComment] ? (
      <div>
         <div className="inputcomment">
    <input
      type="text"
      name="comment" 
      value={commentReplay}
      placeholder="Enter your comment"
      className="toolbar"
      onChange={(e)=>setCommentReplay(e.target.value)}
    />
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        background: "#F8F8F8",
        height: "46px",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <div></div></div>
      {/* <Editor
editorState={editorState}
toolbarClassName="toolbarClassName"
wrapperClassName="wrapperClassName"
editorClassName="editorClassName"
onEditorStateChange={this.onEditorStateChange}
/> */}
{/* 
      <div style={{ marginRight: "20px" }} onClick={()=>handleReplay({
            content: commentReplay,
            sender :{id:`${JSON.parse(localStorage.getItem('id'))}`,Name:`${JSON.parse(localStorage.getItem('Name'))}`},
            createdAt: new Date(),
      },idComment)} >
        <button type="submit" className="buttonsend">
          Replay
        </button>
      </div> */}
    </div>
  </div>
    ):(
           <form onSubmit={handleSubmit} className="inputcomment">
          <input
            type="text"
            name="comment"
            placeholder="Enter your comment"
            className="toolbar"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              background: "#F8F8F8",
              height: "46px",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <div></div>
            {/* <Editor
  editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={this.onEditorStateChange}
/> */}

            <div style={{ marginRight: "20px" }}>
              <button type="submit" className="buttonsend">
                send
              </button>
            </div>
          </div>
        </form> 
    )}
  
      </div>
    </>
  );
};

export default Comments1;
