import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import Swal from "sweetalert2";
import Base_URL from "../../app/api/apiSlice";
import { addNotifcationsAsync } from "../Notification/NotificationSlice";
import { getUsersTeamMemberbydepartementAsync } from "../Users/userSlice";

const API_URL = `${Base_URL}/stasts`;
export const statsSlide = createSlice({
  name: "stasts",
  initialState: {
    datastasts: {},
    datastasts2: {},
    datataskstosend: {},
  },

  reducers: {
    getStats: (state, action) => {
      state.datastasts = { ...state.datastasts, ...action.payload };
    },
    getStats2: (state, action) => {
      state.datastasts2 = { ...state.datastasts2, ...action.payload };
    },
    handelChangeStats: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
  },
});
// const auth = JSON.parse(localStorage.getItem('token'));

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});

//get Tous les tasks
export const getStatsAsync = (data1, data2, data3) => async (dispatch) => {
  const data = {
    param1: JSON.parse(localStorage.getItem("id")),
    param2: data1,
    param3: data2,
    param4: data3,
  };
  api
    .get("/getStats", { params: data })
    .then((response) => {
      dispatch(getStats(response.data));
    })
    .catch((err) => console.log(err));
};
export const getStatsreviewAsync =
  (data1, data2, data3) => async (dispatch) => {
    const data = {
      param1: JSON.parse(localStorage.getItem("id")),
      param2: data1,
      param3: data2,
      param4: data3,
    };
    api
      .get("/getStatsreview", { params: data })
      .then((response) => {
        dispatch(getStats2(response.data));
      })
      .catch((err) => console.log(err));
  };
export const handelChangeStatsAsync = (e) => async (dispatch) => {
  try {
    dispatch(
      handelChangeStats({
        [e.target.name]: e.target.value,
      })
    );
  } catch (err) {
    console.log(err);
  }
};
export const { getStats, getStats2, handelChangeStats } = statsSlide.actions;
export default statsSlide.reducer;
