import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Base_URL from "../../app/api/apiSlice";

// const API_URL = "http://localhost:4003/auth";
const API_URL = `${Base_URL}/auth`;
export const authSlide = createSlice({
  name: "auth",
  initialState: {
    token: null,
    role: "",
    departement: "",
    stast: [],
    error: false,
    loading: false,
    data: {},
    validator: false,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getstats: (state, action) => {
      state.stast = action.payload;
    },
    sendPasswordlink: (state, action) => {
      state.data = action.payload;
    },
    verifylink: (state, action) => {
      state.validator = action.payload;
    },
    setCredentials: (state, action) => {
      const accessToken = action.payload.accessToken;
      const role = action.payload.role;
      // const  departement  = action.payload.Departement

      state.token = accessToken;
      state.role = role;
      // state.departement=departement
    },
    logOut: (state, action) => {
      state.token = null;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const sendPasswordlinkAsync = (data) => async (dispatch) => {
  api
    .post(`/sendLink`, data)
    .then((response) => {
      dispatch(sendPasswordlink(response.data));
      Swal.fire(response.data.message);
    })
    .catch((err) => {
      if (
        err.response.data.message === "User with given email does not exist!"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "User with given email does not exist!",
        });
      } else {
        console.log(err);
      }
    });
};
export const verifylinkAsync = (id) => async (dispatch) => {
  api
    .get(`/password-reset`, { params: { id: id } })
    .then((response) => {
      dispatch(verifylink(response.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updatePassewordAsync = (id, data) => async (dispatch) => {
  api
    .post(`/sendNewPassword`, { id: id, password: data })
    .then((response) => {
      dispatch(sendPasswordlink(response.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getstatsAsync =
  (startDate, monthend, dayend) => async (dispatch) => {
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(startDate.getDate()).padStart(2, "0");

    if (monthend === 0) {
      let formattedDate = `${year}-${month - monthend}-${day - dayend}`;
      let formattedDateend = `${year}-${month}-${day}`;
      api
        .get(`/login-stats`, {
          params: {
            startDate: formattedDate, // Specify the desired date range
            endDate: formattedDateend,
            id_departement: JSON.parse(localStorage.getItem("departement")),
          },
        })
        .then((response) => {
          dispatch(getstats(response.data.loginStatsfiltred));
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      let formattedDate = `${year}-${month - monthend}-${dayend}`;
      let formattedDateend = `${year}-${month}-${dayend + 30}`;
      api
        .get(`/login-stats`, {
          params: {
            startDate: formattedDate, // Specify the desired date range
            endDate: formattedDateend,
            id_departement: JSON.parse(localStorage.getItem("departement")),
          },
        })
        .then((response) => {
          dispatch(getstats(response.data.loginStatsfiltred));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    // Concatenate the components to get the desired format: yy_mm_dd
  };
export const loginAsync = (data, e) => async (dispatch) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!data.Email.match(validRegex)) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "email Invalid",
    });
  } else {
    api
      .post(`/`, data)
      .then((response) => {
        if (response.data.message === "email and password Invalid") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "email and password Invalid",
          });
        } else if (response.data.message === "Invalid Email or password") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "email and password Invalid",
          });
        } else if (
          response.data.message === "email and password are required."
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "email and password are required.",
          });
        } else {
          toast.success("Log In successfully");
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.accessToken)
          );
          localStorage.setItem("role", JSON.stringify(response.data.role));
          // localStorage.setItem("departement", JSON.stringify(response.data.Departement))

          localStorage.setItem("Name", JSON.stringify(response.data.Name));
          localStorage.setItem("Email", JSON.stringify(response.data.Email));
          localStorage.setItem("id", JSON.stringify(response.data._id));
          localStorage.setItem(
            "description",
            JSON.stringify(response.data.description)
          );
          localStorage.setItem(
            "departement",
            JSON.stringify(response.data.departement._id)
          );

          response.data.images[0] &&
            localStorage.setItem(
              "imageurl",
              JSON.stringify(response.data.images[0].path)
            );

          dispatch(setCredentials(response.data));
        }
      })
      .catch((err) => {
        if (err.response.data.message === "user unauthorized") {
          Swal.fire("unauthorized");
        }
        console.log("err", err);
      });
  }
};
export const loginPentaverse = (token) => (dispatch) => {
  api
    .post(`/pentaverselogin`, { token })
    .then((response) => {
      toast.success("Log In successfully");
      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("role", JSON.stringify(response.data.role));
      // localStorage.setItem("departement", JSON.stringify(response.data.Departement))

      localStorage.setItem("Name", JSON.stringify(response.data.Name));
      localStorage.setItem("Email", JSON.stringify(response.data.Email));
      localStorage.setItem("id", JSON.stringify(response.data._id));
      localStorage.setItem(
        "description",
        JSON.stringify(response.data.description)
      );
      localStorage.setItem(
        "departement",
        JSON.stringify(response.data.departement._id)
      );

      response.data.images[0] &&
        localStorage.setItem(
          "imageurl",
          JSON.stringify(response.data.images[0].path)
        );
      // await axios.po
      dispatch(setCredentials(response.data));
    })
    .catch((err) => {
      console.log(err.response);
      Swal.fire(err.response.message);
      console.log("err", err);
    });
};
export const logoutAsync = () => async (dispatch) => {
  api
    .post(`/logout`)
    .then((response) => {
      dispatch(logOut(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const resfreshAsync = () => async (dispatch) => {
  api
    .get(`/refresh`)
    .then((response) => {
      dispatch(setCredentials(response.data));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const {
  setCredentials,
  logOut,
  setError,
  getstats,
  sendPasswordlink,
  verifylink,
} = authSlide.actions;
export default authSlide.reducer;
export const selectCurrentToken = (state) => state.auth.token;
