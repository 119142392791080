import React ,{useEffect, useState} from 'react';
import { FilePdfOutlined, FileWordOutlined, FolderViewOutlined, UploadOutlined } from '@ant-design/icons';

import { Form, Button,DatePicker ,Select  , Upload,Switch} from 'antd';
import './Form.css'
import { useDispatch, useSelector } from 'react-redux';
import { addProjectAsync, getProjectsbydepartementAsync, handelChangeAsync, handelChangeColorAsync, handelChangeDateAsync, handelChangeTeammemberAsync, updateNameProjectAsync } from '../features/Projects/projectSlice';
import {  getDeparementbyUserloginAsync, getUsersAsync, getUsersLeadAsync, getUsersTeamMemberAsync, getUsersTeamMemberbydepartementAsync } from '../features/Users/userSlice';
import { handelChangeResponsableAsync, restDataAsync } from '../features/Tasks/TaskSlice';

import PaletteColor from './PaletteColor';


const Form2 = ({disable,handleCancel,detaisdata}) => {
    const [formLayout]=useState('vertical')
    const [infoList, setInfoList] = useState([]);
    const [size, setSize] = useState('middle');
    const [transformedData, setTransformedData] = useState([]);

    const handleSizeChange = (e) => {
      setSize(e.target.value);
    };
    const dispatch=useDispatch()
    const dataProjectLead = useSelector((state) => state.users.dataProjectLead);
    const dataTeamMemberbydepartement = useSelector((state) => state.users.dataTeamMemberbydepartement);
    const IdDepartement = useSelector((state) => state.users.IdDepartement);

    const datatosend = useSelector((state) => state.projects.datatosend);
    const dataprojectsbydepartement = useSelector((state) => state.projects.dataprojectsbydepartement);

      const formItemLayout =
      formLayout === 'vertical'
        ? {
            labelCol: { span: 24 },
            wrapperCol: { span: 14 },
          }
        : null;
    const buttonItemLayout =
      formLayout === 'vertical'
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : null;
        
        const onSearch = (value) => {
          console.log('search:', value);
        };
        const handleChange = (value) => {
         dispatch(handelChangeTeammemberAsync(value))
        };
        useEffect(()=>{
          dispatch(getUsersAsync())
          dispatch(getUsersTeamMemberbydepartementAsync(JSON.parse(localStorage.getItem('departement'))))
          dispatch(getDeparementbyUserloginAsync(JSON.parse(localStorage.getItem('id'))))
        },[dispatch])
           
  
        const handelSubmit=()=>{
          if (!disable)  {
            handleCancel()
             dispatch(addProjectAsync({...datatosend,images:infoList,id_departement:JSON.parse(localStorage.getItem('departement'))}))
            //  dispatch(addNotifcationsAsync({Name:`${JSON.parse(localStorage.getItem('Name'))} add new project`,sender:JSON.parse(localStorage.getItem('id')),recived:"63f4822d62c185ff336c7614",type:"project"}))
   
             dispatch(restDataAsync())
          }else {
            dispatch(updateNameProjectAsync({...datatosend,Name :datatosend.Name,id:detaisdata._id,TeamMember:datatosend.TeamMember}))
            handleCancel()
          }
        
        }
        const options = [];
        for (let i = 10; i < 36; i++) {
          options.push({
            value: i.toString(36) + i,
            label: i.toString(36) + i,
          });
        }
        useEffect(()=>{
          const transformedData = dataTeamMemberbydepartement.map(item => ({
            id:item._id,
            label: item.Name,
            value: item._id,
          }));

          setTransformedData(transformedData)
         
        },[dataTeamMemberbydepartement])
  return (
    <div style={{marginTop:"18px"}}>
    <Form layout={formLayout} 
       
        >
     
      <Form.Item label="Name the Project"  {...formItemLayout}>
        <input  className='inputfiledaddticket' placeholder={disable ? detaisdata.Name:""} disabled={JSON.parse(localStorage.getItem('role'))!=="Admin"} name="Name" onChange={(e)=>
          dispatch(handelChangeAsync(e))}/>
      </Form.Item>
      <Form.Item label="project descritpion" {...formItemLayout}>
      <textarea  className='inputfiledaddticket' placeholder={disable ? detaisdata.Description:""} disabled={disable} name="Description" onChange={(e)=>dispatch(handelChangeAsync(e))}></textarea>
      </Form.Item>
   
      <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}>
      <Form.Item label="Choose project lead" {...formItemLayout} style={{width:"50%"}}>
   
   <select   disabled={(detaisdata && detaisdata.Name === 'other') ?false:disable}
 showSearch
 placeholder={disable ? detaisdata && detaisdata.ProjectLeadName &&detaisdata.ProjectLeadName.Name:""}
 name='ProjectLeadName'
 className='selectProjectLead'
 optionFilterProp="children"
 onChange={(e)=>dispatch(handelChangeAsync(e))}
 onSearch={onSearch}
 filterOption={(input, option) =>
   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
 }>
   <option selected disabled>{disable ? detaisdata && detaisdata.ProjectLeadName && detaisdata.ProjectLeadName.Name:"Project Lead Name"}</option>
       {dataProjectLead && dataProjectLead.map((e)=>(
<> 
 <option value={`${e._id}`}>{e.Name}</option></>
       ))}
</select>
   </Form.Item>
      <Form.Item
             label="Team members"
             {...formItemLayout}
            
           >
           <Select
          mode="multiple"
          size={size}
         placeholder={disable ? detaisdata && detaisdata.TeamMember &&detaisdata.TeamMember.map((e)=>e.Name):"Please select"}
         className={detaisdata &&  (detaisdata.status==="done")?"selectProjectLeaddisable":"selectProjectLead"}

           //defaultValue={disable ? detaisdata && detaisdata.TeamMember.map((e)=>e.Name):[]}
          onChange={handleChange}
          style={{
            width: '150%',
          }}
          options={transformedData}
        />
            {/* <select
      style={{ width: 500 ,height:'fit-content !important'}}
      
      multiple
      className={detaisdata &&  (detaisdata.status==="done")?"selectProjectLeaddisable":"selectProjectLead"}
      disabled={detaisdata && detaisdata.status==="done"}
      name='TeamMember'
      onChange={(e)=>dispatch(handelChangeAsync(e))}
      placeholder={disable
        ? 
       `${detaisdata.ProjectLeadName.Name}`
         : "----Project Lead name----" }
      tokenSeparators={[" ", ","]}
      defaultValue={detaisdata && detaisdata.responsable&&
        detaisdata.responsable.filter(
          (e1) => e1.id_role.Name !== "Admin"
        ).map((e1)=>e1._id)}
    >
      <option selected disabled>   {disable?
                              `${detaisdata.TeamMember.Name}`
                         : "----Team member name----"}</option>
       {dataTeamMemberbydepartement &&
                 dataTeamMemberbydepartement.map((e) => (
                   <>  <option  value={`${e._id}`}  >
        <span > {
                            e.Name
                           
                       }</span>
      </option>
                   </>
        ))}
    
    </select>
          */}
           </Form.Item>
    </div>
     
      <div style={{display:"flex",justifyContent:"space-between",width:"529px"}}>
      <Form.Item label="Attached Logo of Project" {...formItemLayout}  style={{width:"50%"}}>
      {disable ? (
        <div>
      {
      detaisdata && detaisdata.files && detaisdata.files.map((e) => (
                 <div
                   style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     marginLeft:"86px",
                   }}
                 >
                
                   <div
              style={{
                display: "flex",
                flexDirection: "row",
                minHeight: "100px",
                width:"100%",
                height: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                  { (e.fileName &&  e.fileName.split('.')[1]==='pdf' ) ?
                  (<a href={`${e.path}`}  target="_blank" rel="noreferrer"><FilePdfOutlined   style={{color:'red',fontSize: '200%'}} size={40}/></a>) : 
          (e.fileName.split('.')[1] ==='docx') ?
         (<a href={`${e.path}`} target="_blank" rel="noreferrer"><FileWordOutlined   style={{color:'blue',fontSize: '200%'}} size={40} /></a>) :
         (<a href={`${e.path}`} target="_blank" rel="noreferrer"><FolderViewOutlined    style={{fontSize: '200%'}} size={40}/></a>) 
          }
           </div>   
            <div>
                   </div>
                   <div
                     style={{
                       display: "flex",
                       flexDirection: "column",
                       alignItems: "center",
                     }}
                   >
                     <a href={`${e.path}`}>
                       {e.fileName}{" "}
                     </a>
                   </div>
                 </div>
               ))}
        
           
        </div>
             
          ):(
            <Upload
            name="file"
            fileList={infoList}
            onChange={({ fileList }) => {
            
                setInfoList(fileList)
              


            }}
            multiple
          >
            <Button
              className="attaachfile"
              icon={<UploadOutlined />}
              style={{ borderColor:infoList.length >0 && "green"}}

            >
              {" "}
              Attach File
            </Button>
          </Upload>
          )}
      
      </Form.Item>
      <Form.Item label="Color" {...formItemLayout}  style={{width:"50%"}}>
        <div style={{display:"flex",flexWrap:"wrap"}}>
        <PaletteColor detaisdata ={detaisdata}

    disable={disable}/>    
        </div>
  
      
      </Form.Item>
      </div>
      
 {!disable &&(
  <>
<div style={{display:"flex",justifyContent:"space-between"}}>
          <Form.Item label="Activate Notifications" {...formItemLayout}  style={{width:"50%"}}>
      
      </Form.Item>
      <div style={{marginRight:"30px"}}><Switch defaultChecked  onChange={(e)=>dispatch(handelChangeAsync(e))}/></div>
      
      </div></> )}{(JSON.parse(localStorage.getItem('role')) === "Admin" ) &&
      <Form.Item {...buttonItemLayout} style={{display:"flex",justifyContent:"center"}}>
        <button className='buutonsaveaddticket' onClick={handelSubmit}
        
      
    >
    {(disable && JSON.parse(localStorage.getItem('role')) === "Admin"  )? "Modifier Project":"Save"}  
          </button>
          <div className='buutoncanceladdticket' onClick={()=>handleCancel()}>
          Cancel
          </div>
      </Form.Item>
}
      
    </Form>
  </div>
  )
}

export default Form2