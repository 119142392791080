import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import Swal from "sweetalert2";
import Base_URL from "../../app/api/apiSlice";
import { addNotifcationsAsync } from "../Notification/NotificationSlice";
import { getUsersTeamMemberbydepartementAsync } from "../Users/userSlice";

const API_URL = `${Base_URL}/tasks`;
export const tasksSlide = createSlice({
  name: "tasks",
  initialState: {
    datatasks: [],
    datataskscalander: [],
    data: {},

    alldata: {},
    alldataourdepartemnt: {},
    ourdepartementtaskP: {},

    alldatadepartementexterne: {},

    error: false,
    loading: false,

    datataskstosend: {
      responsable: [],
      DateCreation: "",
    },
  },

  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    addTask: (state, action) => {
        state.datataskstosend=action.payload
       
    },
    deleteTask: (state, action) => {
      state.data[action.payload.status] = state.data[
        action.payload.status
      ].filter((e) => e._id !== action.payload._id);
      state.alldata[action.payload.status] = state.alldata[
        action.payload.status
      ].filter((e) => e._id !== action.payload._id);
    },

    getTasks: (state, action) => {
      state.alldata = { ...state.alldata, ...action.payload };

      const ourdepartementtask = {
        ...state.data,
        ...{
          todo: [
            ...action.payload.newdata,
            ...action.payload.newdataex.filter((e) => e.departementExterne),
          ],
          doing: [
            ...action.payload.newdata1,
            ...action.payload.newdata1ex.filter((e) => e.departementExterne),
          ],
          done: [
            ...action.payload.newdata2,
            ...action.payload.newdata2ex.filter((e) => e.departementExterne),
          ],
        },
      };
      const ourdepartementtaskP = {
        ...state.data,
        ...{
          todo: action.payload.newdatap,
          doing: action.payload.newdata1p,
          done: action.payload.newdata2p,
        },
      };
      const departementExterneTasks = {
        ...state.data,
        ...{
          todo: action.payload.newdataex.filter((e) => e.departementExterne),
          doing: action.payload.newdata1ex.filter((e) => e.departementExterne),
          done: action.payload.newdata2ex.filter((e) => e.departementExterne),
        },
      };
      state.loading = false;
      state.error = false;

      if (JSON.parse(localStorage.getItem("role")) === "Admin") {
        state.alldataourdepartemnt = {
          ...state.alldataourdepartemnt,
          ...ourdepartementtask,
        };
      } else {
        state.alldataourdepartemnt = {
          ...state.ourdepartementtaskP,
          ...ourdepartementtaskP,
        };
      }

      state.alldatadepartementexterne = {
        ...state.alldatadepartementexterne,
        ...departementExterneTasks,
      };
      state.data = {
        ...state.data,
        ...{
          todo: action.payload.newdata,
          doing: action.payload.newdata1,
          done: action.payload.newdata2,
          taskThismonth: {
            todo: action.payload.newdata3,
            doing: action.payload.newdata5,
            done: action.payload.newdata4,
          },
          tasknextmonth: {
            todo: action.payload.newdata6,
            doing: action.payload.newdata8,
            done: action.payload.newdata7,
          },
          taskOnAttendDate: {
            todo: action.payload.newdata9,
            done: action.payload.newdata10,
            doing: action.payload.newdata11,
          },
        },
      };
    },

    getTaskscalander: (state, action) => {
      state.loading = false;
      state.error = false;
      state.datataskscalander = action.payload;
    },
    changeData: (state, action) => {
      state.alldata = action.payload;
      state.data = action.payload;
    },
    handelChange: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
    handelChangeTeammeber: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
    handelChangeProject: (state, action) => {
      if (action.payload.id_project === "other") {
        state.datataskstosend = {
          ...state.datataskstosend,
          id_project: "other",
        };
      } else {
        state.datataskstosend = { ...state.datataskstosend, ...action.payload };
      }
    },
    resetData: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
    handelChangeResponsable: (state, action) => {
      state.datataskstosend = {
        ...state.datataskstosend,
        responsable: action.payload,
      };
    },
    handelChangeDate: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
    handelChangeTime: (state, action) => {
      state.datataskstosend = { ...state.datataskstosend, ...action.payload };
    },
    setError: (state) => {
      state.error = true;
    },
  },
});
// const auth = JSON.parse(localStorage.getItem('token'));

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});
const api2 = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});

//get Tous les tasks
export const getTasksAsync = (id, page) => async (dispatch) => {
  const data = {
    param1: id,
    page: page,
  };
  api
    .get("/getTask", { params: data })
    .then((response) => {
      dispatch(getTasks(response.data));
    })
    .catch((err) => console.log(err));
};
export const getTasksTodayAsync = () => async (dispatch) => {
  api
    .get("/getTaskToday")
    .then((response) => {
      dispatch(getTasks(response.data));
    })
    .catch((err) => console.log(err));
};
export const getTasksArchivedAsync = () => async (dispatch) => {
  const data = {
    param1: JSON.parse(localStorage.getItem("id")),
  };
  api
    .get("/getArchivedDataTask", { params: data })
    .then((response) => {
      dispatch(getTasks(response.data));
    })
    .catch((err) => console.log(err));
};

export const getTasksByfilterAsync = (id) => async (dispatch) => {
  api.get("/gebytTask", id).then((response) => {
    dispatch(getTasks(response.data));
  });
};
export const getTaskscalanderAsync = (data) => async (dispatch) => {
  api.get("/getTaskCalander", { params: { id: data } }).then((response) => {
    dispatch(getTaskscalander(response.data));
  });
};
//add un seul task
export const changeDataAsync = (e) => async (dispatch) => {
  try {
    dispatch(changeData(e));
  } catch (err) {
    dispatch(setError());
  }
};
export const addTaskAsync = (data) => (dispatch) => {
  const formData = new FormData();
  for (var i = 0; i < data.responsable.length; i++) {
    if (data.responsable[i] !== "undefined") {
      formData.append("responsable", data.responsable[i]);
    }
  }
  for (var j = 0; j < data.infoList.length; j++) {
    formData.append("files", data.infoList[j].originFileObj);
  }

  formData.append("title", data.title);

  formData.append("label", data.label);
  formData.append("id_project", data.id_project);
  formData.append("newproject", data.newproject);
  formData.append("DescriptionProject", data.DescriptionProject);
  formData.append("id_departements", data.id_departements);
  formData.append("TeamMember", data.TeamMember);

  formData.append("id", data.id);
  formData.append("Priority", data.Priority);
  formData.append("HeureCreation", data.HeureCreation);
  formData.append("DateCreation", data.DateCreation);
  formData.append("ajouterPar", data.ajouterPar);
  formData.append("departementExterne", data.departementExterne);
  formData.append("status", "todo");
  formData.append("activiatenotifcation", false);
  formData.append("type", "task");

  api2
    .post(`/addTask`, formData)
    .then((response) => {
      
      // dispatch(
      //   addNotifcationsAsync({
      //     Name: `${JSON.parse(localStorage.getItem("Name"))} add new task`,
      //     sender: JSON.parse(localStorage.getItem("id")),
      //     recived: response.data.responsable,
      //     type: "add task",
      //   })
      // );
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
  
    })
    .catch((err) => {
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
      console.log(err);
      // Swal.fire("all filed required");
    });
};
export const restdataAsync=()=>(dispatch)=>{

  dispatch(addTask({
    responsable: [],
    DateCreation: "",
  }
))
}
export const UpdateFileTaskAsync = (data) => async (dispatch) => {
  const formData = new FormData();

  for (var j = 0; j < data.infoList.length; j++) {
    formData.append("files", data.infoList[j].originFileObj);
  }
  formData.append("id", data.id);

  api
    .put(`/UpdateFileTask`, formData)
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const updateTaskAsync = (data) => async (dispatch) => {
  api
    .put(`/updateTask`, {
      ...data,
      userconncted: JSON.parse(localStorage.getItem("id")),
    })
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));

      // const array = [...response.data.responsable, response.data.ajouterPar];
      // if (response.data.status === "done") {
      //   dispatch(
      //     addNotifcationsAsync({
      //       Name: `${JSON.parse(localStorage.getItem("Name"))} update  Your Task <br/>
      //       Task Name :
      //       ${
      //         response.data.title
      //       }<br/>
      //        Project Name : ${response.data.id_project.Name} <br /> `,
      //       sender: JSON.parse(localStorage.getItem("id")),
      //       recived: array.filter(
      //         (e) => e !== JSON.parse(localStorage.getItem("id"))
      //       ),
      //       type: "update task",
      //     })
      //   );
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateResponsableTaskAsync = (data) => async (dispatch) => {
  Swal.fire({
    title: "Do you want to save the changes?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "Save",
    denyButtonText: `Don't save`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire("Responsable deleted Saved!", "", "success");
      api
        .put(`/updateResponsableTask`, data)
        .then((response) => {
          dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
        })
        .catch((err) => {
          if (err.response.data.message) {
            alert(err.response.data.message);
          } else {
            dispatch(setError());
          }
        });
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};
export const updateDateTaskAsync = (data) => async (dispatch) => {
  api
    .put(`/updateDateTask`, data)
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));

      // dispatch(
      //   addNotifcationsAsync({
      //     Name: `${JSON.parse(localStorage.getItem("Name"))} update your  Task  :<br/>
      //     ${response.data.Name} `,
      //     sender: JSON.parse(localStorage.getItem("id")),
      //     recived: [...response.data.responsable, response.data.ajouterPar],
      //     type: "update task",
      //   })
      // );
    })
    .catch((err) => {
      console.log(err);
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};

export const deleteTaskAsync = (data) => async (dispatch) => {
  api
    .delete(`/deleteTask`, { data: { id: data } })
    .then((response) => {
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
//remlpir formulaire add tasks
export const handelChangeAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChange({ [e.target.name]: e.target.value }));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeTeammeberAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChangeTeammeber({ TeamMember: e }));
  } catch (err) {
    dispatch(setError());
  }
};
export const restDataAsync = () => async (dispatch) => {
  try {
    dispatch(resetData({ Name: "", Description: "" }));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeProjectAsync = (e) => async (dispatch) => {
  try {
    const array = e.target.value.split(":");

    dispatch(
      handelChangeProject({
        [e.target.name]: array[0],
        responsable: array.slice(1),
      })
    );
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeResponsableAsync =
  (dataselected) => async (dispatch) => {
    try {
      // ResponsableArray.filter((e1)=>e1._id!)
      //     const newObj=[]
      //  newObj.push(dataselected)
      //    console.log(newObj);

      // detaisdata.responsable.map(
      //   (e1) => e1.id_role.Name !== "Admin" && e1.Name
      // )
      console.log(dataselected);
      dispatch(handelChangeResponsable(dataselected));
    } catch (err) {
      console.log(err);
      dispatch(setError());
    }
  };
export const handelChangeDateAsync =
  (date, dateString, abc) => async (dispatch) => {
    try {
      dispatch(handelChangeDate({ DateCreation: dateString }));
    } catch (err) {
      dispatch(setError());
    }
  };
export const handelChangeTimeAsync =
  (time, timeString, abc) => async (dispatch) => {
    try {
      dispatch(handelChangeTime({ HeureCreation: timeString }));
    } catch (err) {
      dispatch(setError());
    }
  };

export const {
  addTask,
  getTasks,
  resetData,
  getTaskscalander,
  handelChange,
  setError,
  setLoading,
  deleteTask,
  handelChangeDate,
  handelChangeTime,
  changeData,
  handelChangeProject,
  handelChangeResponsable,
  getcombineTasks,
  handelChangeTeammeber,
} = tasksSlide.actions;
export default tasksSlide.reducer;
