import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";
import "./Modal.css";
import { Form, Upload, Button, Radio } from "antd";
import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { CountryDropdown } from "react-country-region-selector";
import "./Modal.css";
import close from "../../assets/x.png";
import {
  addJobPostAsync,
  getaddJobPostAsync,
  handelChangeAsync,
  handelChangeselectAsync,
  updateallJobPostAsync,
  updateJobPostAsync,
  updateJobPostLinkAsync,
} from "../../features/JobPost/jobpostSlice";
import {
  getTasksAsync,
  handelChangeResponsableAsync,
} from "../../features/Tasks/TaskSlice";
import {
  getUsersByroleAdminAsync,
  getUsersByroleAsync,
} from "../../features/Users/userSlice";
const Modal2 = ({
  title,
  disable,
  modalIsOpen,
  handleCancel,
  role,
  details,
  update,
}) => {
  const [formLayout] = useState("vertical");
  const [infoList, setInfoList] = useState([]);
  const [infoList2, setInfoList2] = useState([]);

  const [value, setValue] = React.useState(null);
  const [filedvalue, setfiledvalue] = React.useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);
  const [isCopied3, setIsCopied3] = useState(false);
  const [isCopied4, setIsCopied4] = useState(false);
  const [isCopied5, setIsCopied5] = useState(false);
  const [valueEmail, setValueEmail] = useState("Email");

  const dispatch = useDispatch();
  const datatosend = useSelector((state) => state.jobposts.datatosend);
  const dataOnebyrole = useSelector((state) => state.users.dataOnebyrole);
  const dataOnebyroleAdmin = useSelector(
    (state) => state.users.dataOnebyroleAdmin
  );
  const alldataourdepartemnt = useSelector(
    (state) => state.tasks.alldataourdepartemnt
  );
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        }
      : null;
  const buttonItemLayout =
    formLayout === "vertical"
      ? {
          wrapperCol: { span: 24, offset: 4 },
        }
      : null;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onChange = (e) => {
    setValueEmail(e.target.value);
  };
  useEffect(() => {
    dispatch(getUsersByroleAsync("community manager"));
    dispatch(getUsersByroleAdminAsync("Admin"));
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))));
    dispatch(getaddJobPostAsync());
  }, []);

  const handelSubmit = () => {
    if (role === "Recrutement" && !update) {
      if (
        isValidEmail(datatosend.RecruiterEmail) ||
        datatosend.RecruiterLien !== undefined
      ) {
        if (filedvalue === "Autres") {
          if (datatosend.filed !== undefined) {
            Swal.fire({
              title: "Do you want to add this Job post?",
              showDenyButton: true,
              confirmButtonText: "Yes",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire(
                  "Job Post  added avec sucess!",
                  "",
                  "task delete avec sucess",
                  [dataOnebyroleAdmin._id, dataOnebyrole._id]
                );
                dispatch(
                  addJobPostAsync({
                    ...datatosend,
                    id: `qwe${
                      alldataourdepartemnt.todo.length +
                      alldataourdepartemnt.doing.length +
                      alldataourdepartemnt.done.length
                    }`,
                    label: `JobPost-${
                      alldataourdepartemnt.todo.length +
                      alldataourdepartemnt.doing.length +
                      alldataourdepartemnt.done.length
                    }`,
                    responsable: [dataOnebyroleAdmin._id, dataOnebyrole._id],
                    ajouterPar: `${JSON.parse(localStorage.getItem("id"))}`,
                    files: infoList,
                  })
                );
                handleCancel();
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
          } else {
            Swal.fire("Choice your other filed");
          }
        } else {
          Swal.fire({
            title: "Do you want to add this Job post?",
            showDenyButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire(
                "Job Post  added avec sucess!",
                "",
                "task delete avec sucess"
              );
              dispatch(
                addJobPostAsync({
                  ...datatosend,
                  id: `qwe${
                    alldataourdepartemnt.todo.length +
                    alldataourdepartemnt.doing.length +
                    alldataourdepartemnt.done.length
                  }`,
                  label: `JobPost-${
                    alldataourdepartemnt.todo.length +
                    alldataourdepartemnt.doing.length +
                    alldataourdepartemnt.done.length
                  }`,
                  responsable: [dataOnebyroleAdmin._id, dataOnebyrole._id],
                  ajouterPar: `${JSON.parse(localStorage.getItem("id"))}`,
                  filed: filedvalue,
                  files: infoList,
                })
              );
              handleCancel();
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
        }
      } else {
        Swal.fire("RECRUITER EMAIL format  non valide");
      }
    } else if (
      JSON.parse(localStorage.getItem("description")) ===
      "Chief Recruitement Officer"
    ) {
      if (
        isValidEmail(datatosend.RecruiterEmail) ||
        datatosend.RecruiterLien !== undefined
      ) {
        if (filedvalue === "Autres") {
          if (datatosend.filed !== undefined) {
            Swal.fire({
              title: "Do you want to add this Job post?",
              showDenyButton: true,
              confirmButtonText: "Yes",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire(
                  "Job Post  added avec sucess!",
                  "",
                  "task delete avec sucess"
                );
                dispatch(
                  addJobPostAsync({
                    ...datatosend,
                    id: `qwe${
                      alldataourdepartemnt.todo.length +
                      alldataourdepartemnt.doing.length +
                      alldataourdepartemnt.done.length
                    }`,
                    label: `JobPost-${
                      alldataourdepartemnt.todo.length +
                      alldataourdepartemnt.doing.length +
                      alldataourdepartemnt.done.length
                    }`,
                    responsable: [dataOnebyroleAdmin._id, dataOnebyrole._id],
                    ajouterPar: `${JSON.parse(localStorage.getItem("id"))}`,
                    files: infoList,
                  })
                );
                handleCancel();
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
          }
        } else {
          Swal.fire({
            title: "Do you want to add this Job post?",
            showDenyButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire(
                "Job Post  added avec sucess!",
                "",
                "task delete avec sucess"
              );

              dispatch(
                addJobPostAsync({
                  ...datatosend,
                  id: `qwe${
                    alldataourdepartemnt.todo.length +
                    alldataourdepartemnt.doing.length +
                    alldataourdepartemnt.done.length
                  }`,
                  label: `JobPost-${
                    alldataourdepartemnt.todo.length +
                    alldataourdepartemnt.doing.length +
                    alldataourdepartemnt.done.length
                  }`,
                  responsable: [dataOnebyroleAdmin._id, dataOnebyrole._id],
                  ajouterPar: `${JSON.parse(localStorage.getItem("id"))}`,
                  filed: filedvalue,
                  files: infoList,
                })
              );

              handleCancel();
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
        }
      } else {
        Swal.fire("RECRUITER EMAIL format  non valide");
      }
    } else if (role === "Recrutement" && update) {
      dispatch(updateallJobPostAsync({ ...datatosend, id: details._id }));
    } else {
      dispatch(
        updateJobPostLinkAsync({
          id: details._id,
          ...datatosend,
          filescomminty: infoList2,
        })
      );

      handleCancel();
    }
  };
  const handleDownload = async (filename, urlPDF) => {
    var a = document.createElement("a");
    const response = await fetch(urlPDF);
    const blob = await response.blob();
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.description)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyLinkClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.link)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied2(true);
          setTimeout(() => {
            setIsCopied2(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyJobTitleClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.title)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied3(true);
          setTimeout(() => {
            setIsCopied3(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyRecutierEmailClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      if (details.RecruiterEmail === "undefined") {
        copyTextToClipboard(details.RecruiterLien)
          .then(() => {
            // If successful, update the isCopied state value
            setIsCopied4(true);
            setTimeout(() => {
              setIsCopied4(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        copyTextToClipboard(details.RecruiterEmail)
          .then(() => {
            // If successful, update the isCopied state value
            setIsCopied4(true);
            setTimeout(() => {
              setIsCopied4(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handleCopyRecutierLienClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.RecruiterLien)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied5(true);
          setTimeout(() => {
            setIsCopied5(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyRecutierNameClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.ajouterPar.Name)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied4(true);
          setTimeout(() => {
            setIsCopied4(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCopyWorkColactionClick = () => {
    // Asynchronously call copyTextToClipboard
    if (update) {
      copyTextToClipboard(details.location)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied5(true);
          setTimeout(() => {
            setIsCopied5(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="app">
      <div className="darkBG" onClick={handleCancel} />
      <div className="centered">
        <div className="contenttittle">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="titlePostForm">Job Post Form</div>
            <div>
              <img src={close} onClick={handleCancel} />
            </div>
          </div>
          <Form layout={formLayout} style={{ width: "100%", marginTop: "2%" }}>
            <Form.Item
              label="Job title"
              {...formItemLayout}
              style={{ width: "100%" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  className="inputfiledjob"
                  name="title"
                  placeholder={update ? details.title : ""}
                  // disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                />
                {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyJobTitleClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )}
              </div>
            </Form.Item>

            <Form.Item label="Job description " {...formItemLayout}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <textarea
                  className="inputfiledjob"
                  name="description"
                  placeholder={update ? details.description : ""}
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                ></textarea>
                {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )}
              </div>
            </Form.Item>

            <div style={{ display: "flex", width: "100%" }}>
              <Form.Item
                label="Field "
                {...formItemLayout}
                style={{ width: "fit-content" }}
              >
                <select
                  showSearch
                  placeholder={update ? details.filed : "Select a field"}
                  name="field"
                  className="selectProjectLeadjob"
                  optionFilterProp="children"
                  //  disabled={role!=="Recrutement"}
                  onChange={(e) => {
                    setfiledvalue(e.target.value);
                  }}
                >
                  <option selected disabled>
                    {" "}
                    {update ? details.filed : "Select a field"}
                  </option>
                  <option value="Oil & Gas">Oil & Gas</option>
                  <option value="IT Telecom"> IT Telecom</option>
                  <option value="Energy"> Energy</option>
                  <option value="Transportation"> Transportation</option>

                  <option value="Autres">New filed</option>
                </select>
              </Form.Item>
              {filedvalue === "Autres" && (
                <Form.Item
                  label="Autre Field"
                  {...formItemLayout}
                  style={{ marginLeft: "5%", width: "fit-content" }}
                >
                  <input
                    className="inputfiledjob"
                    name="filed"
                    onChange={(e) => dispatch(handelChangeAsync(e))}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Language "
                {...formItemLayout}
                style={{ width: "fit-content", marginLeft: "5%" }}
              >
                <select
                  //  disabled={role!=="Recrutement"}
                  showSearch
                  placeholder={update ? details.language : "Select a country"}
                  name="Language"
                  className="selectProjectLeadjob"
                  optionFilterProp="children"
                  onChange={(e) => dispatch(handelChangeAsync(e))}
                >
                  <option selected disabled>
                    {update ? details.language : "Select a Language"}
                  </option>
                  <option value={"French"}>French</option>
                  <option value={"English"}>English</option>
                  <option value={"Both"}>Both</option>
                </select>
              </Form.Item>
              <Form.Item
                label="Attach File"
                {...formItemLayout}
                style={{ width: "25%", marginLeft: "5%" }}
              >
                {role === "Recrutement" ||
                (localStorage.getItem("description") !== undefined &&
                  JSON.parse(localStorage.getItem("description")) ===
                    "Chief Recruitement Officer") ? (
                  <div
                    style={{
                      display: "flex",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <Upload
                      name="file"
                      fileList={infoList}
                      onChange={({ fileList }) => {
                        setInfoList(fileList);
                      }}
                      multiple
                    >
                      <Button
                        style={{ borderColor: infoList.length > 0 && "green" }}
                        className="attaachfile"
                        icon={<UploadOutlined />}
                      >
                        {" "}
                        Attach File
                      </Button>
                    </Upload>
                  </div>
                ) : (
                  <div>
                    {details &&
                      details.files.map((e1) => (
                        <a
                          href={`${e1.path}`}
                          key={`${e1.path}`}
                          onClick={() => handleDownload(e1.path, e1.fileName)}
                        >
                          {e1.fileName}{" "}
                        </a>
                      ))}{" "}
                  </div>
                )}
              </Form.Item>
              <Form.Item
                label="Choise"
                {...formItemLayout}
                style={{ width: "25%" }}
              >
                <div style={{ display: "flex" }}>
                  <Radio.Group onChange={onChange} value={valueEmail}>
                    <Radio value={"Email"}>Email</Radio>
                    <Radio value={"Lien"}>Link</Radio>
                    <Radio value={"Both"}>Both</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              {valueEmail === "Lien" ? (
                <Form.Item label="Link" style={{ width: "25%" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      className="inputfiledjob"
                      type="email"
                      name="RecruiterLien"
                      placeholder={
                        update && role === "Recrutement"
                          ? JSON.parse(localStorage.getItem("Email"))
                          : details && details.RecruiterEmail === "undefined"
                          ? details && details.RecruiterLien
                          : details && details.RecruiterEmail
                      }
                      // disabled={role!=="Recrutement"}
                      onChange={(e) => dispatch(handelChangeAsync(e))}
                    />
                    {update && (
                      <img
                        src="http://clipground.com/images/copy-4.png"
                        onClick={handleCopyRecutierLienClick}
                        title="Click to Copy"
                        style={{
                          with: "20px",
                          height: "20px",
                          marginLeft: "-25px",
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              ) : valueEmail === "Email" ? (
                <Form.Item label="Recruiter Email" style={{ width: "25%" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      className="inputfiledjob"
                      type="email"
                      name="RecruiterEmail"
                      placeholder={
                        update && role === "Recrutement"
                          ? JSON.parse(localStorage.getItem("Email"))
                          : details && details.RecruiterEmail === "undefined"
                          ? details && details.RecruiterLien
                          : details && details.RecruiterEmail
                      }
                      // disabled={role!=="Recrutement"}
                      onChange={(e) => dispatch(handelChangeAsync(e))}
                    />
                    {update && (
                      <img
                        src="http://clipground.com/images/copy-4.png"
                        onClick={handleCopyRecutierEmailClick}
                        title="Click to Copy"
                        style={{
                          with: "20px",
                          height: "20px",
                          marginLeft: "-25px",
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              ) : (
                <>
                  <Form.Item label="Link" style={{ width: "20%" }}>
                    <div style={{ display: "flex" }}>
                      <input
                        className="inputfiledjob"
                        type="email"
                        name="RecruiterLien"
                        placeholder={
                          update && role === "Recrutement"
                            ? JSON.parse(localStorage.getItem("Email"))
                            : details && details.RecruiterEmail === "undefined"
                            ? details && details.RecruiterLien
                            : details && details.RecruiterEmail
                        }
                        // disabled={role!=="Recrutement"}
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                      {update && (
                        <img
                          src="http://clipground.com/images/copy-4.png"
                          onClick={handleCopyRecutierLienClick}
                          title="Click to Copy"
                          style={{
                            with: "20px",
                            height: "20px",
                            marginLeft: "-25px",
                          }}
                        />
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Recruiter Email"
                    style={{ width: "20%", marginLeft: "2%" }}
                  >
                    <div style={{ display: "flex" }}>
                      <input
                        className="inputfiledjob"
                        type="email"
                        name="RecruiterEmail"
                        placeholder={
                          update && role === "Recrutement"
                            ? JSON.parse(localStorage.getItem("Email"))
                            : details && details.RecruiterEmail === "undefined"
                            ? details && details.RecruiterLien
                            : details && details.RecruiterEmail
                        }
                        // disabled={role!=="Recrutement"}
                        onChange={(e) => dispatch(handelChangeAsync(e))}
                      />
                      {update && (
                        <img
                          src="http://clipground.com/images/copy-4.png"
                          onClick={handleCopyRecutierEmailClick}
                          title="Click to Copy"
                          style={{
                            with: "20px",
                            height: "20px",
                            marginLeft: "-25px",
                          }}
                        />
                      )}
                    </div>
                  </Form.Item>
                </>
              )}

              <Form.Item
                label="Recruiter Name"
                {...formItemLayout}
                style={{ width: "25%", marginLeft: "5%" }}
              >
                <div style={{ display: "flex" }}>
                  <input
                    className="inputfiledjob"
                    type="text"
                    name="NameEmail"
                    placeholder={
                      update && role === "Recrutement"
                        ? JSON.parse(localStorage.getItem("Name"))
                        : details &&
                          details.ajouterPar &&
                          details.ajouterPar.Name
                    }
                    //  disabled={role!=="Recrutement"}
                    onChange={(e) => dispatch(handelChangeAsync(e))}
                  />
                  {update && (
                    <img
                      src="http://clipground.com/images/copy-4.png"
                      onClick={handleCopyWorkColactionClick}
                      title="Click to Copy"
                      style={{
                        with: "20px",
                        height: "20px",
                        marginLeft: "-25px",
                      }}
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item
                label=" Work Location"
                {...formItemLayout}
                style={{ width: "25%", marginLeft: "5%" }}
              >
                <CountryDropdown
                  // disabled={role!=="Recrutement"}
                  name="location"
                  defaultOptionLabel={
                    update ? details.location : "Select a country"
                  }
                  className="selectProjectLeadjob"
                  value={value}
                  onChange={(val) => {
                    dispatch(handelChangeselectAsync({ location: val }));
                  }}
                />
                {update && (
                  <img
                    src="http://clipground.com/images/copy-4.png"
                    onClick={handleCopyWorkColactionClick}
                    title="Click to Copy"
                    style={{
                      with: "20px",
                      height: "20px",
                      marginLeft: "-25px",
                    }}
                  />
                )}
              </Form.Item>

              {((role === "community manager" && update) ||
                (role === "Admin" && update) ||
                (role === "Recrutement" && update)) && (
                <Form.Item
                  label="Link"
                  {...formItemLayout}
                  style={{ width: "20%", marginLeft: "5%" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <textarea
                      className="selectProjectLeadjob"
                      placeholder={details.link}
                      name="Link"
                      onChange={(e) => dispatch(handelChangeAsync(e))}
                      disabled={
                        role === "Recrutement" || role === "Admin"
                          ? update
                          : false
                      }
                    ></textarea>

                    {update && (
                      <img
                        src="http://clipground.com/images/copy-4.png"
                        onClick={handleCopyLinkClick}
                        title="Click to Copy"
                        style={{
                          with: "20px",
                          height: "20px",
                          marginLeft: "-25px",
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
              )}
              <Form.Item
                label="File/Link"
                {...formItemLayout}
                style={{ width: "25%", marginLeft: "5%" }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "60px",
                    alignItems: "center",
                  }}
                >
                  {role === "community manager" ? (
                    <div style={{ display: "flex" }}>
                      <Upload
                        name="file"
                        fileList={infoList2}
                        onChange={({ fileList }) => {
                          setInfoList2(fileList);
                        }}
                        multiple
                      >
                        <Button
                          style={{
                            borderColor: infoList2.length > 0 && "green",
                          }}
                          className="attaachfile"
                          icon={<UploadOutlined />}
                        >
                          {" "}
                          Attach File
                        </Button>
                      </Upload>
                      <div>
                        {details &&
                          details.filescomminty &&
                          details.filescomminty.map((e1) => (
                            <a
                              href={`${e1.path}`}
                              key={`${e1.path}`}
                              onClick={(e) =>
                                handleDownload(e1.path, e1.fileName)
                              }
                            >
                              {e1.fileName}{" "}
                            </a>
                          ))}{" "}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {details &&
                        details.filescomminty &&
                        details.filescomminty.map((e) => (
                          <a
                            key={`${e.path}`}
                            href={`${e.path}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => handleDownload(e, e.path)}
                          >
                            {e.fileName}{" "}
                          </a>
                        ))}{" "}
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "529px",
              }}
            ></div>
            {(role === "Recrutement" ||
              JSON.parse(localStorage.getItem("description")) ===
                "Chief Recruitement Officer" ||
              (role === "community manager" && update)) && (
              <Form.Item
                {...buttonItemLayout}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <button className="buutonsaveaddticket" onClick={handelSubmit}>
                  {(role === "Recrutement" && !update) ||
                  ((JSON.parse(localStorage.getItem("description")) ===
                    "Chief Recruitement Officer") &&
                    !update)
                    ? "Save"
                    : role === "Recrutement" && update
                    ? "Update"
                    : "Update Link"}
                </button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
