
import React, { useEffect, useReducer, useState } from "react";

import "../../components/Trello/Trello.css";
import { Rate } from "antd";

import Ellipse11 from "../../assets/Ellipse11.png";
import Ellipse12 from "../../assets/Ellipse 12.png";
import Ellipse13 from "../../assets/Ellipse 13.png";
import HorLineToDo from "../../assets/HorLineToDo.png";
import HorLineInProgress from "../../assets/HorLineInProgress.png";
import HorLineDone from "../../assets/HorLineDone.png";
import taskSquare from "../../assets/task-square.png";
import taskSquareActive from "../../assets/task-square-active.png";
import Initial from "../../assets/Initial.png";

import ProjectsIcon from "../../assets/ProjectsIcon.png";
import ProjectsIconActive from "../../assets/ProjectIconActive.png";
import TeamIcon from "../../assets/TeamIcon.png";
import TeamIconActive from "../../assets/TeamIconActive.png";
import PlusIcon from "../../assets/PlusIcon.png";
import { Progress } from "antd";

import Text from "../../shared/Text";


import {
  addTaskAsync,
  changeDataAsync,
  getCombineTasksAsync,
  getTasksArchivedAsync,
  getTasksAsync,
  updateTaskAsync,
} from "../../features/Tasks/TaskSlice";
import { useDispatch, useSelector } from "react-redux";
import { getaddJobPostAsync, updateJobPostAsync } from "../../features/JobPost/jobpostSlice";
import TasksProject from "../../components/TasksProject/TasksProject";
import Modal1 from "../../components/Modal/Modal";
import Team from "../../components/Team/Team";
import Projects from "../../components/Projects/Projects";
import TrelloBoard from "../../components/Trello/TrelloBoard";
// import ProjectAcceuil from "./ProjectAcceuil";
import { getstatsAsync } from "../../features/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import Profil from "./Profil";


export default function ProfilAcceuil({ role }) {
  const [lists, setLists] = useState([
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse11} alt="Ellipse11" />
            </div>
            <div className="titleboard">To Do</div>
            {/* <div
          style={{
            width: "18.66px",
  height:" 18.66px",
  
  marginLeft:"10px",
  background: "#E0E0E0",
  borderRadius: "9.33211px"}}> 4 </div> */}
          </div>
          <div>
            <img src={HorLineToDo} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "todo",
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse13} alt="Ellipse13" />
            </div>
            <div className="titleboard">In Progress</div>
          </div>
          <div>
            <img src={HorLineInProgress} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "doing",
    },
    {
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img src={Ellipse12} alt="Ellipse12" />
            </div>
            <div className="titleboard">Completed tasks under 30 days</div>
          </div>
          <div>
            <img src={HorLineDone} alt="HorLineToDo" />
          </div>
        </div>
      ),
      status: "done",
    },
  ]);
  const [precentage, setprecentage] = useState(25);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsselect, setDetailsselect] = useState("");
  const [detailsselect1, setDetailsselect1] = useState("dd");

  const [Archived, setArchived] = useState(false);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
const navigate=useNavigate()
  const [select, setSelect] = useState({
    Acceuil: false,
    Statistics: true,
    Profil:false
  });
  const data = useSelector((state) => state.tasks.data);
  const alldata = useSelector((state) => state.tasks.alldata);
  const alldataourdepartemnt = useSelector((state) => state.tasks.alldataourdepartemnt);

  const datajobposts = useSelector((state) => state.jobposts.datajobposts);
  const datacombintasks = useSelector((state) => state.jobposts.datacombintasks);

  const dispatch = useDispatch();
  function openModal() {
    setModalIsOpen(true);
    if(JSON.parse(localStorage.getItem('role'))==="Admin"){
      setDetailsselect1("Form5")
    }else{
      setDetailsselect1("Form")

    }
  }

  function handleCancel() {
    setModalIsOpen(false);
  }

  // Handle Lists
  // Handle Lists ends here

  // Handle Data
 
  const cardChangeHandler = (cardInfo, newStatus, targetCardId) => {
    const { id, status: oldStatus, _id } = cardInfo;
    let dropCard = alldata[oldStatus].find((el) => el.id === id);
    let targetCard =
      targetCardId !== ""
        ? alldata[newStatus].find((el) => el.id === targetCardId)
        : null;

    let newListOrderValueMax = alldata[newStatus]
      .map((item) => item.order)
      .reduce((maxValue, a) => Math.max(maxValue, a), 0);

    // CASE 1: If same list, work only this if block then return;
    if (oldStatus === newStatus) {
      let temp = alldata[oldStatus]
        .map((item) => {
          if (item.id === dropCard.id)
            return {
              ...dropCard,
              order: targetCard
                ? targetCard.order - 1
                : newListOrderValueMax + 1,
            };
          return item;
        })
        .sort((a, b) => a.order - b.order)
        .map((item, i) => {
          return { ...item, order: i + 1 };
        });
      dispatch(changeDataAsync({ ...alldata, [oldStatus]: temp }));

      return;
    }
    // CASE 1 ENDS HERE

    // CASE 2: Drag across multiple lists
    let tempGaveList = alldata[oldStatus]
      .filter((item) => item.id !== id)
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });

    let tempRecievedList = [
      ...alldata[newStatus],
      {
        ...dropCard,
        order: targetCard ? targetCard.order - 1 : newListOrderValueMax + 1,
      },
    ]
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });
    // At last, set state

    if(id){
      dispatch(
        changeDataAsync({
          ...alldata,
          [oldStatus]: tempGaveList,
          [newStatus]: tempRecievedList,
        })
      );
      dispatch(
        updateTaskAsync({
          status: newStatus,
          id: tempRecievedList[tempRecievedList.length - 1]._id,
        })
      ); 
    }else{
      dispatch(
        changeDataAsync({
          ...alldata,
          [oldStatus]: tempGaveList,
          [newStatus]: tempRecievedList,
        })
      );
      dispatch(
        updateJobPostAsync({
          status: newStatus,
          id: tempRecievedList[tempRecievedList.length - 1]._id,
        })
       
      ); 
      dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
      dispatch(getaddJobPostAsync())
    }
    
    //   // CASE 2 ENDS HERE
  };
  // Handle Data ends here
  useEffect(() => {
    dispatch(getTasksAsync(JSON.parse(localStorage.getItem("id"))))
    dispatch(getaddJobPostAsync())
  }, [dispatch, select.Tasks]);
 

  
  return (
    <div className="trello_page">
      {/* Sidebar */}
      <div className="app-sidebar"></div>
      <div className="app-content-area">
        {/* Main Header */}
        <div className="app-header">
          <div
            className="grandsemilogo"
          >
              
              <div
                  className={select.Acceuil ? "select1" : "select"}
                  style={{display:"flex",width:"100%",
                justifyContent:"center",alignItems:"center"}}
                  onClick={() =>
                    navigate('/Home')
                  }
                >
                  <div>
                    <img
                      src={Initial}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Acceuil "
                      classname={select.Acceuil ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
              <div
                  className={select.Statistics ? "select1" : "select"}
                  style={{display:"flex",width:"100%",
                justifyContent:"center",alignItems:"center"}}
                  onClick={() =>
                   { setSelect({ Statistics: true, Acceuil: false,Profil:false })
                    navigate('/statistics')}
                  }
                >
                  <div>
                    <img
                      src={select.Statistics ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Statistics "
                      classname={select.Statistics ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
                <div
                  className={select.Profil ? "select1" : "select"}
                  style={{display:"flex",width:"100%",
                justifyContent:"center",alignItems:"center"}}
                onClick={() =>
                 { setSelect({ Statistics: false, Acceuil: false ,Profil:true})
                  navigate('/profil')}
                }
                >
                  <div>
                    <img
                      src={select.Profil ? taskSquareActive : taskSquare}
                      alt="TeamIconActive"
                    />
                  </div>
                  <div >
                    <Text
                      content="Manage Your Profil"
                      classname={select.Profil ? "textTasksActive" : "textTasks"}
                    />
                  </div>
                </div>
                
              
          </div>
    
        </div>
     

        
        {/* App Board */}
        <main
          className="app-board"
          style={{ marginTop: role === "Chef du departement" && "32px" }}
        >
       
          {/* Board */}
          <section className="board-body">
      <Profil />
          </section>
        </main>
      </div>
  
    </div>
  );
}