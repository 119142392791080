
import React, {useEffect, useState } from "react";
import "./Login.css";
import { Col } from "antd";
import { Checkbox } from 'antd';
import connection from '../../assets/connection.png'
import Button from "../../shared/Button";
import Google from "../../assets/google.png";
import Text from "../../shared/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, sendPasswordlinkAsync } from "../../features/Auth/authSlice";
import { Container, Row } from "react-bootstrap";
import Cercular from "./Cercular";

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
    const data=useSelector((state)=>state.auth.data)
    const navigate=useNavigate()
    const dispatch=useDispatch()
  
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
            dispatch(sendPasswordlinkAsync({email}))
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};

  return (
    <Container  fluid>
    <Row>
       <Col xs={12} className="loginBackground1">
       <div className="formBackground" >
              <Cercular />
              
  
      
        </div>
</Col>
<Col xs={12} className="loginBackground">
<div className="formBackground1" >
       
          <Col xs={24} lg={24} xl={24}  style={{
            width:"80%",
            display:"flex",
            justifyContent:"center"

          }}>

            
            <div style={{flexDirection:"column",width:"100%",display:"flex",padding:"10%"}}>
            <div className="titrelogin" >
                Welcome To your ticketing system Manager
              </div>
              <div className="titre2login" >Send Email</div>
              <div className="labelInputlogin">Email</div>
              <input className="inputlogin" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} value={email}/>
  
        
              <div style={{display:"flex",justifyContent:"flex-end" ,width:"100%"}}>
  
              </div>
              <div style={{display:"flex",justifyContent:"center" ,width:"100%"}} >
              <Button classname="buttonsiginin" text="Sign In" style={{marginBottom:"40px"}} event={handleSubmit}/>
              {/* <Button classname="buttonsigininwithgoogle" text="Continue with Google" img={Google} /> */}</div>
            </div>
              
          </Col>
      
        </div>
</Col>
    </Row>

  </Container>
  );
};

export default ForgetPassword;
