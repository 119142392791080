import React, {useEffect, useState } from "react";
import "./Login.css";
import { Col } from "antd";
import { Checkbox } from 'antd';
import connection from '../../assets/connection.png'
import Button from "../../shared/Button";
import Google from "../../assets/google.png";
import Text from "../../shared/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../../features/Auth/authSlice";
import MediaQuery from 'react-responsive'
const SiginUp = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const role=useSelector((state)=>state.auth.role)
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Rememberme, setRememberme] = useState(true)

    const onChange = (e) => {
      setRememberme(e.target.checked);
      };
    
  
   
      useEffect(() => {
        if(role==="Admin"){
          navigate('/Home')
       }else if (role === "Developer"){
         navigate('/editor')
       }
       else if (role==='Chef du departement'){
        navigate('/admin')
       }
      }, [navigate, role])

    const handleSubmit =  (e) => {

          dispatch(loginAsync({Email,Password},Rememberme))
            setEmail('')
            setPassword('')
            const role = JSON.parse(localStorage.getItem('role')); 
            if(role==="Admin"){
               navigate('/Home')
            }else if (role ==="Chef du departement"){
              navigate('/admin')
            }else  {
              navigate('/editor')
            }
            
           
            e.preventDefault()
    }

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)

  return (
    <>
    <MediaQuery minWidth={1224} >

    <div className="loginBackground">
      <div className="formBackground">
        <Col xs={24} lg={12} xl={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <div
            style={{
              
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginLeft:"8.10%"}}
          >
            <div className="titrelogin">
              Welcome To your ticketing system Manager
            </div>
            <div>
            <img src={connection} alt='logo'/>

               

            </div>
          </div>
        </Col>

        <Col xs={24} lg={12} xl={12} style={{display:"flex",alignItems:"center"}}>
          <div style={{flexDirection:"column",width:"100%"}}>
            <div className="titre2login">Register to your Platform</div>
            <div className="labelInputlogin">Email</div>
            <input className="inputlogin" placeholder="Email" onChange={handleUserInput} value={Email}/>

            <div className="labelInputlogin">Password</div>
            <input className="inputlogin" placeholder="Password" onChange={handlePwdInput} value={Password} type='password'/>
            <div style={{display:"flex",justifyContent:"space-between" ,width:"100%",
            // width:"74%"
          }}>
              
          

            </div>
            <Button classname="buttonsiginin" text="Sign In" event={handleSubmit}/>
            {/* <Button classname="buttonsigininwithgoogle" text="Continue with Google" img={Google} /> */}
            </div>
        </Col>
      </div>
    </div>
    </MediaQuery>
        <MediaQuery maxWidth={1000} >

        <div className="loginBackground">
          <div className="formBackground" style={{    marginBottom:" 40px",
    marginTop: "148px"}}>
            <Col xs={24} lg={12} xl={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <div
                style={{
                  
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginLeft:"8.10%"}}
              >
                <div className="titrelogin">
                  Welcome To your ticketing system Manager
                </div>
                <div>
                <img src={connection} alt='logo'/>
    
                   
    
                </div>
              </div>
            </Col>
    
            <Col xs={24} lg={12} xl={12} style={{display:"flex",alignItems:"center"}}>
              <div style={{flexDirection:"column",width:"100%",display:"flex",alignItems:"center"}}>
                <div className="titre2login" >Login to your Account</div>
                <div className="labelInputlogin">Email</div>
                <input className="inputlogin" placeholder="Email" onChange={handleUserInput} value={Email}/>
    
                <div className="labelInputlogin">Password</div>
                <input className="inputlogin" placeholder="Password" onChange={handlePwdInput} value={Password} type='password'/>
                <div style={{display:"flex",justifyContent:"space-between" ,width:"74%"}}>
                {/* <Checkbox onChange={onChange} style={{marginLeft:"16px",marginTop:"5%"}}><div className="textlogin">REMEMBER ME</div></Checkbox> */}
                <Text classname="textforgetpassword"  content="Forgot password ?" classname2="text2forgetpassword"  event={()=>navigate('/password-forget')}/>
    
                </div>
                <Button classname="buttonsiginin" text="Sign In" style={{marginBottom:"40px"}} event={handleSubmit}/>
                {/* <Button classname="buttonsigininwithgoogle" text="Continue with Google" img={Google} /> */}
              </div>
            </Col>
          </div>
        </div>
        </MediaQuery></>
    )
    
}

export default SiginUp