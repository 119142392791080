import React from "react";

import { useState, useEffect } from "react";
import { Table } from "antd";
import { MdBarChart } from "react-icons/md";
import { Button, Typography } from "antd"; // Import Ant Design components
import { useDispatch, useSelector } from "react-redux";
import { getstatsAsync } from "../../features/Auth/authSlice";

const columns = [
  {
    title: "Name",
    dataIndex: "Name",
  },
  {
    title: "Login Count",
    dataIndex: "loginCount",
  },

];
const data = [];
for (let i = 0; i < 4; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}
const { Text } = Typography;

const LineChart = ({
  lineChartDataTotalSpentp,
  lineChartOptionsTotalSpentp,
  data
}) => {
  const [lineChartOptionsTotalSpent, setlineChartOptionsTotalSpent] = useState(
    {}
  );
  const [lineChartDataTotalSpent, setlineChartDataTotalSpent] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedInitial, setSelectedInitial] = useState(true);

  const stast = useSelector((state) => state.auth.stast);
  const dispatch = useDispatch();

  const onSelectChange = (newSelectedRowKeys) => {
   
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    setlineChartDataTotalSpent(lineChartDataTotalSpentp);
    setlineChartOptionsTotalSpent(lineChartOptionsTotalSpentp);
  }, [lineChartDataTotalSpentp, lineChartOptionsTotalSpentp]);

  useEffect(() => {
    dispatch(getstatsAsync(new Date(), 0, 3));
  }, [dispatch]);
 
  const textColor = "#333"; // Define the color for the text (adjust as needed)
  const textColorSecondary = "#555"; // Define the color for secondary text (adjust as needed)
  const boxBg = "#f0f0f0"; // Define the background color for the first button (adjust as needed)
  const bgButton = "#f0f0f0"; // Define the background color for the second button (adjust as needed)
  const bgHover = "#e6e6e6"; // Define the background color on hover (adjust as needed)
  const bgFocus = "#f0f0f0"; // Define the background color on focus (adjust as needed)
  const iconColor = "#1890ff"; // Define the color for the icon (adjust as needed)
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <div className="containerLinechart">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "15px 0",
        }}
      >
        <Text
          style={{ marginRight: "auto", fontSize: "18px", fontWeight: "700" }}
        >
          Check Login User
        </Text>
        <div 
        style={{
          display:"flex",
          justifyContent:"space-between",
          width:"50%"
        }}
        >
        <Button
          style={{
            backgroundColor:selectedInitial?"#245097":"#f0f0f0",
            
            
            borderColor: selectedInitial?"#245097":"#f0f0f0",
            borderRadius: "10px",
            width: "40%",
            height: "37px",
            lineHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          // Add any onClick handler if needed
        >
          
            <Text
              style={{
                marginRight: "auto",
                fontSize: "12px",
                fontWeight: "200",
                width: "100%",
                color:selectedInitial?"white":"black",

              }}
              onClick={() => {
                setSelectedInitial(!selectedInitial);
                dispatch(getstatsAsync(new Date(), 1, 0));
              }}
            >
              This Month
            </Text>
             </Button>
             <Button
                style={{
                  backgroundColor:!selectedInitial?"#245097":"#f0f0f0",
                  
                  
                  borderColor: !selectedInitial?"#245097":"#f0f0f0",
                  borderRadius: "10px",
                  width: "40%",
                  height: "37px",
                  lineHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
             >
            <Text
              style={{
                marginRight: "auto",
                fontSize: "12px",
                fontWeight: "200",
                width: "100%",
                color:!selectedInitial?"white":"black",
              }}
              onClick={() => {
                setSelectedInitial(!selectedInitial);
                dispatch(getstatsAsync(new Date(), 0, 3));
              }}
            >
              Last 3 days
            </Text>
          </Button>
   </div>
      </div>

      <div style={{ height: "240px", marginTop: "auto", overflowY: "scroll" }}>
        <Table columns={columns} dataSource={stast} />
      </div>
    </div>
  );
};

export default LineChart;
