import React, { useEffect, useState } from 'react'
import { ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews, Resize, DragAndDrop, TimelineMonth, Day } from '@syncfusion/ej2-react-schedule';
import { extend } from '@syncfusion/ej2-base';
import {

  Button,
  Upload,
  Input

} from "antd";
import commentsIcon from "../../assets/commentsIcon.png";
import { DownOutlined, FilePdfOutlined, FileWordOutlined, FolderViewOutlined } from '@ant-design/icons/lib/icons';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import Avatar from '../../assets/Avatar2.png'
import '../Team/Team.css';
import { registerLicense } from '@syncfusion/ej2-base';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsAsync, getProjectsCalanderAsync } from '../../features/Projects/projectSlice';
import { getTaskscalander, getTaskscalanderAsync } from '../../features/Tasks/TaskSlice';
import { deleteUserAsync, getUsersAsync, getUsersTeamMemberAsync, sendaddPasswordlinkAsync, sendEmailAllUserAsync, updateImageUserAsync } from '../../features/Users/userSlice';
import { Table } from 'antd';
import Text from '../../shared/Text';
import deleteTask from "../../assets/delete.png";
import { UploadOutlined } from '@ant-design/icons/lib/icons';
import { useCallback } from 'react';
import moment from 'moment';
import Modalcenter from '../Modal/Modalcenter';
import { getCommentsAsync } from '../../features/Comment/commentSlice';

registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpAaV1AQmFJfFBmRmlYeFRxc0U3HVdTRHRcQl5hSn5Qd0JiUXtdcXw=;Mgo+DSMBPh8sVXJ0S0J+XE9AflRGQmFLYVF2R2BJfFRxc19HZ0wgOX1dQl9gSX1RdUVgWHhfeHBRTmg=;ORg4AjUWIQA/Gnt2VVhkQlFacldJWXxLfEx0RWFab116dVJMYVtBNQtUQF1hSn5QdEZjWn5ZcXxRQGVU;MTQyNzgyOUAzMjMwMmUzNDJlMzBvRU5kN1VxZEtVVzJraDY3Y0ZUaXFwQjhweEFzV1lIdnN2OTRoQ09iRnNnPQ==;MTQyNzgzMEAzMjMwMmUzNDJlMzBXVnRrNmxSa0gxM3h1RzRHMnFZd291MXZzY0Ixay9TdVNXWktQbDJVaEtzPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmdWfFFpR2NbfE5zflRBal1WVAciSV9jS31TdUdgWXxfd3VdQ2NYWQ==;MTQyNzgzMkAzMjMwMmUzNDJlMzBWYUk3Y1h2eldNMW8zNktZMXMzb0pPbDZFMVAwdmtWMk8wNUNDQnZ4NTVFPQ==;MTQyNzgzM0AzMjMwMmUzNDJlMzBITEs1bnFZOU1BLzhNVnJFYk1IK3NUZ2hzWlYvMHExcFlyR3ByNHdpUFBZPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJWXxLfEx0RWFab116dVJMYVtBNQtUQF1hSn5QdEZjWn5ZcXxTR2JU;MTQyNzgzNUAzMjMwMmUzNDJlMzBQMkhnOWVPMkhkdHBRcGpVSnBOdFgzcjVuKy8zOXdhcXZlbnE1Ry92SktvPQ==;MTQyNzgzNkAzMjMwMmUzNDJlMzBQQ3BYOFlpOGdqTDAxZFR6Y3E1N2RXRkF2V3FmVjc5eHFVVDdoTVpGUXIwPQ==;MTQyNzgzN0AzMjMwMmUzNDJlMzBWYUk3Y1h2eldNMW8zNktZMXMzb0pPbDZFMVAwdmtWMk8wNUNDQnZ4NTVFPQ==');
const { Search } = Input;

const TasksProject = () => {
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [infoList,setInfoList]=useState(null)
    const datataskscalander=useSelector((state)=>state.tasks.datataskscalander)
    const dataprojectscalander=useSelector((state)=>state.projects.dataprojectscalander)
    const dataTeamMember = useSelector((state) => state.users.dataTeamMember);
    const datausers = useSelector((state) => state.users.datausers);
    const [show, setShow] = useState(false);
    const [type, setType] = useState("");
    const [idTask, setIdTask] = useState("");
    const [files, setFiles] = useState([]);
    const [label, setLabel] = useState("");
    const [responsable, setResponsable] = useState([]);
    const [image, setImage] = useState(null);
    const [id, setId] = useState("");
    const [searchText, setSearchText] = useState('');
    const alldatadepartementexterne = useSelector((state) => state.tasks.alldatadepartementexterne);
const [dataSourceFiltred,setDataSourceFiltred]=useState([])
    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const data = extend([], datataskscalander&& datataskscalander.blockData, null, true);
    const datacomments = useSelector((state) => state.comments.datacomments);


    const dispatch=useDispatch()
    useEffect(() => {
      dispatch(getCommentsAsync());
    }, [dispatch]);

  
    
    function getEmployeeName(value) {
        return value.resourceData[value.resource.textField];
    }
    const handleDrop = useCallback((acceptedFiles,id) => {
      const datato={images:acceptedFiles,id:id}
      dispatch(updateImageUserAsync(datato));
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setImage(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    }, []);
    const handleCropChange = useCallback((newCrop) => {
      setCrop(newCrop);
    }, []);
    const handleImageCrop = useCallback(() => {
      const canvas = document.createElement('canvas');
      const imageElement = document.createElement('img');
      imageElement.src = image;
      imageElement.addEventListener('load', () => {
        const scaleX = imageElement.naturalWidth / imageElement.width;
        const scaleY = imageElement.naturalHeight / imageElement.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          imageElement,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
        setImage(canvas.toDataURL());
      });
    }, [crop, image]);
    function getEmployeeImage(value) {
        return getEmployeeName(value).toLowerCase();
    }
    function getEmployeeDesignation(value) {
        return value.resourceData.Designation;
    }
    function resourceHeaderTemplate(props) {
        return (<div className="template-wrap"><div className="employee-category"><div className={"employee-image " + getEmployeeImage(props)}></div><div className="employee-name">
            {getEmployeeName(props)}</div><div className="employee-designation">{getEmployeeDesignation(props)}</div></div></div>);
    }
    const handleTableChange = (pagination, filters, sorter) => {
      setTableParams({
        pagination,
        filters,
        ...sorter,
      });
  
      // // `dataSource` is useless since `pageSize` changed
      // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      //   setData([]);
      // }
    };
  
    useEffect(()=>{
       

       dispatch(getProjectsCalanderAsync())
       dispatch(getTaskscalanderAsync())
       dispatch(getUsersTeamMemberAsync());
      dispatch(getUsersAsync())
    },[dispatch])
    const handleDownload = async(urlPDF,filename) => {
      var a = document.createElement("a");
      const response = await fetch(urlPDF);
      const blob = await response.blob();
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url)
  
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
              // dispatch(sendaddPasswordlinkAsync({email}))
       
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          // setError(error.response.data.message);
          // setMsg("");
        }
      }
    };
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const columns = [
      {
        title: 'Name',
        dataIndex: 'label',
        key: 'title',
        render: (text) => <div style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
        textTransform: "uppercase",
        color: "#787486"}}>{text}</div>,
      },
      // {
      //   title: '',
      //   dataIndex: 'status',
      //   key: 'title',
      //   render: (status) => <EventTableau status={status}/>,
      // },
      {
        title: 'Task Manager',
        dataIndex: 'responsable',
        render: ( responsable) => (
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
           { responsable.map((e,key)=>
             e.images && e.images.length>0 && (
                <div key ={key} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <img  src={e.images[0].path} style={{width:"40px",marginLeft:"5px",borderRaduis:"50%"}}/>
                </div>
            )
            
            
    
      )}
                 
             </div>  ) ,
      },
    //   {
    //     title: 'Timeline',
    //     key: 'status',
    //     dataIndex: 'status',
    //     render: ( tags ) => tags==='todo' ? (
    //         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
    
    //           <Progress type="circle" percent={10} size="small" status="exception" />
    
            
    //       </div>
    //     ):  tags==='doing' ? (
    //         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            
    //         <Progress type="circle" percent={60}size="small"/>
    
                
              
    //         </div>
    //       )
    //       :  (
    // <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>        
    //         <Progress type="circle" percent={100} size="small" />
              
    //         </div>
    //       )
          
    //   },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: ( tags ) => tags==='todo' ? (
          <>
          
          <div  style={{background: "rgba(255, 108, 101, 0.16)",color: '#FF0B00',
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",display:"flex",justifyContent:"center",height:"70px",alignItems:"center",borderRadius: "4px"}} key={ 'volcano'}>
                {"Pending"}
                </div>
              
            
          </>
        ):  tags==='doing' ? (
            <>
            
                  <div  style={{background: "rgba(255, 165, 0, 0.15)",color: '#FFA500',fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",display:"flex",justifyContent:"center",height:"70px",alignItems:"center",borderRadius: "4px"}} key={ 'volcano'}>
                  {"In Progress"}
                  </div>
                
              
            </>
          )
          :  (
            <>
            
            <div  style={{background: "rgba(131, 194, 157, 0.2)",color: '#68B266',fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",display:"flex",justifyContent:"center",height:"70px",alignItems:"center",borderRadius: "4px"}} key={ 'volcano'}>
                  {"Completed"}
                  </div>
                
              
            </>
          )
        ,
      },
      {
        title: 'Date',
        dataIndex: 'DateCreation',
        render: (text) => text ? (
            <>
        <div style={{fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "19px",
        textTransform: "uppercase",
        
        color: "#0D062D"}}>{moment(text).format('L')}</div> </>):(<> ---------</>),
      },
      {
        title: 'Priority',
        key: 'Priority',
        dataIndex: 'Priority',
        render: ( tags ) => tags!=='undefined' ?( tags==='Normal' ? (
          <>
          
          <div style={{display:"flex",justifyContent:"center",fontFamily: 'Inter',
          fontStyle: "normal",backgroundColor:" rgba(36, 80, 151, 0.21)",color:"#245097",borderRadius: "4px",
          fontWeight: "500",fontSize: "12px",
      lineHeight: "15px",alignItems:"center",height:"70px"}}>
                {tags}
                </div>
              
            
          </>
        ):  tags==='Low' ? (
            <>
            
            <div style={{display:"flex",justifyContent:"center",fontFamily: 'Inter',
          fontStyle: "normal",backgroundColor:" rgba(36, 80, 151, 0.21)",color:"#245097",borderRadius: "4px",
          fontWeight: "500",fontSize: "12px",
      lineHeight: "15px",alignItems:"center",height:"70px"}}>
                  Low
                  </div>
                
              
            </>
          )
          :  tags==='High' ? (
            <>
            
            <div style={{display:"flex",justifyContent:"center",fontFamily: 'Inter',
          fontStyle: "normal",backgroundColor:" rgba(36, 80, 151, 0.21)",color:"#245097",borderRadius: "4px",
          fontWeight: "500",fontSize: "12px",
      lineHeight: "15px",alignItems:"center",height:"70px"}}>
                  High
                  </div>
                
              
            </>
          ): (
            <>
            
            <div style={{display:"flex",justifyContent:"center",fontFamily: 'Inter',
          fontStyle: "normal",backgroundColor:" rgba(36, 80, 151, 0.21)",color:"#245097",borderRadius: "4px",
          fontWeight: "500",fontSize: "12px",
      lineHeight: "15px",alignItems:"center",height:"70px"}}>
                  {tags}
                  </div>
                
              
            </>
          )): <div style={{display:"flex",justifyContent:"center",fontFamily: 'Inter',
          fontStyle: "normal",backgroundColor:" rgba(36, 80, 151, 0.21)",color:"#245097",borderRadius: "4px",
          fontWeight: "500",fontSize: "12px",
      lineHeight: "15px",alignItems:"center",height:"70px"}}>------</div>
        ,
      },
      {
        title: 'Files',
        key: 'files',
        dataIndex: 'files',
        render: ( files ) =>(
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:'center'}}>
        {files &&  files.map((e,key)=>
        e.fileName &&  e.fileName.split('.')[1]==='pdf'  ?(
        <a key ={key} href={`${e.path}`}  target="_blank" rel="noreferrer"><FilePdfOutlined  style={{color:'red',fontSize: '200%'}} size={40}   onClick={()=>handleDownload(e.fileName,e.path)}/></a>
       ) : e.fileName.split('.')[1] ==='docx' ?
       (<a key ={key} href={`${e.path}`} target="_blank" rel="noreferrer"><FileWordOutlined   style={{color:'blue',fontSize: '200%'}} size={40}   onClick={()=>handleDownload(e.fileName,e.path)} /></a>) :(<a key ={key} href={`${e.path}`} target="_blank" rel="noreferrer"   onClick={()=>handleDownload(e.fileName,e.path)}><FolderViewOutlined    style={{fontSize: '200%'}} size={40}/></a>) )
        }
         </div> )
                } 
        ,       
      {
        title: 'Comments',
        dataIndex: '_id',
        key: 'action',
        render: (id) => (
          <div className="cardProjectFooter">
          <div className="containaircardproject">
    
    
            <div style={{display:"flex",height:"100%",alignItems:"center",cursor:"pointer"}}>
              <div onClick={() => {
                        setType("comment");
                        setIdTask(id);
                      

                        const ticketintbleau=transformedData && transformedData.filter((e)=>e._id===id)
                        setLabel(ticketintbleau && ticketintbleau[0] &&ticketintbleau[0].label)
                        setResponsable(ticketintbleau && ticketintbleau[0] &&ticketintbleau[0].Responsable)
                        handleShow();
                      }}>
            <img
                    src={commentsIcon}
                    alt="JoindreficherIcon"
                    
                  />
                  <Text
              content={` comments`}
              classname="textcarfooter"
            />
            </div>
           
            </div>
          </div>
        </div>
        ),
      },
    ];
 
    const handleSearch = (text) => {
            setSearchText(text.target.value);
    };
    const transformedData = dataSourceFiltred && dataSourceFiltred.map((item, index) => ({
      label: item.label,
      _id:item._id,
      Responsable:item.responsable
    }));  
    useEffect(()=>{
      setDataSourceFiltred(alldatadepartementexterne.todo && [ ...alldatadepartementexterne.todo,...alldatadepartementexterne.doing,...alldatadepartementexterne.done])
     },[alldatadepartementexterne])
  return (
    <div className='containercalndrier'>
      <div style={{ display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <div style={{ display:"flex",flexDirection:"row",marginTop: "30px",
marginLeft: "51px"}}>
      <input placeholder="Search Task by name" value={searchText} onChange={handleSearch} style={{ width: 200 ,borderRadius:'4px',border:" 0.910839px solid #787486"}} />
    
      
        
        
        
         </div> 
       
         </div> 
         
       
    <div className='schedule-control-section'>
    <div className='col-lg-12 control-section'>
        <div className='control-wrapper drag-sample-wrapper'>
            <div className="schedule-container">
            <section className="board-body" style={{marginTop:"50px"}}>
                <Table  
                 columns={columns} dataSource={dataSourceFiltred}        
                 pagination={tableParams.pagination}
               loading={loading}
               onChange={handleTableChange}
                    scroll={{
                      y: 840,
                    }}
                />
                </section>
            </div>
        </div>
    </div>
  </div>
  <Modalcenter
        type={type}
        show={show}
        
        files={files}
        datacomments={datacomments.filter(
          (e) => e.task && e.task._id && e.task._id === idTask
        )}
        idTask={idTask}
        data={responsable}
        // detaisdata={detaisdata}
        handleClose={handleClose}
        label={label}
      />
  </div>
  )
}

export default TasksProject