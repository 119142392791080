import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import HorLineToDo from "../../assets/HorLineToDo.png";
import HorLineInProgress from "../../assets/HorLineInProgress.png";
import HorLineDone from "../../assets/HorLineDone.png";
import taskSquare from "../../assets/task-square.png";
import taskSquareActive from "../../assets/task-square-active.png";
import ProjectsIcon from "../../assets/ProjectsIcon.png";
import ProjectsIconActive from "../../assets/ProjectIconActive.png";
import TeamIcon from "../../assets/TeamIcon.png";
import TeamIconActive from "../../assets/TeamIconActive.png";
import PlusIcon from "../../assets/PlusIcon.png";
import Base_URL from "../../app/api/apiSlice";
const API_URL = `${Base_URL}/projects`;
export const projectsSlide = createSlice({
  name: "projects",
  initialState: {
    dataprojects: [],
    dataprojectscalander: [],
    dataprojectsbydepartement: [],
    error: false,
    loading: false,
    datatosend: {
      id_departement: "63e25e88af7add9f0c41180d",
    },
  },

  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    addProject: (state, action) => {
      state.dataprojects = [...state.dataprojects, action.payload];
    },
    updateProject: (state, action) => {
      const index = state.dataprojects.findIndex(
        (item) => item._id === action.payload._id
      );
      const updatedObject = { ...state.dataprojects[index], name: "New Name" };
      const updatedArray = [
        ...state.dataprojects.slice(0, index),
        updatedObject,
        ...state.dataprojects.slice(index + 1),
      ];

      state.dataprojects = updatedArray;
    },
    deleteProject: (state, action) => {
      state.dataprojects = state.dataprojects.filter(
        (e) => e._id !== action.payload._id
      );
    },
    getProjects: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataprojects = action.payload;
    },
    getProjectsbydepartement: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataprojectsbydepartement = action.payload;
    },
    getProjectscalander: (state, action) => {
      state.loading = false;
      state.error = false;
      state.dataprojectscalander = action.payload;
    },
    handelChange: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeTeammember: (state, action) => {
      state.datatosend = { ...state.datatosend, TeamMember: action.payload };
    },
    handelChangeDate: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeColor: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    handelChangeTime: (state, action) => {
      state.datatosend = { ...state.datatosend, ...action.payload };
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  },
});

//get Tous les projets
export const getProjectsAsync = () => async (dispatch) => {
  api.get("/getAllProject").then((response) => {
    dispatch(getProjects(response.data));
  });
};
export const getProjectsbydepartementAsync = (id) => async (dispatch) => {
  const data = {
    param1: id,
  };
  api.get("/findProjectBydepartement", { params: data }).then((response) => {
    dispatch(getProjectsbydepartement(response.data));
  });
};
//get Tous les projets calander
export const getProjectsCalanderAsync = () => async (dispatch) => {
  api.get("/getAllProjectCalander").then((response) => {
    dispatch(getProjectscalander(response.data));
  });
};
//add un seul projet

export const addProjectAsync = (data) => async (dispatch) => {
  api
    .post(`/ajouterprojet`, data)
    .then((response) => {
      dispatch(addProject(response.data));
      dispatch(getProjectsbydepartementAsync(response.data.id_departement));
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
//remlpir formulaire add projet
export const handelChangeAsync = (e) => async (dispatch) => {
  try {
    dispatch(handelChange({ [e.target.name]: e.target.value }));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeDateAsync =
  (date, dateString, abc) => async (dispatch) => {
    try {
      dispatch(handelChangeDate({ DateCreation: dateString }));
    } catch (err) {
      dispatch(setError());
    }
  };
export const handelChangeColorAsync = (color) => async (dispatch) => {
  try {
    dispatch(handelChangeColor({ Color: color }));
  } catch (err) {
    dispatch(setError());
  }
};
export const handelChangeTimeAsync =
  (time, timeString, abc) => async (dispatch) => {
    try {
      dispatch(handelChangeTime({ HeureCreation: timeString }));
    } catch (err) {
      dispatch(setError());
    }
  };
export const handelChangeTeammemberAsync = (data) => async (dispatch) => {
  try {
    dispatch(handelChangeTeammember(data));
  } catch (err) {
    dispatch(setError());
  }
};
export const updateNameProjectAsync = (data) => async (dispatch) => {
  api
    .put(`/updatenammeproject`, data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      if (err.response.data.message) {
        alert(err.response.data.message);
      } else {
        dispatch(setError());
      }
    });
};
export const deleteProjectAsync = (data) => async (dispatch) => {
  axios
    .delete(`${Base_URL}/projects/deleteProject`, { data: { id: data } })
    .then((response) => {
      dispatch(
        getProjectsbydepartementAsync(
          JSON.parse(localStorage.getItem("departement"))
        )
      );
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   alert(err.response.data.message);
      // } else {
      //   dispatch(setError());
      // }
    });
};

export const {
  addProject,
  handelChangeTeammember,
  getProjects,
  getProjectsbydepartement,
  handelChange,
  setError,
  setLoading,
  handelChangeDate,
  handelChangeTime,
  getProjectscalander,
  deleteProject,
  handelChangeColor,
} = projectsSlide.actions;
export default projectsSlide.reducer;
