import React, { useEffect, useState } from "react";
import { Badge } from "antd";
import { Button, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import JoindreficherIcon from "../../assets/JoindreficherIcon.png";
import LineOption from "../../assets/LineOption.png";
import commentsIcon from "../../assets/commentsIcon.png";
import "../../components//Projects/Projectcard.css";
import Text from "../../shared/Text";
import Modal1 from "../../components/Modal/Modal";
import Modalcenter from "../../components/Modal/Modalcenter";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectAsync, getProjectsAsync, getProjectsbydepartementAsync } from "../../features/Projects/projectSlice";
import { getDeparementbyUserloginAsync } from "../../features/Users/userSlice";

const tempColors = { "Bug Fix": 1, "UI Dev": 2, Testing: 3 };
const menu = (setmodalIsOpen2, setDetailsselect,setDetailsproject,data,handelDeleteProject) => (
  <Menu>
    <Menu.Item
      key="1"
      // onClick={() => {
      //   setDetailsselect("Project Details");
      //   setDetailsproject(data)
      //   setmodalIsOpen2(true);
      // }}
    >
      {" "}
      <div className="text-more-task">Project Details</div>
      <div>
        <img src={LineOption} alt="LineOption" />
      </div>
    </Menu.Item>
   { JSON.parse(localStorage.getItem('role')) === "Admin" && 
    <Menu.Item
      key="3"
      onClick={() =>  handelDeleteProject(data._id)
      }
    >
      <div className="text-more-task">Delete Project</div>
      <div>
        <img src={LineOption} alt="LineOption" />
      </div>
    </Menu.Item>}
  </Menu>
);
export default function ProjectAcceuil({
  select,
  detailsselect,
  setDetailsselect,
}) {
  const dataprojects = useSelector((state) => state.projects.dataprojects);

  const [onHold, setOnHold] = useState(false);
  const [modalIsOpen2, setmodalIsOpen2] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [detailsproject, setDetailsproject] = useState({});
  const dataprojectsbydepartement = useSelector((state) => state.projects.dataprojectsbydepartement);
  const IdDepartement = useSelector((state) => state.users.IdDepartement);

  const datadepartements = useSelector((state) => state.departements.datadepartements);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCancel2 = () => {
    setmodalIsOpen2(false);
  };
  const handelDeleteProject=(id)=>{
    dispatch(deleteProjectAsync(id));

  }
  useEffect(()=>{
  dispatch(getDeparementbyUserloginAsync(JSON.parse(localStorage.getItem('id'))))
},[dispatch])
useEffect(()=>{
  dispatch(getProjectsbydepartementAsync(IdDepartement))
},[dispatch,IdDepartement])

  return (
    <>
      {!dataprojects.length ? <div style={{display:"flex",justifyContent:"center",width:"100%",alignItems:"center",height:"150px"}}>No Projects</div>: dataprojects &&
       dataprojects&&  dataprojects.map((e) => (
          <Badge
            count={e.Name}
            style={{ backgroundColor: e.Color }}
            className="textprojectname"
          >
            <div className={`cardproject`}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={`descriptionproject`}>{e.Description}</div>
                <div>
                  <Dropdown overlay={menu(setmodalIsOpen2, setDetailsselect,setDetailsproject,e,handelDeleteProject)}>
                    <button>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                  </Dropdown>
                </div>
              </div>

              <div className="cardProjectFooter">
                <div className="containaircardproject">
                  <div style={{ display: "flex", width: "192px" }}>
                    <a href="" className="cardProjectPerson">
                      <i className="fas fa-user"></i>
                    </a>
                    <Text
                      content={e.ProjectLeadName && e.ProjectLeadName.Name}
                      classname="textcardpersonfooter"
                    />
                  </div>

                  <a className="cardProjectPerson1">
                    <img
                      src={JoindreficherIcon}
                      alt="JoindreficherIcon"
                      onClick={() => {
                        setType("file");
                        handleShow();
                      }}
                    />
                    <Text
                      content={"0 Related Files"}
                      classname="textcarfooter"
                    />
                  </a>
                </div>
              </div>
            </div>
            <Modal1
              title={detailsselect}
              type={select}
              detailsselect={detailsselect}
              modalIsOpen={modalIsOpen2}
              disable={true}
              detaisdataprojects={detailsproject}
              handleCancel={handleCancel2}
            />
            <Modalcenter type={type} show={show} handleClose={handleClose} />
          </Badge>
        ))}
    </>
  );
}
