import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const AreaCharts = ({ data })=> {
  const [chartData, setChartData] = useState([
      
    // Add more data points as needed...
  ]);
  const datastasts2 = useSelector((state) => state.stasts.datastasts2);
useEffect(()=>{
  setChartData(datastasts2.categories)
},[datastasts2])
  return (
    
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={chartData}
     
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="todo" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="inProgress" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        <Area type="monotone" dataKey="done" stackId="1" stroke="#ffc658" fill="#ffc658" />
   
      </AreaChart>
  </ResponsiveContainer>
  
  );
};

export default AreaCharts;