import React, { useEffect, useState } from "react";

import { Avatar, Select, Row, Col } from "antd";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "./Chart";
import LineProject from "../../assets/LineProject.png";
import LineCompleted from "../../assets/LineCompleted.png";
import LineProgress from "../../assets/LineProgress.png";
import LinetoDo from "../../assets/LinetoDo.png";
import WeeklRevenue from "./WeeklRevenue";
import LineChart from "./LineChart";
import Minicard from "./Minicard";
import { MdBarChart } from "react-icons/md";
import DailyTraffic from "./DailyTraffic";
import { useDispatch, useSelector } from "react-redux";
import { getStatsAsync } from "../../features/Stats/StatsSlice";
import { getstatsAsync } from "../../features/Auth/authSlice";
import TimeMangementtask from "./TimeMangementtask";

const PageStast = () => {
  const boxBg = "#f0f0f0"; // Define the background color for the icon box (adjust as needed)
  const brandColor = "#1890ff";
  const datastasts = useSelector((state) => state.stasts.datastasts);
  const [StartDate, setStartDate] = useState({ day: 1, month: 8, year: 2023 });
  const [EndDate, setEndDate] = useState({ day: 30, month: 8, year: 2023 });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStatsAsync(new Date(), 0, 3));
  }, [dispatch]);

  return (
    <div style={{ paddingBottom: "20px" }} className="containstats">
      <Row gutter={20} style={{ marginTop: "20px" }}>
        <Col xs={24} sm={12} md={6} lg={6} xl={11}>
          <LineChart
            data={datastasts}
            lineChartDataTotalSpentp={lineChartDataTotalSpent}
            lineChartOptionsTotalSpentp={lineChartOptionsTotalSpent}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={11}>
          <WeeklRevenue
            StartDate={StartDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            EndDate={EndDate}
          />
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={22}>
          <TimeMangementtask
            lineChartDataTotalSpentp={lineChartDataTotalSpent}
            lineChartOptionsTotalSpentp={lineChartOptionsTotalSpent}
            taskarchived={datastasts.taskarchived}
            StartDate={StartDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            EndDate={EndDate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PageStast;
